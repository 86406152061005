import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CCard, Currency } from '../../../types';

export type SelectPaymentDialogData = {
  currency: Currency;
  foundationId?: string;
  allowCash?: boolean;
};

@Component({
  selector: 'select-payment',
  templateUrl: './select-payment.component.html',
  styleUrls: ['./select-payment.component.scss'],
})
export class SelectPaymentComponent {
  selectCurrency: Currency | undefined;
  foundationId: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SelectPaymentDialogData,
    public dialogRef: MatDialogRef<SelectPaymentComponent>
  ) {
    this.selectCurrency = this.dialogData.currency;
    this.foundationId = this.dialogData.foundationId;
  }

  selectCard(ccard: CCard) {
    this.dialogRef.close(ccard);
  }
}
