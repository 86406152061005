import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type ConfirmDialogData = {
  confirmKey?: string;
  confirmText?: string;
  bodyKey?: string;
  okKey?: string;
  cancelKey?: string;
  hideCancel?: boolean;
  bodyHtmlKey?: string;
  maxWidth?: string;
};

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  confirmKey = this.dialogData.confirmKey ?? 'GENERAL.confirmMessage';
  confirmText = this.dialogData.confirmText;
  hideCancel = this.dialogData.hideCancel ?? false;
  okKey = this.dialogData.okKey ?? 'GENERAL.yes';
  cancelKey = this.dialogData.cancelKey ?? 'GENERAL.no';
  bodyKey = this.dialogData.bodyKey;
  bodyHtmlKey = this.dialogData.bodyHtmlKey;
  maxWidth = this.dialogData.maxWidth ?? 'none';

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogData) {
  }
}
