<h5>{{ section?.section }}</h5>

<div class="navigation-wrapper">
  <div
    class="keen-slider"
    #sliderRef
    *ngIf="auctionIds?.length || items?.length"
  >
    <card-event
      class="keen-slider__slide"
      *ngFor="let auctionId of auctionIds"
      [auctionId]="auctionId"
      [less]="true"
      (clicked)="clickEvent($event)"
    ></card-event>
    <card-stacked
      class="keen-slider__slide"
      (eventClicked)="clickEvent($event)"
      (itemClicked)="clickItem($event)"
      (teamClicked)="clickTeam($event)"
      (webClicked)="clickWeb($event)"
      *ngFor="let item of items"
      [item]="item"
      [less]="true"
    ></card-stacked>
  </div>
  <div
    [ngClass]="
      'arrow arrow--left ' + (sliderData.moreLeft ? '' : 'arrow--disabled')
    "
    (click)="slider?.prev()"
  >
    <div class="shadow"></div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
      />
    </svg>
  </div>
  <div
    *ngIf="slider"
    [ngClass]="
      'arrow arrow--right ' + (sliderData.moreRight ? '' : 'arrow--disabled')
    "
    (click)="slider.next()"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
    <div class="shadow"></div>
  </div>
</div>
