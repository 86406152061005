import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../store';
import { register } from '../../../store/user/user.actions';
import { selectUserProcessing } from '../../../store/user/user.selectors';
import { SignUpCredentials } from '../../../types';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent {
  @Output() goToLogin = new EventEmitter();
  @Input() redirectHome = true;
  checked = false;

  credentials: SignUpCredentials = {
    email: '',
    password: '',
    contactNumber: '',
    firstName: '',
    lastName: '',
  };

  processing$ = this.store.select(selectUserProcessing);
  constructor(private store: Store<GlobalState>, private router: Router) {}

  submit() {
    if (!this.checked) return;
    this.store.dispatch(
      register({
        credentials: { ...this.credentials },
        redirectHome: this.redirectHome,
      })
    );
  }

  loginClick() {
    this.goToLogin.observers.length
      ? this.goToLogin.emit()
      : this.router.navigate(['/login']);
  }
}
