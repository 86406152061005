import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import {
  loadProfile,
  login,
  loginFail,
  loginSucceeded,
  logout,
  register,
  recoverAccessToken,
  saveAccessToken,
  setProfile,
  sendProfile,
  sendProfileFail,
  pullOTItems,
  pullOTItemsSuccess,
} from './user.actions';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../services/storage.service';
import { accessTokenKey } from '../../keys';
import {
  AnyObject,
  Credentials,
  SignUpCredentials,
  SnackbarClass,
  UserProfile,
} from '../../types';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { ChangePassword } from '../../types/change-password.model';
import { RealtimeService } from '../../services/realtime.service';

@Injectable()
export class UserEffects {
  constructor(
    private readonly api: ApiService,
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private storageService: StorageService,
    private router: Router,
    private realtimeService: RealtimeService
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login),
      mergeMap(({ credentials, redirectHome }) =>
        this.login(credentials, redirectHome)
      )
    );
  });

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(register),
      mergeMap(({ credentials, redirectHome }) =>
        this.register(credentials, redirectHome)
      )
    );
  });

  sendProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendProfile),
      mergeMap(({ profile, passwords }) => this.saveProfile(profile, passwords))
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      mergeMap(() => this.logout())
    );
  });

  loadProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProfile),
      mergeMap(({ tokenId }) => this.loadProfile(tokenId))
    );
  });

  restoreSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(recoverAccessToken),
      mergeMap(() => this.restoreSession())
    );
  });

  loginFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginFail),
      mergeMap(() => [
        setProfile({ profile: undefined }),
        saveAccessToken({ accessToken: undefined }),
      ])
    );
  });

  pullOtItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pullOTItems),
      mergeMap(() => {
        return this.api.bid.ot().pipe(
          mergeMap((otItems) => [pullOTItemsSuccess({ otItems })]),
          catchError(() => [pullOTItemsSuccess({ otItems: [] })])
        );
      })
    );
  });

  setProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setProfile),
      tap(({ profile }) => {
        this.realtimeService.userChannel = profile?.profileId;
      }),
      mergeMap(({ profile }) => (profile ? [pullOTItems()] : []))
    );
  });

  loginSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginSucceeded),
        tap(({ redirectHome }) => {
          if (redirectHome) this.router.navigate(['']);
        })
      );
    },
    { dispatch: false }
  );

  setAccessToken$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveAccessToken),
        tap(({ accessToken }) => this.accessTokenUpdated(accessToken))
      );
    },
    { dispatch: false }
  );

  private restoreSession() {
    const tokenId = this.storageService.getAndParseItem(accessTokenKey);
    if (!tokenId) {
      return [
        setProfile({ profile: undefined }),
        saveAccessToken({ accessToken: undefined }),
      ];
    }
    return this.api.user.authenticated(tokenId).pipe(
      mergeMap((profile) => [
        setProfile({ profile }),
        saveAccessToken({ accessToken: tokenId }),
      ]),
      catchError((error) => this.loginError(error))
    );
  }

  private saveProfile(
    data: Partial<UserProfile>,
    passwords: ChangePassword | undefined
  ) {
    let profile: UserProfile;
    return this.api.user.updateMy(data).pipe(
      mergeMap((res) => {
        profile = res;
        if (!passwords) return of(profile);
        return this.api.user.changePassword(passwords);
      }),
      map(() => setProfile({ profile })),
      tap(() => {
        this.translateService
          .get('modal.profileChanged')
          .subscribe((message) => {
            this.snackBar.open(message, undefined, {
              panelClass: SnackbarClass.Success,
            });
          });
      }),
      catchError((error) => this.profileError(error))
    );
  }

  private loadProfile(tokenId?: string) {
    return this.api.user.authenticated(tokenId).pipe(
      map((profile) => setProfile({ profile })),
      catchError((error) => this.loginError(error))
    );
  }

  private login(credentials: Credentials, redirectHome: boolean) {
    let tokenId: string | undefined;
    return this.api.user.login(credentials).pipe(
      mergeMap((accessToken) => {
        tokenId = accessToken.id;
        return this.api.user.authenticated(accessToken.id);
      }),
      mergeMap((profile) => [
        setProfile({ profile }),
        saveAccessToken({ accessToken: tokenId }),
        loginSucceeded({ profile, isNewProfile: false, redirectHome }),
      ]),
      catchError((error) => this.loginError(error))
    );
  }

  private register(credentials: SignUpCredentials, redirectHome: boolean) {
    let tokenId: string | undefined;
    return this.api.user.register(credentials).pipe(
      mergeMap((accessToken) => {
        tokenId = accessToken.id;
        return this.api.user.authenticated(accessToken.id);
      }),
      mergeMap((profile) => [
        setProfile({ profile }),
        saveAccessToken({ accessToken: tokenId }),
        loginSucceeded({ profile, isNewProfile: true, redirectHome }),
      ]),
      catchError((error) => this.loginError(error))
    );
  }

  private accessTokenUpdated(accessToken: string | undefined) {
    if (!accessToken) return this.storageService.removeItem(accessTokenKey);
    this.storageService.setItem(accessTokenKey, accessToken);
  }

  private loginError(error: AnyObject) {
    return this.translateService.get('modal.loginError').pipe(
      map((copy) => {
        const message =
          error?.status === 401 ? copy : error?.error?.error?.message ?? copy;
        this.snackBar.open(message);
        return loginFail();
      })
    );
  }

  private profileError(error: AnyObject) {
    const message =
      error?.error?.error?.message ?? 'There was an error saving your profile';
    this.snackBar.open(message);
    return of(sendProfileFail());
  }

  private logout() {
    this.api.user.logout().subscribe(() => {
      console.log('Successfully logged out');
    });
    this.router.navigate(['']);
    return of(saveAccessToken({ accessToken: undefined }));
  }
}
