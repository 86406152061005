<ng-container *ngrxLet="myBids$ as myBids">
  <div class="content-section">
    <h5 class="title dark">{{ 'nav.myLocker' | translate }}</h5>
    <div *ngIf="!myBids.length">
      <p>{{ 'locker.empty1' | translate }}</p>
      <p>{{ 'locker.empty2' | translate }}</p>
    </div>
    <card-locker
      *ngFor="let bid of myBids"
      [id]="bid.auctionItemId"
      [userBidId]="bid.id"
      [purchaseCount]="bid.purchaseCount"
      [fulfillmentOption]="bid.fulfillmentOption"
      [customMessage]="bid.customMessage"
    ></card-locker>
  </div>
</ng-container>
