import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Content } from '@fan-types/content';

export const pullContent = createAction(
  '[Content/API] Pull content',
  props<{ contentId: string, isTeam?: boolean, limit?: number, reset?: boolean }>()
);


export const pullContentFailed = createAction(
  '[Content/API] Pull content failed'
);

export const loadContents = createAction(
  '[Content/API] Load contents',
  props<{ contents: Content[] }>()
);

export const addContent = createAction(
  '[Content/API] Add Content',
  props<{ content: Content }>()
);

export const upsertContent = createAction(
  '[Content/API] Upsert Content',
  props<{ content: Content }>()
);

export const addContents = createAction(
  '[Content/API] Add contents',
  props<{ contents: Content[] }>()
);

export const upsertContents = createAction(
  '[Content/API] Upsert contents',
  props<{ contents: Content[] }>()
);

export const updateContent = createAction(
  '[Content/API] Update Content',
  props<{ content: Update<Content> }>()
);

export const updateContents = createAction(
  '[Content/API] Update contents',
  props<{ contents: Update<Content>[] }>()
);

export const deleteContent = createAction(
  '[Content/API] Delete Content',
  props<{ id: string }>()
);

export const deleteContents = createAction(
  '[Content/API] Delete contents',
  props<{ ids: string[] }>()
);

export const clearContents = createAction('[Content/API] Clear contents');
