import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import profileHasAddress from '../../../../../helpers/profile-has-address';
import { ApiService } from '../../../../../services/api.service';
import { CCard, Currency, UserProfile } from '../../../../../types';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import { MatDialog } from '@angular/material/dialog';

export type OnboardingDialogStep = 'login' | 'shipping' | 'payment';

@Component({
  selector: 'onboarding-controller',
  templateUrl: './onboarding-controller.component.html',
  styleUrls: ['./onboarding-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingControllerComponent implements OnChanges {
  step: OnboardingDialogStep = 'login';
  @Input() profile: UserProfile | undefined | null;
  @Input() currency: Currency = 'USD';
  @Input() foundationId?: string;
  @Input() ccards?: CCard[] | null;
  @Output() cancel = new EventEmitter();
  @Output() cardAdded = new EventEmitter<CCard>();

  constructor(private api: ApiService, private dialog: MatDialog) {}

  ngOnChanges() {
    this.step = (() => {
      if (!this.profile?.id) return 'login';
      if (!profileHasAddress(this.profile)) return 'shipping';
      if (this.step !== 'payment') this.checkCurrency();
      return 'payment';
    })();
    setTimeout(() => {
      if (this.ccards?.length && this.step === 'payment') {
        this.cardAdded.emit(this.ccards[0]);
      }
    });
  }

  private checkCurrency() {
    if (!this.profile?.id) return;
    this.api.card.getCurrencies(this.profile?.id).subscribe((currencies) => {
      const hasOtherCurrency = currencies.filter(
        (c) => c !== this.currency
      ).length;
      const hasCurrency = currencies.filter((c) => c === this.currency).length;
      if (hasOtherCurrency && !hasCurrency) this.showMarketModal();
    });
  }

  private showMarketModal() {
    const data: ConfirmDialogData = {
      confirmKey: 'modal.newMarketTitle',
      okKey: 'modal.ok',
      hideCancel: true,
      bodyKey: 'modal.newMarketBody',
    };
    this.dialog.open(ConfirmDialogComponent, {
      data,
    });
  }
}
