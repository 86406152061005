import { Component, Input } from '@angular/core';
import { Auction, AuctionState } from '../../../types';

@Component({
  selector: 'event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss'],
})
export class EventStatusComponent {
  countdown?: string;
  private _auction?: Auction;

  @Input()
  set auction(auction: Auction | undefined) {
    this._auction = auction;
    this.countdown =
      auction?.auctionEnd && auction.auctionState === AuctionState.Open
        ? auction.auctionEnd
        : undefined;
  }
  get auction() {
    return this._auction;
  }
}
