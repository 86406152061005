import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FulfillmentOption } from '@fan-types/fulfillment-option';
import { AuctionItem, BuynowItem } from '@fan-types/item.model';
import { ProfileAddress } from '@fan-types/user-profile';

@Component({
  selector: 'fulfillment-option-info',
  templateUrl: './fulfillment-option-info.component.html',
  styleUrls: ['./fulfillment-option-info.component.scss']
})
export class FulfillmentOptionInfoComponent implements OnChanges {
  @Input() item?: AuctionItem | BuynowItem;
  fulfillmentOption?: Partial<FulfillmentOption>;
  shippingAddress?: ProfileAddress;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item?.currentValue) {
      this.fulfillmentOption = changes.item.currentValue?.fulfillmentOptionSelected?.option;
      this.shippingAddress = changes.item.currentValue?.fulfillmentOptionSelected?.address;
    }
  }

}
