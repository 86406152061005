import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';
import { ContentItemWeb, ContentSection } from '../../../../types';

@Component({
  selector: 'dash-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() section?: ContentSection;
  @ViewChild('sliderRef') sliderRef?: ElementRef<HTMLElement>;
  interval = 0;
  pause = false;
  slider?: KeenSliderInstance;
  items: ContentItemWeb[] = [];

  constructor(private trackAnalytics: TrackAnalyticsService) {}

  ngOnInit() {
    if (this.section && 'items' in this.section) {
      this.items = this.section.items.filter(
        (item) => item.itemType === 'web' && item.desktopAssetUrl
      ) as ContentItemWeb[];
    }
  }

  ngAfterViewInit() {
    if (!this.sliderRef) return;
    this.slider = new KeenSlider(
      this.sliderRef.nativeElement,
      {
        loop: true,
      },
      [
        (slider) => {
          let timeout: any;
          let mouseOver = false;
          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
              slider.next();
            }, 8000);
          }
          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on('dragStarted', clearNextTimeout);
          slider.on('animationEnded', nextTimeout);
          slider.on('updated', nextTimeout);
        },
      ]
    );
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

  clicked(item: ContentItemWeb) {
    this.trackAnalytics.viewFeatured({
      type: 'link',
      title: item.title,
      itemRef: item.itemRef,
      section: this.section?.section,
      sectionStyle: this.section?.sectionStyle,
    });
  }
}
