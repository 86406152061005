import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CCardActions from './ccard.actions';
import { CCard } from '@fan-types/ccard.model';
import { logout } from '../user/user.actions';

export const cCardsFeatureKey = 'cCards';

export interface State extends EntityState<CCard> {
  processing: boolean;
  savingCard: boolean;
}

export const adapter: EntityAdapter<CCard> = createEntityAdapter<CCard>();

export const initialState: State = adapter.getInitialState({
  processing: false,
  savingCard: false,
});

export const reducer = createReducer(
  initialState,
  on(logout, (state) => adapter.removeAll(state)),
  on(CCardActions.addCCard, (state, action) =>
    adapter.addOne(action.cCard, state)
  ),
  on(CCardActions.upsertCCard, (state, action) =>
    adapter.upsertOne(action.cCard, state)
  ),
  on(CCardActions.addCCards, (state, action) =>
    adapter.addMany(action.cCards, state)
  ),
  on(CCardActions.upsertCCards, (state, action) =>
    adapter.upsertMany(action.cCards, state)
  ),
  on(CCardActions.updateCCard, (state, action) =>
    adapter.updateOne(action.cCard, state)
  ),
  on(CCardActions.updateCCards, (state, action) =>
    adapter.updateMany(action.cCards, state)
  ),
  on(CCardActions.deleteCCard, (state, action) => {
    return {
      ...adapter.removeOne(action.id, state),
      processing: false,
    };
  }),
  on(CCardActions.deleteCCards, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(CCardActions.loadCCards, (state, action) => {
    return {
      ...adapter.setAll(action.cCards, state),
      processing: false,
    };
  }),
  on(CCardActions.clearCCards, (state) => adapter.removeAll(state)),

  on(CCardActions.pullCCards, (state) => ({
    ...state,
    processing: true,
  })),

  on(CCardActions.requestDeleteCCard, (state) => ({
    ...state,
    processing: true,
  })),

  on(CCardActions.addCCardStart, (state) => ({
    ...state,
    savingCard: true,
  })),

  on(CCardActions.editCCardStart, (state) => ({
    ...state,
    savingCard: true,
  })),

  on(CCardActions.addCCard, (state) => ({
    ...state,
    savingCard: false,
  })),

  on(CCardActions.upsertCCard, (state) => ({
    ...state,
    savingCard: false,
  })),

  on(CCardActions.editCCardFailed, (state) => ({
    ...state,
    savingCard: false,
  })),

  on(CCardActions.addCCardFailed, (state) => ({
    ...state,
    savingCard: false,
  }))
);
