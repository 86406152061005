<payments-list
  [currency]="selectCurrency"
  [selectable]="!!selectCurrency"
  (cardSelected)="selectCard($event)"
  [canAddCard]="enableAddGeneralCard"
  [hideFoundations]="false"
></payments-list>
<div *ngIf="!enableAddGeneralCard" class="no-add-card mat-caption">
  {{ 'billing.noAddCardHere' | translate }}
</div>
