import { Auction } from '@fan-types/auction.model';
import {
  ContentItem,
  ContentSection,
  ContentSectionResponse,
} from '@fan-types/content';
import { AuctionItem } from '@fan-types/item.model';
import { Team } from '@fan-types/team.model';
import { TeamMapper } from '@fan-services/mappers/team-mapper';
import { environment } from '../../../environments/environment';
import { DEFAULT_THEME } from '@fan-services/theme.service';

export interface ContentMapped {
  sections: ContentSection[];
  auctions: Auction[];
  items: AuctionItem[];
  teams: Team[];
}

export class ContentMapper {
  public static mapItems(contents: ContentSectionResponse[]): ContentMapped {
    const sections: ContentSection[] = [];
    const auctions: { [id: string]: Auction } = {};
    const items: { [id: string]: AuctionItem } = {};
    const teams: { [id: string]: Team } = {};
    contents.forEach((contentResponse) => {
      if ('auctions' in contentResponse) {
        const auctionIds = contentResponse.auctions.map((auction) => {
          auctions[auction.id] = auction;
          return auction.id;
        });
        sections.push({
          section: contentResponse.section,
          auctionIds,
          sectionStyle: contentResponse.sectionStyle,
        });
      } else {
        const contentItems: ContentItem[] = contentResponse.items.map(
          (contentItem) => {
            if (contentItem.itemType === 'auction') {
              const { auction, ...common } = contentItem;
              auctions[auction.id] = auction;
              return common;
            } else if (contentItem.itemType === 'item') {
              const { item, ...common } = contentItem;
              items[item.id] = item;
              return common;
            } else if (contentItem.itemType === 'team') {
              const team = contentItem.team;
              contentItem.assetUrl = contentItem.assetUrl
                ? `${environment.assetsUrl}/${contentItem.assetUrl}`
                : DEFAULT_THEME.placeholderImage!;
              teams[team.id] = TeamMapper.mapItem(team);
              return contentItem;
            } else {
              return contentItem;
            }
          }
        );
        sections.push({
          section: contentResponse.section,
          sectionStyle: contentResponse.sectionStyle,
          items: contentItems,
        });
      }
    });
    return {
      auctions: Object.values(auctions),
      items: Object.values(items),
      teams: Object.values(teams),
      sections,
    };
  }
}
