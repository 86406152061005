import { Component, EventEmitter, Output } from '@angular/core';
import { GlobalState } from '@fan-store/state';
import { sendProfile } from '@fan-store/user/user.actions';
import { selectUserProcessing, selectUserState } from '@fan-store/user/user.selectors';
import { ProfileAddress, UserProfile } from '@fan-types/user-profile';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'shipping-address-list',
  templateUrl: './shipping-address-list.component.html',
  styleUrls: ['./shipping-address-list.component.scss']
})
export class ShippingAddressListComponent {

  profile?: UserProfile;
  processing$ = this.store.select(selectUserProcessing);
  subs?: Subscription;
  addresses: ProfileAddress[] = [];
  @Output() onAddressAction = new EventEmitter<{section: string, index?: number}>();

  constructor(private store: Store<GlobalState>) {
    this.subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) this.profile = { ...profile };
      this.addresses = this.profile?.addresses ?? [];
    });
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  editItem(index: number) {
    this.onAddressAction.emit({section: 'edit', index})
  }

  deleteAddressItem(index: number) {
    const newAddresses = [...this.profile?.addresses ?? []];
    newAddresses.splice(index, 1);
    this.store.dispatch(
      sendProfile({
        profile: {
          addresses: newAddresses
        },
      })
    );
  }

  setDefaultAddress(index: number) {
    const newAddresses = (this.profile?.addresses ?? []).map((address, addressIndex) => {
      return {
        ...address,
        default: addressIndex === index
      };
    });
    this.store.dispatch(
      sendProfile({
        profile: {
          addresses: newAddresses
        },
      })
    );
  }

}
