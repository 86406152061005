<div class="cta-colored-box cta-centered-box">
  <event-status *ngIf="auction" [auction]="auction"></event-status>
</div>
<!-- Donation -->
<div class="cta-box" *ngIf="item?.itemState === 'open'">
  <form>
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>attach_money</mat-icon>
      <input
        cdkFocusInitial
        matInput
        [(ngModel)]="donationAmount"
        name="donationAmount"
        [readonly]="processing$ | async"
        dashNxDecimalNumber
      />
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      id="buy"
      [disabled]="processing$ | async"
      (click)="submit()"
    >
      {{ 'event.donate' | translate | uppercase }}
    </button>
  </form>
  <payment
    *ngIf="currency && profile"
    [segment]="{ currency: currency, foundationId: foundation?.id }"
    (cardSelected)="cardSelected($event)"
    [card]="card"
  ></payment>
  <div class="mat-subtitle-4" *ngIf="!card">
    {{ 'event.nodHint' | translate }}
  </div>
</div>
<mat-progress-bar mode="query" *ngIf="processing$ | async"></mat-progress-bar>
