import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventRouteData } from '../../../events/events.routing.params';
import { buildItemPath, PathSegment } from '../../../helpers/build-item-path';

@Component({
  selector: 'path',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss'],
})
export class PathComponent {
  segments: PathSegment[] = [];
  @Input() color?: string | null;

  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data) => {
      this.buildPath(data);
    });
  }

  private buildPath(data?: EventRouteData) {
    const { segments } = buildItemPath(data, this.route.snapshot.params);
    this.segments = segments;
  }
}
