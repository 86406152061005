import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@fan-env/environment';
import isNonNil from '@fan-helpers/is-non-nil';

export abstract class ApiBase {
  protected readonly apiBase = environment.apiUrl;
  abstract model: string;
  get modelBase() {
    return `${this.apiBase}/${this.model}`;
  }

  constructor(protected http: HttpClient) {}

  modelUri(...args: string[]) {
    return `${this.modelBase}/${args.join('/')}`;
  }

  uri(...args: string[]) {
    return `${this.apiBase}/${args.join('/')}`;
  }

  setParams(paramsTemplate: any) {
    let params = new HttpParams();
    for (const key in paramsTemplate) {
      if (isNonNil(paramsTemplate[key])) {
        const element = paramsTemplate[key];
        params =
          typeof element === 'string'
            ? params.set(key, element)
            : params.set(key, element.toString());
      }
    }
    return params;
  }
}
