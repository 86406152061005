import { environment } from '../../environments/environment';

const appId = environment.appId;
// TODO: Migrate to remote config
export const SOCIALS =
  !appId || appId === 'dash'
    ? {
        facebook: 'https://www.facebook.com/DASHauction/',
      }
    : {
        facebook: 'https://www.facebook.com/dogoodphil/',
      };
