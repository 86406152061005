import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@dash-nx/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { CountdownComponent } from './layout/countdown/countdown.component';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { MatIconModule } from '@angular/material/icon';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarClass } from '../types';
import { PathComponent } from './layout/path/path.component';
import { EventSegmentComponent } from './layout/path/event-segment/event-segment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NotificationsComponent } from './layout/notifications/notifications.component';
import { EventCountdownComponent } from './layout/event-countdown/event-countdown.component';
import { EventCurrencyComponent } from './layout/event-currency/event-currency.component';
import { ImageComponent } from './layout/image/image.component';
import { TeamSegmentComponent } from './layout/path/team-segment/team-segment.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    CountdownComponent,
    PathComponent,
    EventSegmentComponent,
    TeamSegmentComponent,
    NotificationsComponent,
    EventCountdownComponent,
    EventCurrencyComponent,
    ImageComponent,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, panelClass: SnackbarClass.Error },
    },
  ],
  imports: [
    CommonModule,
    UiComponentsModule,
    TranslateModule.forChild({
      extend: true,
    }),
    RouterModule,
    LetDirective, PushPipe,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSelectModule
  ],
  exports: [
    UiComponentsModule,
    PathComponent,
    TranslateModule,
    CountdownComponent,
    RouterModule,
    LetDirective, PushPipe,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    NotificationsComponent,
    EventCountdownComponent,
    EventCurrencyComponent,
    ImageComponent,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
})
export class SharedModule {}
