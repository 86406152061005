<div class="keen-slider main-gallery" #sliderRef>
  <div
    class="keen-slider__slide"
    *ngFor="let th of images; trackBy: trackImageBy"
  >
    <image class="main-image" [src]="th" [overlayImage]="overlayImage"></image>
  </div>
</div>

<div class="keen-slider thumbnail" #thumbnailRef [ngClass]="{'thumbnail-hide': images.length < 2}">
  <div class="keen-slider__slide" *ngFor="let th of images">
    <div class="cover-image" [style.background-image]="'url(' + th + ')'"></div>
  </div>
</div>
