export enum FulfillmentOptionType {
  pickup = 'pickup',
  shipping = 'shipping'
}

export interface FulfillmentOption {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  name?: string;
  type: FulfillmentOptionType;
  description?: string;
  shippingCharge?: number;
  foundationId?: string;

  isOnlyItemOption?: boolean;
}
