import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProfileAddress } from '@fan-types/user-profile';

@Component({
  selector: 'shipping-address-item',
  templateUrl: './shipping-address-item.component.html',
  styleUrls: ['./shipping-address-item.component.scss']
})
export class ShippingAddressItemComponent {
  @Input() address: ProfileAddress = {
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: ''
  }
  @Input() allowDelete = true;
  @Output() onEdit = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<void>();
  @Output() setDefaultAddress = new EventEmitter<void>();

}
