<ng-container [ngSwitch]="currentView">
  <start-form
    *ngSwitchCase="'start'"
    (goToLogin)="currentView = 'login'"
    (goToRegister)="currentView = 'register'"
  >
    <button mat-raised-button (click)="cancel.emit(); (false)" class="mb">
      {{ 'general.cancel' | translate | uppercase }}
    </button>
  </start-form>
  <register-form
    *ngSwitchCase="'register'"
    (goToLogin)="currentView = 'login'"
    [redirectHome]="false"
  >
    <button mat-raised-button (click)="cancel.emit(); (false)" class="mb">
      {{ 'general.cancel' | translate | uppercase }}
    </button>
  </register-form>
  <login-form
    (goToRegister)="currentView = 'register'"
    (goToForgot)="currentView = 'forgot'"
    *ngSwitchCase="'login'"
    [redirectHome]="false"
  >
    <button mat-raised-button (click)="cancel.emit(); (false)" class="mb">
      {{ 'general.cancel' | translate | uppercase }}
    </button>
  </login-form>
  <forgot-form *ngSwitchCase="'forgot'" (goToLogin)="currentView = 'login'"
    >Forgot</forgot-form
  >
  <div *ngSwitchDefault>Loading...</div>
</ng-container>
