import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { EventsRouting } from '@fan-events/events.routing';
import { SharedModule } from '@fan-shared/shared.module';
import { ContentModule } from '../shared/content/content.module';
import { EventComponent } from './event/event.component';
import { ItemComponent } from './item/item.component';
import { EventItemsComponent } from './event/event-items/event-items.component';
import { CardModule } from '../shared/card/card.module';
import { ClosedComponent } from './closed/closed.component';
import { EventStatusComponent } from './event/event-status/event-status.component';
import { TeamHomeComponent } from './team-home/team-home.component';
import { LockerComponent } from './locker/locker.component';
import { DescriptionComponent } from './item/description/description.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ItemGalleryComponent } from './item/item-gallery/item-gallery.component';
import { KeenInstanceComponent } from './item/item-gallery/keen-instance/keen-instance.component';
import { BidComponent } from './item/cta/bid/bid.component';
import { BuyComponent } from './item/cta/buy/buy.component';
import { EnterComponent } from './item/cta/enter/enter.component';
import { CtaComponent } from './item/cta/cta.component';
import { PaymentComponent } from './item/cta/payment/payment.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WinnerMessageComponent } from './item/cta/winner-message/winner-message.component';
import { OnboardingDialogComponent } from './item/cta/onboarding-dialog/onboarding-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OnboardingControllerComponent } from './item/cta/onboarding-dialog/onboarding-controller/onboarding-controller.component';
import { OnboardingModule } from '../shared/onboarding/onboarding.module';
import { PaymentsModule } from '../shared/payments/payments.module';
import { OtBidComponent } from './item/cta/ot-bid/ot-bid.component';
import { OtParticipantsComponent } from './item/cta/ot-bid/ot-participants/ot-participants.component';
import { RelatedItemsComponent } from './item/related-items/related-items.component';
import { MatDividerModule } from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DonationComponent } from './item/cta/donation/donation.component';
import { DecimalNumberDirective } from '@fan-shared/directives/decimal-number.directive';
import { BidConfirmDialogComponent } from './item/cta/bid/bid-confirm-dialog/bid-confirm-dialog.component';
import { BidCompleteDialogComponent } from './item/cta/bid/bid-complete-dialog/bid-complete-dialog.component';
import { FulfillmentOptionComponent } from './item/cta/fulfillment-option/fulfillment-option.component';
import { BidBuynowFulfillmentMethodDialogComponent } from './item/cta/bid/bid-buynow-fulfillment-method-dialog/bid-buynow-fulfillment-method-dialog.component';
import { FulfillmentOptionInfoComponent } from './item/cta/fulfillment-option-info/fulfillment-option-info.component';
import { CustomBuynowComponent } from './item/cta/custom-buynow/custom-buynow.component';

@NgModule({
  declarations: [
    HomeComponent,
    EventComponent,
    ItemComponent,
    EventItemsComponent,
    ClosedComponent,
    EventStatusComponent,
    TeamHomeComponent,
    LockerComponent,
    DescriptionComponent,
    ItemGalleryComponent,
    KeenInstanceComponent,
    BidComponent,
    BuyComponent,
    EnterComponent,
    CtaComponent,
    OtBidComponent,
    OtParticipantsComponent,
    PaymentComponent,
    WinnerMessageComponent,
    OnboardingDialogComponent,
    OnboardingControllerComponent,
    RelatedItemsComponent,
    DonationComponent,
    DecimalNumberDirective,
    BidConfirmDialogComponent,
    BidCompleteDialogComponent,
    FulfillmentOptionComponent,
    BidBuynowFulfillmentMethodDialogComponent,
    FulfillmentOptionInfoComponent,
    CustomBuynowComponent
  ],
  imports: [
    CommonModule,
    EventsRouting,
    SharedModule,
    ContentModule,
    CardModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressBarModule,
    OnboardingModule,
    PaymentsModule,
    MatDividerModule,
    MatTooltipModule
  ],
})
export class EventsModule {}
