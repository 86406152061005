import { NgModule } from '@angular/core';
import { UiComponentsComponent } from './ui-components.component';
import { StateButtonComponent } from './state-button/state-button.component';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input/search-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MainNavComponent } from './main-nav/main-nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HeaderActionsComponent } from './header-actions/header-actions.component';
import {MatMenuModule} from '@angular/material/menu';
import { BaseComponent } from './base/base.component';
import { LoadingComponent } from './loading/loading.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LinkifiedComponent } from './linkified/linkified.component';


@NgModule({
  declarations: [
    UiComponentsComponent,
    StateButtonComponent,
    SearchInputComponent,
    MainNavComponent,
    SideNavComponent,
    HeaderComponent,
    HeaderActionsComponent,
    BaseComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    LinkifiedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatMenuModule,
    MatProgressBarModule,
    MatDialogModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [
    UiComponentsComponent,
    StateButtonComponent,
    SearchInputComponent,
    MainNavComponent,
    HeaderComponent,
    HeaderActionsComponent,
    BaseComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    LinkifiedComponent
  ]
})
export class UiComponentsModule { }
