import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Auction } from '@fan-types/auction.model';
import { ContentItem, ContentItemTeam, ContentItemWeb } from '@fan-types/content';
import { Item } from '@fan-types/item.model';

@Component({
  selector: 'card-wrap',
  templateUrl: './card-wrap.component.html',
  styleUrls: ['./card-wrap.component.scss']
})
export class CardWrapComponent {
  @Output() eventClicked = new EventEmitter<Auction>();
  @Output() itemClicked = new EventEmitter<Item>();
  @Output() teamClicked = new EventEmitter<ContentItemTeam>();
  @Output() webClicked = new EventEmitter<ContentItemWeb>();

  @Input() item?: ContentItem;

  get web() {
    return this.item as ContentItemWeb;
  }
  get team() {
    return this.item as ContentItemTeam;
  }
}
