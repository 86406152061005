import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getItemImage } from '../../../helpers/get-image';
import { isSoldOut } from '../../../helpers/is-sold-out';
import { BuynowItem } from '../../../types';

@Component({
  selector: 'card-bin',
  templateUrl: './card-bin.component.html',
  styleUrls: ['./card-bin.component.scss'],
})
export class CardBinComponent {
  @Output() clicked = new EventEmitter<BuynowItem>();
  image?: string;
  _item?: BuynowItem;
  overlayImage?: string;
  @Input() userBidId?: string;

  @Input()
  set item(item: BuynowItem | undefined) {
    this._item = item;
    this.image = getItemImage(item);
    this.overlayImage = isSoldOut(item) ? '/assets/images/sold_out.png' : undefined;
  }
  get item() {
    return this._item;
  }

  onClick() {
    if (this.item) this.clicked.emit(this.item);
  }
}
