<span *ngFor="let segment of segments; let i = index">
  <span *ngIf="i"> < </span>
  <a
    [style.color]="color"
    class="secondary-link no-decoration"
    *ngIf="segment.type === 'translation'"
    [routerLink]="segment.link"
    >{{ segment.value | translate }}</a
  >
  <team-segment
    [segment]="segment"
    [color]="color"
    *ngIf="segment.type === 'team'"
  ></team-segment>
  <event-segment
    [segment]="segment"
    *ngIf="segment.type === 'event'"
  ></event-segment>
</span>
