import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap } from 'rxjs/operators';

import * as AuctionActions from './auction.actions';
import { ApiService } from '../../services/api.service';
import { AuctionState } from '../../types';

@Injectable()
export class AuctionEffects {
  loadAuction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionActions.pullAuction),
      mergeMap(({ auctionId }) => {
        return this.api.auction.findById(auctionId).pipe(
          mergeMap((auction) => {
            return [AuctionActions.upsertAuction({ auction })];
          })
          //         catchError(error => of(AuthApiActions.loginFailure({ error })))
        );
      })
    );
  });

  startAuction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionActions.startAuction),
      mergeMap(({ auctionId }) => {
        return [
          AuctionActions.updateAuction({
            auction: {
              id: auctionId,
              changes: {
                auctionState: AuctionState.Open,
              },
            },
          }),
        ];
      })
    );
  });

  endAuction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionActions.endAuction),
      mergeMap(({ auctionId }) => {
        return [
          AuctionActions.updateAuction({
            auction: {
              id: auctionId,
              changes: {
                auctionState: AuctionState.Closed,
              },
            },
          }),
        ];
      })
    );
  });

  constructor(private actions$: Actions, private readonly api: ApiService) {}
}
