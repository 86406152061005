import { MyBidResponse } from '../../types';
import { ApiBase } from './api-base';

export class BidsApi extends ApiBase {
  model = 'bids';

  my() {
    return this.http.get<MyBidResponse[]>(this.modelUri('my'));
  }

  ot() {
    return this.http.get<string[]>(this.modelUri('ot'));
  }

  validateFirstBid(itemId: string) {
    return this.http.get<boolean>(this.modelUri('validate-first-bid', itemId));
  }
}
