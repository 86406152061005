import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ContentItemWeb } from '../../../types';

@Component({
  selector: 'card-gallery',
  templateUrl: './card-gallery.component.html',
  styleUrls: ['./card-gallery.component.scss'],
})
export class CardGalleryComponent {
  src?: string;
  _item?: ContentItemWeb;

  @Output() clicked = new EventEmitter<ContentItemWeb>();

  @Input()
  set item(item: ContentItemWeb | undefined) {
    if (!item) return;
    this._item = item;
    this.src = `${environment.assetsUrl}/${item.desktopAssetUrl}`;
  }

  get item() {
    return this._item;
  }
}
