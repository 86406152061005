import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Auction } from '@fan-types/auction.model';

export const startAuction = createAction(
  '[Auction/API] Start auction',
  props<{ auctionId: string }>()
);

export const endAuction = createAction(
  '[Auction/API] End auction',
  props<{ auctionId: string }>()
);

export const pullAuction = createAction(
  '[Auction/API] Pull auction',
  props<{ auctionId: string }>()
);

export const loadAuctions = createAction(
  '[Auction/API] Load Auctions',
  props<{ auctions: Auction[] }>()
);

export const addAuction = createAction(
  '[Auction/API] Add Auction',
  props<{ auction: Auction }>()
);

export const upsertAuction = createAction(
  '[Auction/API] Upsert Auction',
  props<{ auction: Auction }>()
);

export const addAuctions = createAction(
  '[Auction/API] Add Auctions',
  props<{ auctions: Auction[] }>()
);

export const upsertAuctions = createAction(
  '[Auction/API] Upsert Auctions',
  props<{ auctions: Auction[] }>()
);

export const updateAuction = createAction(
  '[Auction/API] Update Auction',
  props<{ auction: Update<Auction> }>()
);

export const updateAuctions = createAction(
  '[Auction/API] Update Auctions',
  props<{ auctions: Update<Auction>[] }>()
);

export const deleteAuction = createAction(
  '[Auction/API] Delete Auction',
  props<{ id: string }>()
);

export const deleteAuctions = createAction(
  '[Auction/API] Delete Auctions',
  props<{ ids: string[] }>()
);

export const clearAuctions = createAction('[Auction/API] Clear Auctions');
