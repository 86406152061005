import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { asyncScheduler } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { StorageService } from '../../services/storage.service';
import { ThemeService } from '../../services/theme.service';
import { pullOTItems } from '../../store/user/user.actions';
import { selectOtItems } from '../../store/user/user.selectors';
import { OtModalComponent } from './ot-modal/ot-modal.component';

const OT_ONBOARDED_KEY = 'otOnboarded';

@Component({
  selector: 'overtime',
  templateUrl: './overtime.component.html',
  styleUrls: ['./overtime.component.scss'],
})
export class OvertimeComponent implements OnInit {
  otItems: string[] = [];
  otOnboarded = false;

  constructor(
    public themeService: ThemeService,
    private dialog: MatDialog,
    private store: Store,
    private router: Router,
    private storage: StorageService
  ) {
    this.store
      .select(selectOtItems)
      .pipe(
        tap((otItems) => (this.otItems = otItems ?? [])),
        throttleTime(15000, asyncScheduler, { leading: false, trailing: true })
      )
      .subscribe((otItems) => {
        // Refresh OT items if there are some in progress
        if (otItems?.length) this.store.dispatch(pullOTItems());
      });
  }

  ngOnInit() {
    try {
      const onboarded = this.storage.getItem(OT_ONBOARDED_KEY);
      this.otOnboarded = !!onboarded;
    } catch (e) {
      //
    }
  }

  cta() {
    if (this.otItems.length === 1) {
      this.router.navigate(['item', this.otItems[0]]);
    } else if (this.otItems.length > 1) {
      this.router.navigate(['locker']);
    } else {
      this.dialog.open(OtModalComponent, {
        panelClass: 'mat-dialog-no-padding',
      });
      this.storage.setItem(OT_ONBOARDED_KEY, 'true');
      this.otOnboarded = true;
    }
  }
}
