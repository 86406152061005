import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLocker from './locker.reducer';

export const selectLockerState = createFeatureSelector<fromLocker.State>(
  fromLocker.lockersFeatureKey
);

export const selectLockerProcessing = createSelector(
  selectLockerState,
  (state) => state.processing
);

export const selectMyLocker = createSelector(
  selectLockerState,
  (state) => state.myBids
);
