import { Component, Input } from '@angular/core';
import { Auction } from '@fan-types/auction.model';
import { ContentItem, ContentItemTeam, ContentItemWeb, ContentSection } from '@fan-types/content';
import { Item } from '@fan-types/item.model';
import { AnalyticsEvents } from 'apps/fan/src/app/analytics/analytics-events.enum';
import { TrackAnalyticsService } from 'apps/fan/src/app/analytics/track-analytics.service';

@Component({
  selector: 'dash-wrap',
  templateUrl: './wrap.component.html',
  styleUrls: ['./wrap.component.scss']
})
export class WrapComponent {

  private _section?: ContentSection;
  items: ContentItem[] = [];

  @Input()
  set section(section: ContentSection | undefined) {
    this._section = section;
    this.items = section && 'items' in section ? section.items : [];
  }
  get section() {
    return this._section;
  }

  constructor(private trackAnalytics: TrackAnalyticsService) {}

  clickEvent(event: Auction) {
    this.trackAnalytics.eventAction(
      AnalyticsEvents.ViewFeaturedItem,
      event.id,
      {
        section: this.section?.section,
        type: 'event',
        sectionStyle: this.section?.sectionStyle,
      }
    );
  }

  clickItem(item: Item) {
    this.trackAnalytics.itemAction(AnalyticsEvents.ViewFeaturedItem, item.id, {
      section: this.section?.section,
      type: 'item',
      sectionStyle: this.section?.sectionStyle,
    });
  }

  clickWeb(item: ContentItemWeb) {
    this.trackAnalytics.viewFeatured({
      type: 'link',
      title: item.title,
      itemRef: item.itemRef,
      section: this.section?.section,
      sectionStyle: this.section?.sectionStyle,
    });
  }

  clickTeam(item: ContentItemTeam) {
    this.trackAnalytics.viewFeatured({
      type: 'team',
      title: item.title,
      itemRef: item.itemRef,
      team: item.team?.name,
      params: item.parms,
      section: this.section?.section,
      sectionStyle: this.section?.sectionStyle,
    });
  }

  trackEventBy(index: number, auctionId: string) {
    return auctionId;
  }

}
