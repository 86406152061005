import { environment } from '../../environments/environment';
import { Theme } from '../types/theme';

const THEMES: Record<string, Theme> = {
  dgp: {
    backgroundColor: '#FFFFFF',
    textColor: '#58646E',
    headerBorderBottom: '1px',
    image: '/assets/images/dgp-logo.png',
  },
  dash: {
    backgroundColor: '#FFFFFF',
    textColor: '#58646E',
    headerBorderBottom: '1px',
    image: '/assets/images/dash-logo.svg',
  },
};

export const DEFAULT_THEME = THEMES[environment.appId];
