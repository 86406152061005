<span *ngIf="fulfillmentOption?.type === 'pickup'">
  {{'shipping.pickup' | translate}}: {{fulfillmentOption?.name}} - {{fulfillmentOption?.description}}
</span>
<span *ngIf="fulfillmentOption?.type === 'shipping' && shippingAddress?.address1" class="shipping-details">
  {{'shipping.shippingTo' | translate}}: <br/>
  <span *ngIf="shippingAddress?.name">{{shippingAddress?.name}}<br/></span>
  <span>{{shippingAddress?.address1}}</span><br/>
  <span *ngIf="shippingAddress?.address2">{{shippingAddress?.address2}}<br/></span>
  <span>{{shippingAddress?.city}}, {{shippingAddress?.state}} {{shippingAddress?.zip}}</span>
</span>
