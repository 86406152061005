import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isValidLanguage } from '../../../types';

@Component({
  selector: 'translate',
  template: '',
})
export class TranslateComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
  ) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    const lang  = isValidLanguage(this.translateService.getBrowserLang());
    this.translateService.use(lang ?? 'en');
  }

}
