<ng-container *ngrxLet="auction$ as auction">
  <div
    [style.backgroundColor]="(themeService.theme | async)?.backgroundColor"
    [style.color]="(themeService.theme | async)?.textColor"
    class="event-container"
  >
    <div class="max-width">
      <div class="event-header">
        <path [color]="(themeService.theme | async)?.textColor"></path>
        <span class="mat-subheading-2">{{ auction?.title }}</span>
        <event-status [auction]="auction"></event-status>
      </div>
    </div>
  </div>
</ng-container>
<div class="max-width event-container" *ngrxLet="auction$ as auction">
  <event-items [auctionId]="auctionId" [auction]="auction"></event-items>
</div>
