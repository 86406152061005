import { map } from 'rxjs/operators';
import { Team } from '../../types';
import { TeamMapper } from '../mappers/team-mapper';
import { ApiBase } from './api-base';

export type GetThemeParams =
  | {
      team: string;
    }
  | { foundationId: string }
  | { auctionId: string };

export class TeamApi extends ApiBase {
  model = 'teams';

  getTeam(params: GetThemeParams) {
    return this.http
      .get<Team>(this.modelUri('theme'), {
        params: this.setParams(params),
      })
      .pipe(map((t) => TeamMapper.mapItem(t)));
  }
}
