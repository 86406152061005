<shipping-address-list
  *ngIf="section === 'list'; else addressForm"
  (onAddressAction)="onAddressAction($event)">
</shipping-address-list>
<ng-template #addressForm>
  <shipping-form
    [section]="section"
    [addressIndex]="shippingItemIndex"
    (onCancel)="section = 'list'"
    (onSave)="section = 'list'"
  ></shipping-form>
</ng-template>
