import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CONTENT_LINKS } from '../../config/content-links';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  termsUrl?: SafeResourceUrl;

  constructor(
    // private userService: UserService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const lang: keyof typeof CONTENT_LINKS.terms = this.translate
      .currentLang as any;
    const url = CONTENT_LINKS.terms[lang] ?? CONTENT_LINKS.terms.en;
    this.termsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
