import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../store';
import { selectProcessing } from '../../store/selectors';

@Component({
  selector: 'dash-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  processing$ = this.store.select(selectProcessing);
  constructor(private store: Store<GlobalState>) {}
}
