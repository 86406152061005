import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../store';
import { recoverAccessToken } from '../../store/user/user.actions';

@Component({
  selector: 'integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  constructor(private store: Store<GlobalState>) {}

  ngOnInit(): void {
    this.store.dispatch(recoverAccessToken());
  }
}
