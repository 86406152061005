import { Params } from '@angular/router';
import { EventRouteData } from '../events/events.routing.params';

export type PathSegment = {
  link: string[];
  type: 'translation' | 'event' | 'team';
  value: string;
};

export const buildItemPath = (data?: EventRouteData, routerParams?: Params) => {
  const routes: string[] = ['/'];
  // Always start with All Events
  const segments: PathSegment[] = [
    {
      value: 'nav.events',
      type: 'translation',
      link: [...routes],
    },
  ];
  // const routerParams = this.route.snapshot.params;
  data?.segments?.forEach((s) => {
    // Team
    if (s === 'team' && routerParams?.team) {
      routes.push('team', routerParams.team);
      segments.push({
        value: routerParams.team,
        type: 'team',
        link: [...routes],
      });
    }
    // Closed Events Events
    if (s === 'closed') {
      routes.push('closed-events');
      segments.push({
        value: 'nav.closedEvents',
        type: 'translation',
        link: [...routes],
      });
    }
    // My Locker
    if (s === 'locker') {
      routes.push('locker');
      segments.push({
        value: 'nav.myLocker',
        type: 'translation',
        link: [...routes],
      });
    }
  });
  if (data?.path === 'item' && routerParams?.id) {
    segments.push({
      value: routerParams.id,
      type: 'event',
      link: [...routes],
    });
  }
  return {
    segments,
    routes,
  };
};
