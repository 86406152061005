<mat-dialog-content>
  <div class="bid-buynow-fulfillment-method-container">
    <span class="mat-h5">{{'shipping.fulfillmentMethod' | translate}}</span>
    <fulfillment-option
      [item]="item"
      [requireFulfillmentMethod]="true"
      (onFulfillmentOptionUpdated)="confirmFulfillmentMethod($event)"
      (editState)="fulfillmentOptionState($event)"
      [requireConfirmButton]="true"
    ></fulfillment-option>
  </div>
</mat-dialog-content>
