import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, of, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { LocationService } from '../../../../services/location.service';
import { GlobalState } from '../../../../store';
import { selectUserState } from '../../../../store/user/user.selectors';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import {
  Auction,
  GiveawayData,
  GiveawayItem,
  UserProfile,
} from '../../../../types';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';

@Component({
  selector: 'enter',
  templateUrl: './enter.component.html',
  styleUrls: ['./enter.component.scss'],
})
export class EnterComponent implements OnDestroy {
  @Input() item?: GiveawayItem;
  @Input() auction?: Auction | null;
  subs: Subscription[] = [];
  tmpProfileId?: string;

  data: Partial<UserProfile> = {};
  disableInputs = false;
  loading = false;
  winnerMessage: string | undefined;

  constructor(
    private store: Store<GlobalState>,
    private api: ApiService,
    private locationService: LocationService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private trackAnalytics: TrackAnalyticsService
  ) {
    const subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) this.data = { ...profile };
      this.disableInputs = !!this.data.id;
    });
    this.subs.push(subs);
  }

  isSectionVisible(section: GiveawayData) {
    const data = this.item?.giveawayRequiredData || [];
    return data.includes(section);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private getProfile() {
    if (this.data.id) return of(this.data.id);
    if (this.tmpProfileId) return of(this.tmpProfileId);
    return this.api.user.createProfile(this.data).pipe(
      map((profile) => {
        this.tmpProfileId = profile.id;
        return profile.id;
      })
    );
  }

  // logPixelEvent(quantity = 1, amount: number) {
  //   const pixelId = this.auctionItem?.auction?.foundation?.fbPixelTracking;
  //   if (!pixelId) return;
  //   this.pixel.track(pixelId, 'Purchase', {
  //     currency: 'USD',
  //     value: amount,
  //     num_items: quantity,
  //     content_ids: [this.auctionItem.id],
  //     content_name: this.auctionItem.title,
  //     content_type: 'product',
  //     contents: [{ id: this.auctionItem.id, quantity }],
  //   });
  // }

  submit() {
    this.loading = true;
    const itemId = this.item?.id;
    if (!itemId) return;
    const data: ConfirmDialogData = {
      confirmKey: '',
      bodyHtmlKey: 'giveaway.confirmTos',
      okKey: 'general.agree',
      cancelKey: 'general.cancel',
      maxWidth: '500px'
    };
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data,
    });
    ref.afterClosed().subscribe((res) => {
      if (!res) {
        this.loading = false;
        return;
      }
      forkJoin([
        this.locationService.getActivationLocation(this.item),
        this.getProfile(),
      ])
        .pipe(
          mergeMap(([coords, profileId]) => {
            const body = this.disableInputs ? {} : this.data;
            return this.api.auctionItem.enter(itemId, {
              ...body,
              profileId,
              location: coords?.toObj(),
            });
          })
        )
        .subscribe(
          () => this.onSuccess(),
          (err) => this.onError(err)
        );
    });
  }

  private onSuccess() {
    this.loading = false;
    const data: ConfirmDialogData = {
      confirmKey: 'giveaway.enterTitle',
      okKey: 'modal.ok',
      hideCancel: true,
      bodyKey: 'giveaway.enterDone',
    };
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data,
    });
    ref.afterClosed().subscribe(() => {
      this.router.navigate(['../../event', this.item?.auctionId], {
        relativeTo: this.route,
      });
    });
    if (this.item?.id) this.trackAnalytics.enterGiveaway(this.item?.id);
  }
  private onError(err: any) {
    this.loading = false;
    const strError = typeof err === 'string' ? err : undefined;
    const message =
      err?.error?.error?.message ??
      strError ??
      err?.message ??
      'There was an error processing the transaction';
    const data: ConfirmDialogData = {
      confirmText: message,
      okKey: 'modal.ok',
      hideCancel: true,
    };
    this.dialog.open(ConfirmDialogComponent, {
      data,
    });
  }
}
