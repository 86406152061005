<ng-container [ngSwitch]="step">
  <sign-nav (cancel)="cancel.emit()" *ngSwitchCase="'login'">Login</sign-nav>
  <shipping-form
    [title]="'shipping.bidHint' | translate"
    [section]="'onboarding'"
    *ngSwitchCase="'shipping'"
  ></shipping-form>
  <div *ngSwitchCase="'payment'">
    <payment-form-selector
      [currency]="currency"
      [foundationId]="foundationId"
      [title]="'billing.addCardHint1' | translate"
    >
    </payment-form-selector>
  </div>
  <div *ngSwitchDefault>Loading...</div>
</ng-container>
