<div class="form-body" novalidate>
  <div *ngIf="title" class="mat-h5">{{ title }}</div>
  <mat-form-field appearance="outline" *ngIf="!currency">
    <mat-label>{{ 'general.market' | translate }}</mat-label>
    <mat-select
      required
      [value]="selectedCurrency()"
      (selectionChange)="selectedCurrency.set($event.value)"
    >
      <mat-option value="USD">United States</mat-option>
      <mat-option value="CAD">Canada</mat-option>
    </mat-select>
  </mat-form-field>
  <payment-form-api
    *ngIf="formType() === 'API'"
    [currency]="currency || selectedCurrency()"
    [foundationId]="foundationId"
    (cardAdded)="cardAdded.emit($event)"
  ></payment-form-api>
  <payment-form-deluxe
    *ngIf="formType() === 'DELUXE'"
    [currency]="currency || selectedCurrency()"
    [foundationId]="foundationId"
    (cardAdded)="cardAdded.emit($event)"
  ></payment-form-deluxe>
</div>
