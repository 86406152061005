import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../../store';
import { selectById } from '../../../store/auction/auction.reducer';
import { Auction } from '../../../types';

@Component({
  selector: 'event-countdown',
  templateUrl: './event-countdown.component.html',
  styleUrls: ['./event-countdown.component.scss'],
})
export class EventCountdownComponent {
  auction$?: Observable<Auction | undefined>;
  _auctionId: string | undefined;

  @Input()
  set auctionId(auctionId: string | undefined) {
    this._auctionId = auctionId;
    if (auctionId) {
      this.auction$ = this.store.select(selectById(auctionId));
    }
  }
  get auctionId() {
    return this._auctionId;
  }

  constructor(private store: Store<GlobalState>) {}
}
