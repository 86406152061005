import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../../../store';
import { selectCCardByCurrency } from '../../../../store/ccard/ccard.selectors';
import { selectProfile } from '../../../../store/user/user.selectors';
import { CCard, Currency } from '../../../../types';

export type OnboardingDialogData = {
  currency: Currency;
  foundationId?: string;
};

@Component({
  selector: 'onboarding-dialog',
  templateUrl: './onboarding-dialog.component.html',
  styleUrls: ['./onboarding-dialog.component.scss'],
})
export class OnboardingDialogComponent {
  profile$ = this.store.select(selectProfile);
  currency: Currency;
  foundationId?: string;
  ccards$: Observable<CCard[]>;
  constructor(
    private store: Store<GlobalState>,
    public dialogRef: MatDialogRef<OnboardingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: OnboardingDialogData
  ) {
    this.currency = dialogData.currency;
    this.foundationId = dialogData.foundationId;
    this.ccards$ = this.store.select(selectCCardByCurrency(this.currency));
  }

  cancel() {
    this.dialogRef.close();
  }

  cardAdded(card: CCard) {
    this.dialogRef.close({ card, isNewProfile: false });
  }
}
