import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as ContentActions from './content.actions';
import * as AuctionActions from '@fan-store/auction/auction.actions';
import * as ItemActions from '@fan-store/item/item.actions';
import * as TeamActions from '@fan-store/team/team.actions';
import { ApiService } from '@fan-services/api.service';
import { ContentMapper } from '@fan-services/mappers/content-mapper';
import { Content } from '@fan-types/content';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { selectAll } from './content.reducer';
import { GlobalState } from '../state';
import { Store } from '@ngrx/store';

@Injectable()
export class ContentEffects {
  loadContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContentActions.pullContent),
      withLatestFrom(this.store.select(selectAll)),
      mergeMap(([{ contentId, limit, isTeam }, items]) => {
        const lastContent = items.find((i) => i.id === contentId);
        const skip = lastContent?.count;
        const obs = isTeam
          ? this.api.content.teamContentByName(contentId)
          : this.api.content.getContent(
              contentId,
              limit ? { limit, skip } : undefined
            );
        return obs.pipe(
          map((content) => ContentMapper.mapItems(content)),
          mergeMap(({ items, teams, sections, auctions }) => {
            const mergedSections = limit
              ? sections.map((s, i) => {
                  const existingSection = lastContent?.sections[i];
                  if ('auctionIds' in s) {
                    const auctionIds =
                      existingSection && 'auctionIds' in existingSection
                        ? existingSection.auctionIds
                        : [];
                    return {
                      ...s,
                      auctionIds: [...auctionIds, ...s.auctionIds],
                    };
                  } else {
                    const items =
                      existingSection && 'items' in existingSection
                        ? existingSection.items
                        : [];

                    return {
                      ...s,
                      items: [...items, ...s.items],
                    };
                  }
                })
              : sections;
            const ttItems = mergedSections.reduce((p, c) => {
              return (
                p + ('auctionIds' in c ? c.auctionIds.length : c.items.length)
              );
            }, 0);

            const content: Content = {
              id: contentId,
              sections: mergedSections,
              hasMore: limit
                ? items.length + auctions.length >= limit
                : undefined,
              count: ttItems,
            };
            return [
              ContentActions.upsertContent({ content }),
              ItemActions.upsertItems({ items }),
              TeamActions.upsertTeams({ teams }),
              AuctionActions.upsertAuctions({ auctions }),
            ];
          }),
          catchError((error) => this.contentError(error))
        );
      })
    );
  });

  private contentError(error: any) {
    const message = error?.error?.error?.message ?? 'Error pulling the content';
    this.snackBar.open(message);
    return of(ContentActions.pullContentFailed());
  }

  constructor(
    private store: Store<GlobalState>,
    private actions$: Actions,
    private readonly api: ApiService,
    private snackBar: MatSnackBar
  ) {}
}
