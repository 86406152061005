<div class="cta-colored-box cta-centered-box">
  <event-status *ngIf="auction" [auction]="auction"></event-status>
</div>
<!-- Enter -->
<div class="cta-box form-page" *ngIf="item?.itemState === 'open'">
  <form #accountData="ngForm" class="form-body" (ngSubmit)="submit()">
    <mat-form-field appearance="outline" *ngIf="isSectionVisible('firstName')">
      <mat-label>{{ 'account.firstName' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="data.firstName"
        name="first-name"
        required
        [disabled]="disableInputs || loading"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="isSectionVisible('lastName')">
      <mat-label>{{ 'account.lastName' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="data.lastName"
        name="last-name"
        required
        [disabled]="disableInputs || loading"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="isSectionVisible('email')">
      <mat-label>{{ 'account.email' | translate }}</mat-label>
      <input
        matInput
        type="email"
        [(ngModel)]="data.email"
        name="email"
        required
        [disabled]="disableInputs || loading"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      *ngIf="isSectionVisible('contactNumber')"
    >
      <mat-label>{{ 'account.phoneNumber' | translate }}</mat-label>
      <input
        matInput
        type="phone"
        [(ngModel)]="data.contactNumber"
        name="phoneNumber"
        required
        [disabled]="disableInputs || loading"
      />
    </mat-form-field>
    <label *ngIf="disableInputs"
      >{{ 'giveaway.editProfileTitle' | translate }}
      <a class="secondary-link" routerLink="/profile/account">{{
        'giveaway.editProfileAction' | translate
      }}</a></label
    >
    <button
      id="enter"
      type="submit"
      mat-raised-button
      color="primary"
      (click)="submit()"
      [disabled]="loading || (!accountData.valid && !data.id)"
    >
      {{ 'event.enter' | translate | uppercase }}
    </button>
  </form>
</div>
<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
<!-- winner -->
<winner-message [item]="item" [profileId]="data.id"></winner-message>
