<h5>{{ section?.section }}</h5>
<div *ngFor="let auctionId of auctionIds; trackBy: trackEventBy">
  <card-event
    [auctionId]="auctionId"
    (clicked)="clickEvent($event)"
  ></card-event>
</div>
<div *ngFor="let item of items;">
  <card-stacked
    (eventClicked)="clickEvent($event)"
    (itemClicked)="clickItem($event)"
    (teamClicked)="clickTeam($event)"
    (webClicked)="clickWeb($event)"
    [item]="item"
  ></card-stacked>
</div>
