import { ApiBase } from './api-base';
import { NewDeviceBody, Device } from '../../types/user-device';

export class DeviceApi extends ApiBase {
  model = 'devices';

  addDevice(device: NewDeviceBody) {
    return this.http.post<Device>(this.modelBase, device);
  }
}
