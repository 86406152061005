import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CONTENT_LINKS } from '../../config/content-links';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  privacyUrl?: SafeResourceUrl;

  constructor(
    // private userService: UserService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const lang: keyof typeof CONTENT_LINKS.privacy = this.translate
      .currentLang as any;
    const url = CONTENT_LINKS.privacy[lang] ?? CONTENT_LINKS.privacy.en;
    this.privacyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
