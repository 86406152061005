import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';

@Component({
  selector: 'ot-modal',
  templateUrl: './ot-modal.component.html',
  styleUrls: ['./ot-modal.component.scss'],
})
export class OtModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sliderRef') sliderRef?: ElementRef<HTMLElement>;

  slider?: KeenSliderInstance;
  action = 'general.next';
  currentSlide = 0;
  get slides() {
    return this.slider?.slides.length ?? 0;
  }

  constructor(private dialogRef: MatDialogRef<OtModalComponent>) {}

  ngAfterViewInit() {
    if (this.sliderRef)
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        slideChanged: (s) => this.slideChanged(s.track.details.rel),
      });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

  private slideChanged(currentSlide: number) {
    this.currentSlide = currentSlide;
    this.action =
      this.currentSlide >= this.slides - 1
        ? 'general.getStarted'
        : 'general.next';
  }

  onActionClicked() {
    if (this.currentSlide >= this.slides - 1) return this.dialogRef.close();
    this.slider?.next();
  }
}
