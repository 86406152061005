import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getEventImage } from '../../../helpers/get-image';
import { GlobalState } from '../../../store';
import { selectById } from '../../../store/auction/auction.reducer';
import { Auction } from '../../../types';
import { LayoutTypeService } from '../layout-type.service';

@Component({
  selector: 'card-event',
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss'],
})
export class CardEventComponent {
  @Output() clicked = new EventEmitter<Auction>();
  @Input()
  set less(value: boolean) {
    if (value) this.layoutTypeService.less = true;
  }

  auction$?: Observable<Auction | undefined>;
  getEventImage = getEventImage;

  @Input()
  set auctionId(auctionId: string) {
    this.auction$ = this.store.select(selectById(auctionId));
  }

  constructor(
    private store: Store<GlobalState>,
    private layoutTypeService: LayoutTypeService
  ) {}

  onClick(auction: Auction | undefined) {
    if (auction) this.clicked.next(auction);
  }
}
