import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { AnalyticsEvents } from '../../../../analytics/analytics-events.enum';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';
import {
  Auction,
  ContentItem,
  ContentItemTeam,
  ContentItemWeb,
  ContentSection,
  Item,
} from '../../../../types';

@Component({
  selector: 'dash-round',
  templateUrl: './round.component.html',
  styleUrls: ['./round.component.scss'],
})
export class RoundComponent implements AfterViewInit, OnDestroy {
  private _section?: ContentSection;
  auctionIds: string[] = [];
  items: ContentItem[] = [];
  sliderData = {
    currentSlide: 0,
    perView: 0,
    moreRight: false,
    moreLeft: false,
    slidesCount: 0,
  };

  @Input()
  set section(section: ContentSection | undefined) {
    this._section = section;
    this.auctionIds =
      section && 'auctionIds' in section ? section.auctionIds : [];
    this.items = section && 'items' in section ? section.items : [];
    this.sliderData.slidesCount = this.items.length + this.auctionIds.length;
    this.updateSlide();
  }
  get section() {
    return this._section;
  }

  @ViewChild('sliderRef') sliderRef?: ElementRef<HTMLElement>;
  slider?: KeenSliderInstance;

  constructor(private trackAnalytics: TrackAnalyticsService) {}

  ngAfterViewInit() {
    setTimeout(() => this.initSlider());
  }
  private initSlider() {
    if (!this.sliderRef) return;
    this.slider = new KeenSlider(
      this.sliderRef.nativeElement,
      {
        slideChanged: (s) => {
          this.sliderData.currentSlide = s.track.details.rel;
          this.updateSlide();
        },
        slides: {
          perView: () => {
            const perView = (() => {
              const t = Math.round(window.innerWidth / 400);
              if (t >= 3) return 3.2;
              return 2.2;
            })();
            this.sliderData.perView = perView;
            this.updateSlide();
            return perView;
          },
          spacing: 15,
        },
      },
      [
        (slider) => {
          slider.on('created', () => {
            this.updateSlide();
          });
        },
      ]
    );
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }
  private updateSlide() {
    const slides = this.sliderData.slidesCount;
    this.sliderData.moreLeft =
      this.sliderData.currentSlide > 0 && slides > this.sliderData.perView;
    this.sliderData.moreRight =
      this.sliderData.currentSlide < slides - this.sliderData.perView &&
      slides > this.sliderData.perView;
  }
  clickEvent(event: Auction) {
    this.trackAnalytics.eventAction(
      AnalyticsEvents.ViewFeaturedItem,
      event.id,
      {
        section: this.section?.section,
        type: 'event',
        sectionStyle: this.section?.sectionStyle,
      }
    );
  }

  clickItem(item: Item) {
    this.trackAnalytics.itemAction(AnalyticsEvents.ViewFeaturedItem, item.id, {
      section: this.section?.section,
      type: 'item',
      sectionStyle: this.section?.sectionStyle,
    });
  }

  clickWeb(item: ContentItemWeb) {
    this.trackAnalytics.viewFeatured({
      type: 'link',
      title: item.title,
      itemRef: item.itemRef,
      section: this.section?.section,
      sectionStyle: this.section?.sectionStyle,
    });
  }

  clickTeam(item: ContentItemTeam) {
    this.trackAnalytics.viewFeatured({
      type: 'team',
      title: item.title,
      itemRef: item.itemRef,
      team: item.team,
      params: item.parms,
      section: this.section?.section,
      sectionStyle: this.section?.sectionStyle,
    });
  }
}
