import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FirebaseMessagingService } from './firebase-messaging.service';
import { StorageService } from './storage.service';
import { Store } from '@ngrx/store';
import { GlobalState } from '../store';
import { selectUserState } from '../store/user/user.selectors';
import { UserProfile } from '../types';

const NUM_OF_VISITS_TO_REQUEST = 5;

@Injectable({ providedIn: 'root' })
export class RequestPushNotificationsService {
  hasRequested = false;
  pagesVisited = 0;
  profile: UserProfile | undefined;
  constructor(
    private router: Router,
    private fbmService: FirebaseMessagingService,
    private storage: StorageService,
    private store: Store<GlobalState>
  ) {
    const hasRequested = this.storage?.getItem(
      'hasRequestedNotificationPermission'
    );
    if (hasRequested) {
      this.hasRequested = true;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewPage();
      }
    });
    this.store.select(selectUserState).subscribe(({ profile }) => {
      this.profile = profile;
    });
  }

  viewPage() {
    if (this.profile?.id && !this.hasRequested) {
      this.pagesVisited++;
      this.shouldRequestPermission();
    } else {
      this.pagesVisited = 0;
    }
  }
  shouldRequestPermission() {
    if (this.pagesVisited >= NUM_OF_VISITS_TO_REQUEST) {
      this.hasRequested = true;
      this.fbmService.requestPermission();
    }
  }
}
