import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help/help.component';
import { ContentRouting } from './content.routing';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';



@NgModule({
  declarations: [
    HelpComponent,
    PrivacyComponent,
    TermsComponent
  ],
  imports: [
    CommonModule,
    ContentRouting
  ]
})
export class ContentModule { }
