<div class="form-page">
  <div class="form-header">
    <a class="title active">{{ 'auth.welcome' | translate }}</a>
    <div class="subtitle">{{ 'auth.logInHint' | translate }}</div>
  </div>
  <form #loginData="ngForm" class="form-body" (ngSubmit)="submit()">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.email' | translate }}</mat-label>
      <input
        autofocus
        matInput
        type="email"
        [(ngModel)]="credentials.email"
        name="user"
        autocomplete="email"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'auth.pwd' | translate }}</mat-label>
      <input
        matInput
        type="password"
        [(ngModel)]="credentials.password"
        name="password"
        autocomplete="current-password"
        [readonly]="processing$ | async"
        required
      />
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="(processing$ | async) || !loginData.valid"
    >
      {{ 'auth.logIn' | translate }}
    </button>

    <a class="secondary-link mb" (click)="forgotClick(); (false)">{{
      'auth.forgotAction' | translate
    }}</a>

    <div class="mb">
      <span>{{ 'auth.goSignUp' | translate }}</span>
    </div>
    <button class="mb" mat-raised-button (click)="registerClick(); (false)">
      {{ 'auth.signUp' | translate | uppercase }}
    </button>
    <ng-content></ng-content>
  </form>
</div>
