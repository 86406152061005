<div class="cta" *ngIf="canAddCard">
  <span>{{ 'billing.addCardHint2' | translate }}</span>
  <button mat-stroked-button color="accent" (click)="add()" class="add-card">
    <mat-icon class="left-icon mobile-hide" aria-hidden="false" aria-label="Add"
      >add</mat-icon
    >
    {{ 'billing.addCard' | translate | uppercase }}
  </button>
</div>

<ng-container *ngrxLet="cards$ as cards">
  <div *ngFor="let card of cards; let i = index">
    <mat-divider *ngIf="i"></mat-divider>
    <div class="ccard">
      <div class="title">
        {{ card.brand | titlecase }} {{ 'billing.endingIn' | translate }}
        {{ card.last4 }} -
        {{ card.currency }}
        <div *ngIf="!hideFoundations && (card.foundations?.length ?? false)">
          {{
            'billing.cardFoundations'
              | translate : { value: card.foundations?.join(', ') ?? '' }
          }}
        </div>
      </div>
      <div class="actions">
        <button mat-stroked-button (click)="delete(card)" *ngIf="!selectable">
          {{ 'general.delete' | translate | uppercase }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="edit(card)"
          *ngIf="!selectable && canEditCard"
        >
          {{ 'general.edit' | translate | uppercase }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="cardSelected.next(card)"
          *ngIf="selectable"
        >
          {{ 'general.select' | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
