import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../../store';
import { selectById as selectItemsByAuctionId } from '../../../store/auction-items/auction-items.reducer';
import { Auction, AuctionItems } from '../../../types';
import * as AuctionItemActions from '@fan-store/auction-items/auction-items.actions';
import { TrackAnalyticsService } from '../../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../../analytics/analytics-events.enum';

@Component({
  selector: 'event-items',
  templateUrl: './event-items.component.html',
  styleUrls: ['./event-items.component.scss'],
})
export class EventItemsComponent {
  items$?: Observable<AuctionItems | undefined>;
  @Input()
  skip: string | undefined;
  @Input()
  showTitle: string | undefined;
  @Input()
  auction?: Auction;

  @Input()
  set auctionId(auctionId: string | undefined) {
    if (auctionId) {
      this.store.dispatch(AuctionItemActions.pullAuctionItems({ auctionId }));
      this.items$ = this.store.select(selectItemsByAuctionId(auctionId));
    }
  }

  constructor(
    private store: Store<GlobalState>,
    private trackAnalytics: TrackAnalyticsService
  ) {}

  clickItem(itemId: string) {
    this.trackAnalytics.itemAction(
      AnalyticsEvents.ClickItemFromEventDetail,
      itemId
    );
  }
}
