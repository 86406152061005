import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';
import { SignRouting } from './sign.routing';
import { OnboardingModule } from '../shared/onboarding/onboarding.module';

@NgModule({
  declarations: [LoginComponent, RegisterComponent, ForgotComponent],
  imports: [SignRouting, CommonModule, OnboardingModule],
})
export class SignModule {}
