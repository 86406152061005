import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ContentActions from './content.actions';
import { createEntitySelectors } from '../helpers/merge-selectors';
import { GlobalState } from '../state';
import { Content } from '@fan-types/content';

export const contentFeatureKey = 'content';

export interface State extends EntityState<Content> {
  processing: boolean;
}

export const adapter: EntityAdapter<Content> = createEntityAdapter<Content>();

export const initialState: State = adapter.getInitialState({
  processing: false,
});

export const reducer = createReducer(
  initialState,
  on(ContentActions.addContent, (state, action) =>
    adapter.addOne(action.content, state)
  ),
  on(ContentActions.upsertContent, (state, action) => {
    return {
      ...adapter.upsertOne(action.content, state),
      processing: false,
    };
  }),
  on(ContentActions.addContents, (state, action) =>
    adapter.addMany(action.contents, state)
  ),
  on(ContentActions.upsertContents, (state, action) =>
    adapter.upsertMany(action.contents, state)
  ),
  on(ContentActions.updateContent, (state, action) =>
    adapter.updateOne(action.content, state)
  ),
  on(ContentActions.updateContents, (state, action) =>
    adapter.updateMany(action.contents, state)
  ),
  on(ContentActions.deleteContent, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ContentActions.deleteContents, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ContentActions.loadContents, (state, action) =>
    adapter.setAll(action.contents, state)
  ),
  on(ContentActions.clearContents, (state) => adapter.removeAll(state)),
  on(ContentActions.pullContent, (state) => ({
    ...state,
    processing: true,
  })),
  on(ContentActions.pullContentFailed, (state) => ({
    ...state,
    processing: false,
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  createEntitySelectors(adapter, (state: GlobalState) => state.content);
