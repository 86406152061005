import { RouterModule, Routes } from '@angular/router';
import { AnalyticsEvents } from '../analytics/analytics-events.enum';
import { HelpComponent } from './help/help.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';

export const ContentRoutes: Routes = [
  {
    path: 'help',
    component: HelpComponent,
    data: {
      analyticsEvent: AnalyticsEvents.HelpViewed
    }
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      analyticsEvent: AnalyticsEvents.PrivacyPolicyViewed
    }
  },
  {
    path: 'terms',
    component: TermsComponent,
    data: {
      analyticsEvent: AnalyticsEvents.TermsViewed
    }
  }
];

export const ContentRouting = RouterModule.forChild(ContentRoutes);
