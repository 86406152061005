<span class="mat-h5">
  <ng-container
    *ngIf="auction?.auctionState === 'pending' && auction?.auctionStart"
    >{{ 'event.startTime' | translate }}:
    {{ auction?.auctionStart | date: 'short' }}</ng-container
  >
  <ng-container
    *ngIf="
      !countdown &&
      (auction?.auctionState !== 'pending' || !auction?.auctionStart)
    "
    >{{ 'event.' + auction?.auctionState | translate }}</ng-container
  >
  <ng-container *ngIf="countdown"
    >{{ 'event.timeLeft' | translate }}
    <countdown *ngIf="countdown" [time]="countdown"></countdown
  ></ng-container>
</span>
