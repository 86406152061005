import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { AuctionItem, GiveawayItem } from '../../../../types';

@Component({
  selector: 'winner-message',
  templateUrl: './winner-message.component.html',
  styleUrls: ['./winner-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WinnerMessageComponent implements OnChanges {
  winnerMessage: string | undefined;
  @Input() profileId?: string;
  @Input() item?: AuctionItem | GiveawayItem;

  ngOnChanges(): void {
    this.winnerMessage = (() => {
      if (!this.profileId) return;
      if (!this.item) return;
      const winners = this.item?.winnerProfileIds ?? [];
      if (!winners.includes(this.profileId)) return;
      return this.item.winnerMessage;
    })();
  }
}
