<div class="shipping-item-container">
  <div class="address-header">
    <span class="mat-h6 address-name">{{address.name}}</span>
    <button mat-stroked-button class="simple-icon edit-icon" (click)="onEdit.emit()">
      <mat-icon>edit</mat-icon>
    </button>
    <button *ngIf="allowDelete" mat-stroked-button class="simple-icon delete-icon" (click)="onDelete.emit()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <span>{{address.address1}}<span *ngIf="address.address2" class="address-2">{{address.address2}}</span></span>
  <div class="address-row">
    <span>{{address.city}}, {{address.state}} {{address.zip}}</span>
    <span *ngIf="address.default" class="default-address">{{'shipping.default' | translate | uppercase}}</span>
    <a *ngIf="!address.default" class="set-default" (click)="setDefaultAddress.emit()">{{'shipping.setDefault' | translate}}</a>
  </div>
</div>
