import { Fave, League } from '../../types/fave';
import { ApiBase } from './api-base';

export class FavesApi extends ApiBase {
  model = 'faves';

  sports() {
    return this.http.get<string[]>(this.modelUri('sports'));
  }

  list(params: {
    skip?: number;
    limit?: number;
    order?: string[];
    q?: string;
    sport?: string;
    profileId?: string;
    isFaved?: boolean;
  }) {
    return this.http.get<Fave[]>(this.modelBase, {
      params: this.setParams(params),
    });
  }

  grouped(
    params: {
      skip?: number;
      limit?: number;
      order?: string[];
      q?: string;
      profileId?: string;
      isFaved?: boolean;
      sport?: string;
    }
  ) {
    return this.http.get<League[]>(this.modelUri('grouped'), {
      params: this.setParams(params),
    });
  }

  addFave(profileId: string, faveId: string) {
    return this.http.put<Fave>(
      this.uri('users', profileId, 'faves', faveId),
      {}
    );
  }

  deleteFave(profileId: string, faveId: string) {
    return this.http.delete<{ count: number }>(
      this.uri('users', profileId, 'faves', faveId),
      {}
    );
  }
}
