import { Component, EventEmitter, Output } from '@angular/core';

type SignView = 'start' | 'login' | 'forgot' | 'register';

@Component({
  selector: 'sign-nav',
  templateUrl: './sign-nav.component.html',
  styleUrls: ['./sign-nav.component.scss'],
})
export class SignNavComponent {
  @Output() cancel = new EventEmitter();
  currentView: SignView = 'start';
}
