import {
  AccessToken,
  Credentials,
  UserProfile,
  SignUpCredentials,
} from '../../types';
import { ChangePassword } from '../../types/change-password.model';
import Coords from '../../types/coords.type';
import { ApiBase } from './api-base';

export class UsersApi extends ApiBase {
  model = 'users';

  login(credentials: Credentials) {
    return this.http.post<AccessToken>(this.modelUri('login'), credentials);
  }

  register(credentials: SignUpCredentials) {
    return this.http.post<AccessToken>(this.modelBase, credentials);
  }

  createProfile(profile: Partial<UserProfile>) {
    return this.http.post<UserProfile>(this.modelUri('profile'), profile);
  }

  forgot(email: string) {
    return this.http.post<{ message: string }>(
      this.modelUri('reset-password'),
      {
        email,
      }
    );
  }

  logout() {
    return this.http.post<void>(this.modelUri('logout'), {});
  }

  authenticated(authorization?: string) {
    const headers = authorization
      ? { Authorization: authorization }
      : undefined;
    return this.http.get<UserProfile>(this.modelUri('authenticated'), {
      headers,
    });
  }

  updateMy(profile: Partial<UserProfile>) {
    return this.http.patch<UserProfile>(
      this.modelUri('authenticated'),
      profile
    );
  }

  updateLocation(coords: Coords) {
    return this.http.post<void>(this.modelUri('location'), coords);
  }

  changePassword(passwords: ChangePassword) {
    return this.http.put<void>(this.modelUri('password'), passwords);
  }

  chargeCard(userId: string, body: { itemId: string; cardId: string }) {
    return this.http.post<{ paid: boolean; resultMessage: string }>(
      this.modelUri(userId, 'charge-card'),
      body
    );
  }
}
