export enum Lang {
  EN = 'en',
  FR = 'fr',
}

export const isValidLanguage = (lang: string | undefined): Lang | undefined => {
  if (!lang) return;
  if (!Object.values(Lang).includes(lang as Lang)) return;
  return lang as Lang;
};
