import { Component } from '@angular/core';

@Component({
  selector: 'shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss'],
})
export class ShippingComponent {
  section: 'list' | 'new' | 'edit' = 'list';
  shippingItemIndex: number | undefined;

  onAddressAction(event: {section: string, index?: number}) {
    if (event.section === 'edit') {
      this.shippingItemIndex = event.index;
      this.section = 'edit';
    } else {
      this.shippingItemIndex = undefined;
      this.section = 'new';
    }
  }

}
