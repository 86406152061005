import { Component, Input } from '@angular/core';
import { ContentSection } from '../../../types';

@Component({
  selector: 'dash-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() section?: ContentSection;
}
