<div class="form-page">
  <div class="form-header">
    <a routerLink="account" routerLinkActive="active" class="title">{{
      'nav.account' | translate
    }}</a>
    <a href routerLink="shipping" routerLinkActive="active" class="title">{{
      'nav.shipping' | translate
    }}</a>
    <a href routerLink="cards" routerLinkActive="active" class="title">{{
      'nav.payments' | translate
    }}</a>
    <a
      href
      routerLink="favorite-teams"
      routerLinkActive="active"
      class="title"
      >{{ 'nav.faves' | translate }}</a
    >
  </div>
  <div class="form-body">
    <router-outlet></router-outlet>
  </div>
</div>
