import * as fromAuction from '@fan-store/auction/auction.reducer';
import * as fromCCard from '@fan-store/ccard/ccard.reducer';
import * as fromUser from '@fan-store/user/user.reducer';
import * as fromItem from '@fan-store/item/item.reducer';
import * as fromContent from '@fan-store/content/content.reducer';
import * as fromTeam from '@fan-store/team/team.reducer';
import * as fromLocker from '@fan-store/locker/locker.reducer';
import * as fromAuctionItems from '@fan-store/auction-items/auction-items.reducer';
import * as fromRelatedItems from '@fan-store/related-items/related-items.reducer';
import * as fromBid from '@fan-store/bid/bid.reducer';

export const reducers = {
  [fromAuction.auctionsFeatureKey]: fromAuction.reducer,
  [fromCCard.cCardsFeatureKey]: fromCCard.reducer,
  [fromUser.userFeatureKey]: fromUser.reducer,
  [fromItem.itemsFeatureKey]: fromItem.reducer,
  [fromContent.contentFeatureKey]: fromContent.reducer,
  [fromTeam.teamsFeatureKey]: fromTeam.reducer,
  [fromLocker.lockersFeatureKey]: fromLocker.reducer,
  [fromAuctionItems.auctionItemsFeatureKey]: fromAuctionItems.reducer,
  [fromRelatedItems.relatedItemsFeatureKey]: fromRelatedItems.reducer,
  [fromBid.bidFeatureKey]: fromBid.reducer,
};

export interface GlobalState {
  [fromAuction.auctionsFeatureKey]: fromAuction.State;
  [fromCCard.cCardsFeatureKey]: fromCCard.State;
  [fromUser.userFeatureKey]: fromUser.State;
  [fromItem.itemsFeatureKey]: fromItem.State;
  [fromContent.contentFeatureKey]: fromContent.State;
  [fromTeam.teamsFeatureKey]: fromTeam.State;
  [fromLocker.lockersFeatureKey]: fromLocker.State;
  [fromAuctionItems.auctionItemsFeatureKey]: fromAuctionItems.State;
  [fromRelatedItems.relatedItemsFeatureKey]: fromRelatedItems.State;
  [fromBid.bidFeatureKey]: fromBid.State;
}
