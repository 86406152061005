import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'team-home',
  templateUrl: './team-home.component.html',
  styleUrls: ['./team-home.component.scss'],
})
export class TeamHomeComponent {
  team?: string;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => (this.team = params.team));
  }
}
