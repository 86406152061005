import { environment } from '../../environments/environment';

const appId = environment.appId;

export const APP_CONFIG =
  !appId || appId === 'dash'
    ? {
        addCardMethodUSD: 'API' as const,
        addCardMethodCAD: 'DELUXE' as const,
        enableAddGeneralCard: true,
        canEditCard: true,
      }
    : {
        addCardMethodUSD: 'DELUXE' as const,
        addCardMethodCAD: 'DELUXE' as const,
        enableAddGeneralCard: false,
        canEditCard: false,
      };
