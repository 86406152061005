<ng-container *ngrxLet="items$ as items">
  <span
    *ngIf="showTitle && (items?.itemIds?.length || 0) > 1"
    class="mat-h5 dark"
    >{{ 'event.moreItems' | translate }}</span
  >
  <ng-container *ngFor="let itemId of items?.itemIds">
    <card-item
      *ngIf="!skip || skip !== itemId"
      [id]="itemId"
      (clicked)="clickItem(itemId)"
      [auction]="auction"
    ></card-item>
  </ng-container>
</ng-container>
