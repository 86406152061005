import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PathSegment } from '../../../../helpers/build-item-path';
import { GlobalState } from '../../../../store';
import { selectEventByItemId } from '../../../../store/selectors';
import { Auction } from '../../../../types';

@Component({
  selector: 'event-segment',
  templateUrl: './event-segment.component.html',
  styleUrls: ['./event-segment.component.scss'],
})
export class EventSegmentComponent {
  auction$?: Observable<Auction | undefined>;
  link?: any[];

  _segment?: PathSegment;
  @Input()
  set segment(segment: PathSegment | undefined) {
    this._segment = segment;
    if (segment)
      this.auction$ = this.store.select(selectEventByItemId(segment.value));
  }

  get segment() {
    return this._segment;
  }

  mergeRouter(auction?: Auction) {
    if (!auction?.id) return this._segment?.link ?? [];
    if (this.link) return this.link;
    this.link = [...(this._segment?.link ?? []), 'event', auction?.id];
    return this.link;
  }

  constructor(private store: Store<GlobalState>) {}
}
