<ng-container *ngrxLet="items$ as items">
  <ng-container *ngIf="(items?.itemIds?.length || 0) > 0">
    <mat-divider></mat-divider>
    <span class="mat-h5 dark">{{ 'event.relatedItems' | translate }}</span>
  </ng-container>
  <card-item
    *ngFor="let itemId of items?.itemIds"
    [id]="itemId"
    (clicked)="clickItem(itemId)"
  ></card-item>
</ng-container>
