import { Component, Inject, OnInit } from '@angular/core';
import { CreditCardValidators } from 'angular-cc-library';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../../store';
import { editCCardStart } from '../../../store/ccard/ccard.actions';
import {
  selectById,
  selectCCardSaving,
} from '../../../store/ccard/ccard.selectors';
import { CCard, Currency } from '../../../types';

export interface PaymentEditData {
  cardId: string;
  currency: Currency;
}

@Component({
  selector: 'payment-edit',
  templateUrl: './payment-edit.component.html',
  styleUrls: ['./payment-edit.component.scss'],
})
export class PaymentEditComponent implements OnInit {
  saving$ = this.store.select(selectCCardSaving);
  card$?: Observable<CCard | undefined>;

  public demoForm: FormGroup = this.fb.group({
    expDate: ['', [Validators.required, CreditCardValidators.validateExpDate]],
    cvc: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
    ],
    zip: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(10)],
    ],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentEditData,
    private store: Store<GlobalState>,
    public dialogRef: MatDialogRef<PaymentEditComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.card$ = this.store.select(selectById(this.data.cardId));
  }

  submit(demoForm: FormGroup, currency?: Currency) {
    const { expDate, zip, cvc } = demoForm.value;
    const expMY = expDate.split(' / ');
    const expY = Number(expMY[1]);
    this.store.dispatch(
      editCCardStart({
        cardId: this.data.cardId,
        ccard: {
          exp_month: expMY[0],
          exp_year: String(expY < 100 ? 2000 + expY : expY),
          currency: currency ?? 'USD',
          zipCode: zip,
          cvv: cvc,
        },
        onCardEdited: () => this.dialogRef.close(),
      })
    );
  }
}
