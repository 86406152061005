export enum ItemState {
  Closed = 'closed',
  Closing = 'closing',
  Overtime = 'overtime',
  Deleted = 'deleted',
  Open = 'open',
  Paid = 'paid',
  Pending = 'pending',
  Pulled = 'pulled',
  Sold = 'sold',
}
