<div class="form-page">
  <div class="form-header">
    <a class="title active">{{ 'auth.forgotTitle' | translate }}</a>
    <div class="subtitle">{{ 'auth.forgotHint' | translate }}</div>
  </div>
  <form #loginData="ngForm" class="form-body" (ngSubmit)="submit()">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.email' | translate }}</mat-label>
      <input
        matInput
        type="email"
        [(ngModel)]="email"
        name="user"
        required
        [readonly]="processing"
      />
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="processing || !loginData.valid"
    >
      {{ 'general.send' | translate }}
    </button>

    <button mat-raised-button (click)="cancelClick(); (false)" class="mb">
      {{ 'general.cancel' | translate | uppercase }}
    </button>
  </form>
</div>
