import { Component, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { SnackbarClass } from '../../../types';

@Component({
  selector: 'forgot-form',
  templateUrl: './forgot-form.component.html',
  styleUrls: ['./forgot-form.component.scss'],
})
export class ForgotFormComponent {
  @Output() goToLogin = new EventEmitter();

  email = '';
  processing = false;
  constructor(
    private api: ApiService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  submit() {
    this.processing = true;
    this.api.user.forgot(this.email).subscribe(
      (r) => {
        this.snackBar.open(r.message, undefined, {
          panelClass: SnackbarClass.Success,
        });
        this.cancelClick();
        this.processing = false;
      },
      (error) => {
        const message = error?.error?.error?.message;
        this.snackBar.open(message);
        this.processing = false;
      }
    );
  }

  cancelClick() {
    this.goToLogin.observers.length
      ? this.goToLogin.emit()
      : this.router.navigate(['/login']);
  }
}
