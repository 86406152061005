import { environment } from '@fan-env/environment';
import { Team } from '../../types';

export class TeamMapper {
  public static mapItems(teams: any[]): Team[] {
    return teams.map(TeamMapper.mapItem);
  }
  public static mapItem(team: any): Team {
    team.imageUrl = team.logo
      ? `${environment.assetsUrl}/${team.foundationId}/${team.logo.url}`
      : undefined;
    return team;
  }
}
