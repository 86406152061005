<bid
  *ngIf="item?.itemType === 'auction' && item?.itemState !== 'overtime'"
  [item]="asAuction(item)"
  [auction]="auction"
></bid>
<ot-bid
  *ngIf="item?.itemType === 'auction' && item?.itemState === 'overtime'"
  [item]="asAuction(item)"
  [auction]="auction"
></ot-bid>
<enter
  *ngIf="item?.itemType === 'giveaway'"
  [item]="asGiveaway(item)"
  [auction]="auction"
></enter>
<buy
  *ngIf="item?.itemType === 'ticket' || item?.itemType === 'buynow'"
  [item]="asBin(item)"
  [auction]="auction"
></buy>
<donation
  *ngIf="item?.itemType === 'donation'"
  [item]="asDonation(item)"
  [auction]="auction"
></donation>
<custom-buynow
  *ngIf="item?.itemType === 'customBuynow'"
  [item]="asCustomBuynow(item)"
  [auction]="auction"
></custom-buynow>
<ng-content></ng-content>
