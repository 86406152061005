import { createSelector } from '@ngrx/store';
import { selectEntities } from './auction/auction.reducer';
import { selectById, selectEntities as selectItems } from './item/item.reducer';
import { GlobalState } from './state';

export const selectProcessing = (state: GlobalState) =>
  state.user.processing ||
  state.cCards.processing ||
  state.content.processing ||
  state.locker.processing;

export const selectEventByItemId = (itemId: string) =>
  createSelector(selectById(itemId), selectEntities, (item, auctions) => {
    if (!item || !auctions) return;
    return auctions[item.auctionId];
  });

export const selectItemsByEventId = (auctionId: string) =>
  createSelector(selectItems, (items) => {
    return Object.values(items).filter(i => i?.auctionId === auctionId);
  });
