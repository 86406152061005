import { createReducer, on } from '@ngrx/store';
import {
  processBid,
  processBidFailed,
  processBidSucceeded,
  updateServerDiffMsSuccess,
} from './bid.actions';

export const bidFeatureKey = 'bid';

export interface State {
  processing?: boolean;
  completed?: boolean;
  serverDiffMs: number;
}

export const initialState: State = {
  processing: false,
  completed: false,
  serverDiffMs: 0,
};

export const reducer = createReducer(
  initialState,

  on(processBid, (state) => ({
    ...state,
    processing: true,
    completed: false
  })),

  on(processBidSucceeded, (state) => ({
    ...state,
    processing: false,
    completed: true
  })),

  on(processBidFailed, (state) => ({
    ...state,
    processing: false,
    completed: false
  })),

  on(updateServerDiffMsSuccess, (state, { diff }) => ({
    ...state,
    serverDiffMs: diff,
  }))
);
