<payments-list
  [currency]="selectCurrency"
  [foundationId]="foundationId"
  [selectable]="true"
  [skipLoad]="true"
  (cardSelected)="selectCard($event)"
  *ngIf="selectCurrency"
></payments-list>
<mat-dialog-actions align="end" class="actions">
  <button mat-stroked-button color="accent" mat-dialog-close>
    {{ 'general.cancel' | translate | uppercase }}
  </button>
</mat-dialog-actions>
