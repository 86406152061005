import { environment } from '../../environments/environment';
import { Auction, Item } from '../types';

export const getItemImage = (item: Item | undefined) => {
  const asset = item?.assets?.[0];
  if (!asset || !item) return;
  return `${environment.assetsUrl}/${item.auctionId}/${asset.url}`;
};

export const getItemImages = (item: Item | undefined) => {
  return item?.assets?.map(
    (asset) => `${environment.assetsUrl}/${item.auctionId}/${asset.url}`
  );
};

export const getEventImage = (event: Auction | undefined) => {
  const asset = event?.assets?.[0];
  if (!asset || !event) return;
  return `${environment.assetsUrl}/${event.id}/${asset.url}`;
};
