<button
  mat-flat-button
  class="search-button"
  [style.backgroundColor]="theme?.backgroundColor"
  [style.color]="theme?.textColor"
>
  <mat-icon class="left-icon">search</mat-icon>
</button>
<mat-form-field
  appearance="outline" class="search-input" [ngClass]="{'not-white-theme': !closeToWhite(), 'displaying-autocomplete': autocompleteOpen}"
  [style.backgroundColor]="theme?.backgroundColor"
>
  <input type="text"
    matInput
    [placeholder]="'searchbar.search' | translate"
    #searchInput
    [(ngModel)]="query"
    (ngModelChange)="search$.next($event)"
    [matAutocomplete]="autoGroup"
    #trigger="matAutocompleteTrigger"
    >
    <mat-autocomplete #autoCompleteRef (opened)="panelOppened(searchInput, autoCompleteRef, trigger)" (closed)="searchMenuClosed()" #autoGroup="matAutocomplete" (optionSelected)="query = '';" (optionActivated)="query = '';">
      <mat-optgroup class="search-group" *ngFor="let group of stateGroupOptions | async" [label]="group.name | translate">
        <ng-container *ngIf="group.type === 'auctionItem'">
          <mat-option *ngFor="let item of group.items" [disabled]="item.id === 'null'" [value]="item.title" (onSelectionChange)="trackOpenItem(item); openSearchResult({url: item.id, type: group.type});">
            {{item.id === 'null' ? (item.title | translate) : item.title}}<span class="option-description" *ngIf="item.content">{{item.content}}</span>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="group.type === 'auction'">
          <mat-option *ngFor="let item of group.items" [disabled]="item.id === 'null'" [value]="item.title" (onSelectionChange)="trackOpenItem(item); openSearchResult({url: item.id, type: group.type})">
            {{item.id === 'null' ? (item.title | translate) : item.title}}<span class="option-description" *ngIf="item.content">{{item.content}}</span>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="group.type === 'team'">
          <mat-option *ngFor="let item of group.items" [disabled]="item.id === 'null'" [value]="item.team" (onSelectionChange)="trackOpenItem(item); openTeamResult(item)">
            {{item.id === 'null' ? (item.team ?? '' | translate) : item.team}}<span class="option-description team-request-info" *ngIf="!item.teamExists && item.id !== 'null'">{{'searchbar.teamRequest' | translate}}</span>
          </mat-option>
        </ng-container>
    </mat-optgroup>
  </mat-autocomplete>
</mat-form-field>
