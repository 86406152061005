<div class="participants-container">
  <div class="avatar" [style.background-color]="'#00ADE1'">
    {{ loggedParticipant?.name }}
  </div>
  <div class="vs">vs</div>
  <div
    class="avatar"
    *ngFor="let participant of filteredParticipants; let i = index"
    [style.background-color]="avatarColors[i]"
  >
    {{ participant.name }}
  </div>
  <div *ngIf="showCount" class="other-participants">
    <div class="avatar op op2" [style.background-color]="avatarColors[2]"></div>
    <div class="avatar op op1" [style.background-color]="avatarColors[1]"></div>
    <div class="avatar op" [style.background-color]="avatarColors[0]">
      {{ otherParticipantsCount }}
    </div>
    <div class="plus">+</div>
  </div>
</div>
