import { ApiBase } from '@fan-services/models/api-base';

export class TeamRequestApi extends ApiBase {
  model = 'team-request';

  requestTeam(faveId: string, profileId?: string) {
    return this.http.post<{created: true}>(
      this.modelUri(),
      {faveId, profileId}
    );
  }
}
