import {
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  signal,
} from '@angular/core';
import { CCard, Currency } from '../../../types';
import { APP_CONFIG } from '../../../config/app';

@Component({
  selector: 'payment-form-selector',
  templateUrl: './payment-form-selector.component.html',
  styleUrls: ['./payment-form-selector.component.scss'],
})
export class PaymentFormSelectorComponent {
  @Input() currency?: Currency;
  @Input() foundationId?: string;
  @Input() title?: string;
  @Output() cardAdded = new EventEmitter<CCard>();
  selectedCurrency = signal<Currency>('USD');

  addCardMethodCAD = signal(APP_CONFIG.addCardMethodCAD);
  addCardMethodUSD = signal(APP_CONFIG.addCardMethodUSD);

  formType = computed(() => {
    return this.currency === 'CAD' || this.selectedCurrency() === 'CAD'
      ? this.addCardMethodCAD()
      : this.addCardMethodUSD();
  });
}
