import { Observable, of } from 'rxjs';
import { filter, first, switchMap, withLatestFrom } from 'rxjs/operators';

export function waitFor<T, N>(signal: Observable<N>) {
  return (source: Observable<T>) =>
    source.pipe(
      withLatestFrom(signal),
      switchMap(([, result]) => {
        if (result) return of(result);
        return signal.pipe(
          filter((e) => !!e),
          first()
        );
      })
    );
}
