<div id="edit-card-form" class="form-body">
  <div *ngIf="title" class="mat-h5">{{ title }}</div>
  <div *ngIf="status() === 'loading'">Initializing secure payments form...</div>

  <div id="embeddedpayments"></div>

  <div *ngIf="status() === 'authorizing'">Validating payment method...</div>
  <div *ngIf="status() === 'saving'">Saving payment method...</div>

  <div class="two-cols">
    <div></div>
    <div class="two-cols">
      <button mat-stroked-button mat-dialog-close>
        {{ 'general.cancel' | translate | uppercase }}
      </button>
      <button
        mat-raised-button
        color="primary"
        form="edit-card-form"
        *ngIf="status() !== 'error'"
        (click)="onSubmit()"
        [disabled]="status() !== 'ready'"
      >
        {{ 'general.save' | translate | uppercase }}
      </button>
      <button
        mat-raised-button
        color="primary"
        form="edit-card-form"
        *ngIf="status() === 'error'"
        (click)="initForm()"
      >
        {{ 'general.back' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
