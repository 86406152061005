import { map } from 'rxjs/operators';
import { CCard, EditCCard, NewCCard, NewTokenizedCCard } from '../../types';
import { Currency } from '../../types/currency';
import { ApiBase } from './api-base';

export class CardApi extends ApiBase {
  model = 'users';

  getCards(id: string, currency?: Currency, foundationId?: string) {
    return this.http.get<CCard[]>(this.modelUri(id, 'cards'), {
      params: this.setParams({ currency, foundationId }),
    });
  }

  getCurrencies(id: string) {
    return this.http.get<Currency[]>(this.modelUri(id, 'currencies'));
  }

  deleteCard(id: string, cardId: string, currency: Currency) {
    return this.http.delete<void>(this.modelUri(id, 'cards', cardId), {
      params: this.setParams({ currency }),
    });
  }

  editCard(id: string, cardId: string, card: EditCCard) {
    return this.http.patch<CCard>(this.modelUri(id, 'cards', cardId), card);
  }

  addCreditCard(id: string, creditCard: NewCCard) {
    const body = creditCard;
    return this.http.post<CCard>(this.modelUri(id, 'add-card'), body).pipe(
      map((ccard) => ({
        ...ccard,
        currency: creditCard.currency,
        foundationId: creditCard.foundationId,
      }))
    );
  }

  addTokenizedCreditCard(id: string, creditCard: NewTokenizedCCard) {
    const body = creditCard;
    return this.http
      .post<CCard>(this.modelUri(id, 'add-tokenized-card'), body)
      .pipe(
        map((ccard) => ({
          ...ccard,
          currency: creditCard.currency,
          foundationId: creditCard.foundationId,
        }))
      );
  }

  generateAuthorizationJwt(
    id: string,
    foundationId?: string,
    currency?: Currency
  ) {
    const body = {
      foundationId,
      currency,
    };
    return this.http.post<{ jwt: string }>(this.modelUri(id, 'auth-jwt'), body);
  }
}
