import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import * as LockerActions from './locker.actions';
import { ApiService } from '../../services/api.service';
import { AnyObject, Auction, MyBid } from '../../types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypedAction } from '@ngrx/store/src/models';
import * as ItemActions from '../item/item.actions';
import * as AuctionActions from '../auction/auction.actions';

@Injectable()
export class LockerEffects {
  processBid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LockerActions.pullLocker),
      mergeMap(() => this.pullMyLocker())
    );
  });

  private pullMyLocker() {
    return this.api.bid.my().pipe(
      mergeMap((bids) => {
        const myBids: MyBid[] = bids.map((bid) => ({
          auctionItemId: bid.auctionItemId,
          id: bid.id,
          bidType: bid.bidType,
          purchaseCount: 'purchaseCount' in bid ? bid.purchaseCount : undefined,
          fulfillmentOption: 'fulfillmentOption' in bid ? bid.fulfillmentOption : undefined,
          customMessage: 'customMessage' in bid ? bid.customMessage : undefined
        }));
        const items = bids.map((b) => b.auctionItem);
        const auctions = bids
          .map((b) => b.auctionItem.auction as Auction)
          .filter((b) => b);
        const res: TypedAction<any>[] = [
          ...items.map((item) => ItemActions.upsertItem({ item })),
          ...auctions.map((auction) =>
            AuctionActions.upsertAuction({ auction })
          ),
          LockerActions.pullLockerSucceeded({ myBids }),
        ];
        return res;
      }),
      catchError((error) => this.onError(error))
    );
  }

  private onError(err: AnyObject) {
    const message =
      err?.error?.error?.message ??
      'There was an error processing the transaction';
    this.snackBar.open(message);
    const res: TypedAction<any>[] = [LockerActions.pullLockerFailed()];
    return res;
  }

  constructor(
    private actions$: Actions,
    private readonly api: ApiService,
    private snackBar: MatSnackBar
  ) {}
}
