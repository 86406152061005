import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { GlobalState } from '../../store';
import { selectUserState } from '../../store/user/user.selectors';
import { League, Fave } from '../../types/fave';

interface ListParams {
  q: string;
  profileId?: string;
  source:
    | {
        sport: string;
      }
    | {
        isFaved: true;
      };
}

@Component({
  selector: 'faves',
  templateUrl: './faves.component.html',
  styleUrls: ['./faves.component.scss'],
})
export class FavesComponent implements OnDestroy {
  sports: string[] = [];
  search$ = new Subject<string>();
  q = '';
  loading = false;
  params?: ListParams;
  subs?: Subscription;
  faves: League[] = [];
  sport = 'my';

  constructor(
    private store: Store<GlobalState>,
    private api: ApiService,
    private snackBar: MatSnackBar
  ) {
    this.search$
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(500)
      )
      .subscribe((q) => this.updateList({ q }));
    this.subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile && this.params?.profileId !== profile.id)
        this.updateList({
          profileId: profile.id,
          source: {
            isFaved: true,
          },
        });
    });
    this.loadSports();
  }

  private loadSports() {
    this.api.fave.sports().subscribe((s) => (this.sports = s));
  }

  selectSport(sport: string) {
    this.updateList({
      source: sport === 'my' ? { isFaved: true } : { sport },
    });
  }

  private updateList(params: Partial<ListParams>) {
    this.loading = true;
    this.params = {
      ...this.params,
      ...params,
    } as ListParams;
    if (!this.params.profileId) {
      this.faves = [];
      this.loading = false;
      return;
    }
    const sport =
      'sport' in this.params.source ? this.params.source.sport : undefined;
    sport || this.params.q ? this.loadGrouped(sport) : this.loadFaved();
  }
  private loadGrouped(sport?: string) {
    this.api.fave
      .grouped({
        sport,
        profileId: this.params?.profileId,
        q: this.params?.q,
      })
      .subscribe((f) => {
        this.faves = f;
        this.loading = false;
      });
  }
  private loadFaved() {
    this.api.fave
      .list({
        profileId: this.params?.profileId,
        q: this.params?.q,
        isFaved: true,
      })
      .subscribe((faves) => {
        this.faves = [
          {
            league: '',
            faves,
          },
        ];
        this.loading = false;
      });
  }

  favedChanged(fave: Fave, isFaved: boolean) {
    const initialValue = fave.isFaved;
    const profileId = this.params?.profileId;
    if (!profileId) return;
    fave.isFaved = isFaved;
    const obs: Observable<unknown> = isFaved
      ? this.api.fave.addFave(profileId, fave.id)
      : this.api.fave.deleteFave(profileId, fave.id);
    obs.subscribe(
      () => {
        fave.isFaved = isFaved;
      },
      (err: any) => {
        console.log({ err });
        fave.isFaved = initialValue;
        this.snackBar.open(
          err?.error?.error?.message || 'There was an error saving your faves'
        );
      }
    );
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }
}
