<ng-container *ngrxLet="auction$ as auction">
  <card-layout
    [title]="auction?.title"
    [description]="
      'event.itemsCount' | translate: { count: auction?.itemsCount || 0 }
    "
    [cta]="'event.goTo' | translate"
    [image]="getEventImage(auction)"
    [path]="['event', auction?.id || '']"
    (clicked)="onClick(auction)"
    [chip]="(auction?.auctionState === 'closed' ? 'event.closedChip' : '') | translate"
    [warningChip]="true"
    [teamName]="auction?.foundation?.name"
  >
    <ng-template [ngIf]="auction?.auctionState === 'open'">
      <span *ngIf="auction?.auctionEnd; else elseBlock">
        {{ 'event.timeLeft' | translate }}
        <countdown [time]="auction?.auctionEnd!"></countdown>
      </span>
      <ng-template #elseBlock>{{ 'event.inProgress' | translate }}</ng-template>
    </ng-template>
    <ng-template [ngIf]="auction?.auctionState === 'pending'">
      <span *ngIf="auction?.auctionStart; else elseBlock">
        {{ 'event.startsIn' | translate }}
        <countdown [time]="auction?.auctionStart!"></countdown>
      </span>
      <ng-template #elseBlock>{{ 'event.pending' | translate }}</ng-template>
    </ng-template>
    <ng-template [ngIf]="auction?.auctionState === 'closed'">
      <span *ngIf="auction?.auctionEnd">
        {{ 'event.closed' | translate }}
      </span>
    </ng-template>
  </card-layout>
</ng-container>
