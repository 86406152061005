import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {
  CCard,
  EditCCard,
  NewCCard,
  NewTokenizedCCard,
} from '@fan-types/ccard.model';
import { Currency } from '../../types/currency';

export const loadCCards = createAction(
  '[CCard/API] Load CCards',
  props<{ cCards: CCard[] }>()
);

export const addCCard = createAction(
  '[CCard/API] Add CCard',
  props<{ cCard: CCard }>()
);

export const upsertCCard = createAction(
  '[CCard/API] Upsert CCard',
  props<{ cCard: CCard }>()
);

export const addCCards = createAction(
  '[CCard/API] Add CCards',
  props<{ cCards: CCard[] }>()
);

export const upsertCCards = createAction(
  '[CCard/API] Upsert CCards',
  props<{ cCards: CCard[] }>()
);

export const updateCCard = createAction(
  '[CCard/API] Update CCard',
  props<{ cCard: Update<CCard> }>()
);

export const updateCCards = createAction(
  '[CCard/API] Update CCards',
  props<{ cCards: Update<CCard>[] }>()
);

export const deleteCCard = createAction(
  '[CCard/API] Delete CCard',
  props<{ id: string }>()
);

export const requestDeleteCCard = createAction(
  '[CCard/API] Request Delete CCard',
  props<{ cardId: string; currency: Currency }>()
);

export const deleteCCards = createAction(
  '[CCard/API] Delete CCards',
  props<{ ids: string[] }>()
);

export const clearCCards = createAction('[CCard/API] Clear CCards');

export const pullCCards = createAction(
  '[CCard/API] Pull CCards',
  props<{ currency?: Currency; foundationId?: string }>()
);

export const addCCardStart = createAction(
  '[CCard/API] Add CCard Start',
  props<{ ccard: NewCCard; onCardAdded?: (ccard: CCard) => void }>()
);

export const addTokenizedCCardStart = createAction(
  '[CCard/API] Add Tokenized CCard Start',
  props<{ ccard: NewTokenizedCCard; onCardAdded?: (ccard: CCard) => void }>()
);

export const addCCardFailed = createAction('[CCard/API] Add CCard Failed');

export const editCCardStart = createAction(
  '[CCard/API] edit CCard Start',
  props<{ cardId: string; ccard: EditCCard; onCardEdited?: () => void }>()
);

export const editCCardFailed = createAction('[CCard/API] Edit CCard Failed');
