import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { RelatedItems } from '@fan-types/auction.model';
import * as RelatedItemsActions from '@fan-store/related-items/related-items.actions';
import { createEntitySelectors } from '@fan-store/helpers/merge-selectors';
import { GlobalState } from '@fan-store/state';

export const relatedItemsFeatureKey = 'relatedItems';

export type State = EntityState<RelatedItems>;

export const adapter: EntityAdapter<RelatedItems> =
  createEntityAdapter<RelatedItems>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(RelatedItemsActions.addRelatedItems, (state, action) =>
    adapter.upsertOne(action.items, state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  createEntitySelectors(adapter, (state: GlobalState) => state.relatedItems);
