import { Component, Input } from '@angular/core';
import { getItemImages } from '../../../helpers/get-image';
import { isSoldOut } from '../../../helpers/is-sold-out';
import { Item } from '../../../types';

@Component({
  selector: 'item-gallery',
  templateUrl: './item-gallery.component.html',
  styleUrls: ['./item-gallery.component.scss'],
})
export class ItemGalleryComponent {
  images: string[] = ['/assets/images/placeholder.jpeg'];
  overlayImage?: string;
  lastItemId?: string;

  @Input()
  set item(item: Item | undefined) {
    // Avoid unnecessary updates
    if (
      this.lastItemId === item?.id &&
      this.images?.length === item?.assets?.length
    )
      return;
    // this.images = [];
    this.lastItemId = item?.id;
    setTimeout(() => {
      const images = getItemImages(item);
      this.images = images?.length
        ? images
        : ['/assets/images/placeholder.jpeg'];
    }, 10);
    this.overlayImage = isSoldOut(item) ? '/assets/images/sold_out.png' : undefined;
  }
}
