import { createReducer, on } from '@ngrx/store';
import { UserProfile } from '../../types';
import {
  loadProfile,
  login,
  loginFail,
  recoverAccessToken,
  saveAccessToken,
  setProfile,
  sendProfile,
  logout,
  sendProfileFail,
  register,
  pullOTItemsSuccess,
} from './user.actions';

export const userFeatureKey = 'user';

export interface State {
  accessToken?: string;
  profile?: UserProfile;
  processing?: boolean;
  otItems?: string[];
}

export const initialState: State = {
  processing: false,
};

export const reducer = createReducer(
  initialState,

  on(saveAccessToken, (state, { accessToken }) => ({
    ...state,
    accessToken: accessToken,
  })),

  on(login, (state) => ({
    ...state,
    accessToken: undefined,
    profile: undefined,
    processing: true,
  })),

  on(register, (state) => ({
    ...state,
    accessToken: undefined,
    profile: undefined,
    processing: true,
  })),

  on(loginFail, (state) => ({
    ...state,
    accessToken: undefined,
    profile: undefined,
    processing: false,
  })),

  on(logout, (state) => ({
    ...state,
    accessToken: undefined,
    profile: undefined,
    processing: false,
  })),

  on(setProfile, (state, { profile }) => {
    const p = profile
      ? {
          ...profile,
          id: profile.id ?? profile.profileId,
        }
      : profile;
    return {
      ...state,
      profile: p,
      processing: false,
    };
  }),

  on(loadProfile, (state) => ({
    ...state,
    processing: true,
  })),

  on(recoverAccessToken, (state) => ({
    ...state,
    processing: true,
  })),

  on(sendProfile, (state) => ({
    ...state,
    processing: true,
  })),

  on(sendProfileFail, (state) => ({
    ...state,
    processing: false,
  })),

  on(pullOTItemsSuccess, (state, {otItems}) => ({
    ...state,
    otItems,
  }))
);
