<ng-container *ngrxLet="profile$ as profile">
  <div class="mat-subtitle-2">
    {{ title | translate | uppercase }}
    <ng-template [ngIf]="bidsCount">{{
      'event.bidNOf' | translate: { n: bidsCount }
    }}</ng-template>
  </div>
  <div class="mat-subheading-2 dark m0" *ngIf="showAmount">
    {{ amount | currency: 'USD':'symbol':'1.2-2' }}
    <event-currency [auctionId]="item?.auctionId"></event-currency>
  </div>
  <div class="mat-subtitle-2">
    <event-countdown [auctionId]="item?.auctionId"></event-countdown>
  </div>
  <div
    class="mat-h5 dark"
    *ngIf="
      item?.itemState === 'open' && item?.topBidUserProfileId === profile?.id
    "
  >
    {{ 'event.topBidderTitle' | translate }}
  </div>
  <div
    class="mat-h5 dark"
    *ngIf="
      (item?.itemState === 'closed' || item?.itemState === 'paid') &&
      item?.topBidUserProfileId === profile?.id
    "
  >
    {{ 'event.winner' | translate }}
  </div>
</ng-container>
