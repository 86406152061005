export default class Coords {
  constructor(public lat: number, public lng: number) {}
  toString() {
    return `${this.lat}, ${this.lng}`;
  }
  toObj() {
    return {
      lat: this.lat,
      lng: this.lng
    }
  }
}
