import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../../store';
import { selectById } from '../../../store/item/item.reducer';
import { selectProfile } from '../../../store/user/user.selectors';
import {
  AuctionItem,
  BuynowItem,
  CustomBuynowItem,
  DonationItem,
  FulfillmentOption,
  GiveawayItem,
  Item,
  TicketItem,
} from '../../../types';

@Component({
  selector: 'card-locker',
  templateUrl: './card-locker.component.html',
  styleUrls: ['./card-locker.component.scss'],
})
export class CardLockerComponent {
  item$?: Observable<Item | undefined>;
  profile$ = this.store.select(selectProfile);
  @Input() purchaseCount?: null | number;
  @Input() userBidId?: string;
  @Input() fulfillmentOption?: FulfillmentOption;
  @Input() customMessage?: string;

  @Input()
  set id(id: string | undefined) {
    if (!id) return;
    this.item$ = this.store.select(selectById(id));
  }

  asGiveaway(item: Item | undefined) {
    return item as GiveawayItem;
  }
  asTicket(item: Item | undefined) {
    return item as TicketItem;
  }
  asBin(item: Item | undefined) {
    return item as BuynowItem;
  }
  asAuction(item: Item | undefined) {
    return item as AuctionItem;
  }
  asDonation(item: Item | undefined) {
    return item as DonationItem;
  }
  asCustomBin(item: Item | undefined) {
    return item as CustomBuynowItem;
  }

  constructor(private store: Store<GlobalState>) {}

  onClick(auction: Item | undefined) {
    // TODO: click event
    console.log(auction);
  }
}
