<div class="cta-colored-box cta-centered-box">
  <span class="mat-h5">
    {{ 'event.inOt' | translate }}
  </span>
  <div>
    <countdown [time]="item?.overtimeEnd || ''"></countdown>
  </div>
  <ot-participants
    [participants]="item?.overtimeUserProfiles"
    [userProfileId]="profile?.id"
    *ngIf="inOvertime"
  ></ot-participants>
</div>
<!-- OT Data -->
<div class="cta-box" *ngIf="inOvertime">
  <p class="mat-h5 dark" *ngIf="item?.myCurrentBid">
    {{ 'event.yourCurrentBid' | translate }}:
    {{ item?.myCurrentBid | currency : currency : 'symbol' : '1.2-2' }}
    <span class="currency">{{ currency }}</span>
  </p>

  <p>
    {{ 'event.otStartingBid' | translate }}:
    {{ item?.topBidAmount || 0 | currency : currency : 'symbol' : '1.2-2' }}
    <span class="currency">{{ currency }}</span>
  </p>
  <div>{{ 'event.bidsCount' | translate }}:&nbsp;{{ item?.otBidCount }}</div>
  <form>
    <button
      *ngIf="!editing"
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="processing$ | async"
      (click)="openBid()"
    >
      {{
        (item?.hasBidOT ? 'action.editOt' : 'action.placeOtBid')
          | translate
          | uppercase
      }}
    </button>
  </form>
</div>
<!-- Bid -->
<div class="cta-box" *ngIf="inOvertime && editing">
  <form (ngSubmit)="submit()">
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>attach_money</mat-icon>
      <input
        cdkFocusInitial
        matInput
        type="number"
        [(ngModel)]="bid"
        name="bidAmount"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="processing$ | async"
    >
      {{ 'action.bid' | translate | uppercase }}
    </button>
  </form>
  <form>
    <button
      mat-raised-button
      color="primary"
      (click)="editing = false"
      *ngIf="inOvertime && editing"
    >
      {{ 'general.back' | translate | uppercase }}
    </button>
  </form>
  <payment
    *ngIf="currency && profile"
    [segment]="{ currency: currency, foundationId: foundation?.id }"
    (cardSelected)="cardSelected($event)"
    [card]="card"
  ></payment>
  <div class="mat-subtitle-4" *ngIf="!card">
    {{ 'event.nodHint' | translate }}
  </div>
  <!-- Open Item Comments -->
  <div class="mat-subtitle-4">
    {{ 'event.noteFee1' | translate }}
  </div>
  <div class="mat-subtitle-4">
    {{
      'event.noteFee2'
        | translate : { procPerc: foundation?.procPerc, name: foundation?.name }
    }}
  </div>
  <div class="mat-subtitle-4">
    {{
      'event.noteLocation'
        | translate : { location: currency === 'CAD' ? 'Canada' : 'U.S.A.' }
    }}
  </div>
  <div class="mat-subtitle-4">
    {{ 'event.noteOt' | translate }}
  </div>
  <div class="mat-subtitle-4">
    {{ 'event.noteTos' | translate }}
  </div>
</div>
<mat-progress-bar mode="query" *ngIf="processing$ | async"></mat-progress-bar>
