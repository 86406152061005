<dash-header
  [style.backgroundColor]="(themeService.theme | async)?.backgroundColor"
  [style.borderBottomWidth]="(themeService.theme | async)?.headerBorderBottom"
  [theme]="themeService.theme | async"
></dash-header>
<integrations></integrations>
<main>
  <section
    [style.padding]="sectionClass === 'full-width-section' ? '0' : undefined"
  >
    <div [ngClass]="sectionClass">
      <overtime></overtime>
      <router-outlet></router-outlet>
    </div>
  </section>
  <dash-footer></dash-footer>
</main>
