import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '../shared.module';
import { PaymentEditComponent } from './payment-edit/payment-edit.component';
import { PaymentDialogAddComponent } from './payment-dialog-add/payment-dialog-add.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentsListComponent } from './payments-list/payments-list.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MatSelectModule } from '@angular/material/select';
import { SelectPaymentComponent } from './select-payment/select-payment.component';
import { PaymentFormApiComponent } from './payment-form-api/payment-form-api.component';
import { PaymentFormDeluxeComponent } from './payment-form-deluxe/payment-form-deluxe.component';
import { PaymentFormSelectorComponent } from './payment-form-selector/payment-form-selector.component';

@NgModule({
  declarations: [
    PaymentsListComponent,
    PaymentEditComponent,
    PaymentDialogAddComponent,
    SelectPaymentComponent,
    PaymentFormApiComponent,
    PaymentFormDeluxeComponent,
    PaymentFormSelectorComponent,
  ],
  exports: [
    PaymentsListComponent,
    SelectPaymentComponent,
    PaymentFormSelectorComponent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    SharedModule,
    MatDialogModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    MatSelectModule,
  ],
})
export class PaymentsModule {}
