import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ProfileRouting } from './profile.routing';
import { AccountComponent } from './account/account.component';
import { ShippingComponent } from './shipping/shipping.component';
import { PaymentsComponent } from './payments/payments.component';
import { FavesComponent } from './faves/faves.component';
import { SharedModule } from '../shared/shared.module';
import { PaymentsModule } from '../shared/payments/payments.module';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { FaveComponent } from './faves/fave/fave.component';
import { OnboardingModule } from '../shared/onboarding/onboarding.module';
import { ShippingAddressItemComponent } from './shipping/shipping-address-item/shipping-address-item.component';
import { ShippingAddressListComponent } from './shipping/shipping-address-list/shipping-address-list.component';

@NgModule({
  declarations: [
    ProfileComponent,
    AccountComponent,
    ShippingComponent,
    PaymentsComponent,
    FavesComponent,
    FaveComponent,
    ShippingAddressItemComponent,
    ShippingAddressListComponent,
  ],
  imports: [
    CommonModule,
    ProfileRouting,
    SharedModule,
    PaymentsModule,
    MatSelectModule,
    MatDividerModule,
    OnboardingModule,
  ],
})
export class ProfileModule {}
