<img
  [src]="fallbackImage ?? safeImage ?? defaultImage"
  (error)="onError()"
  loading="lazy"
/>
<img
  class="overlay-img"
  [src]="overlayImage"
  *ngIf="overlayImage"
  loading="lazy"
/>
