import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as BidActions from './bid.actions';
import { ApiService } from '../../services/api.service';
import { AnyObject, BidAttempt, ItemType } from '../../types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypedAction } from '@ngrx/store/src/models';
import * as ItemActions from '../item/item.actions';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import { MatDialog } from '@angular/material/dialog';
import { TrackAnalyticsService } from '../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../analytics/analytics-events.enum';
import { LocationService } from '../../services/location.service';

@Injectable()
export class BidEffects {
  processBid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BidActions.processBid),
      mergeMap(({ bid }) => {
        if (bid.type === 'ot-bid') return this.submitOtBid(bid);
        if (bid.type === 'bid') return this.submitBid(bid);
        return this.submitPurchase(bid);
      })
    );
  });

  updateServerDiffMs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BidActions.updateServerDiffMs),
      mergeMap(() => {
        return this.api.auction.serverTime().pipe(
          map(({ now }) => {
            const diff = new Date(now).valueOf() - new Date().valueOf();
            return BidActions.updateServerDiffMsSuccess({ diff });
          })
        );
      })
    );
  });

  private submitBid(bid: BidAttempt) {
    return this.locationService.getActivationLocation(bid.item).pipe(
      mergeMap((coords) =>
        this.api.auctionItem.bid(bid.item.id, {
          amount: bid.amount,
          byUserProfileId: bid.profile?.id ?? '',
          device: 'web',
          forAuctionItemId: bid.item.id,
          payWithCardId: bid.card?.id ?? '',
          type: 'bid',
          payWithCustomerId: bid.profile?.id ?? '',
          location: coords?.toObj(),
          buynowAmount: bid.buynowAmount,
          fulfillmentOption: bid.fulfillmentOption,
          shipAddress: bid.shipAddress
        })
      ),
      mergeMap((item) => {
        if (bid.buynowAmount && item.auctionPurchased) {
          const data: ConfirmDialogData = {
            confirmKey: 'modal.purchaseTitle',
            bodyKey: 'modal.purchaseHint',
            okKey: 'modal.ok',
            hideCancel: true,
          };
          this.dialog.open(ConfirmDialogComponent, {
            data,
          });
          this.trackAnalytics.buy(
            AnalyticsEvents.AuctionItemPaid,
            bid.item.id,
            bid.amount,
            {
              ...(bid.fulfillmentOption
                ? {fulfillmentType: bid.fulfillmentOption.type, fulfillmentOptionName: bid.fulfillmentOption.name}
                : {}
              ),
            }
          );
        } else {
          this.trackAnalytics.bid(
            AnalyticsEvents.BidSuccess,
            bid.item.id,
            bid.amount
          );
        }
        const res: TypedAction<any>[] = [
          ItemActions.upsertItem({ item }),
          BidActions.processBidSucceeded({ bid }),
        ];
        return res;
      }),
      catchError((error) => this.bidError(bid, error))
    );
  }

  private submitOtBid(bid: BidAttempt) {
    return this.locationService.getActivationLocation(bid.item).pipe(
      mergeMap((coords) =>
        this.api.auctionItem.otBid(bid.item.id, {
          amount: bid.amount,
          byUserProfileId: bid.profile?.id ?? '',
          device: 'web',
          forAuctionItemId: bid.item.id,
          payWithCardId: bid.card?.id ?? '',
          type: 'bid',
          payWithCustomerId: bid.profile?.id ?? '',
          location: coords?.toObj(),
        })
      ),
      mergeMap((item) => {
        this.trackAnalytics.bid(
          AnalyticsEvents.OvertimeBidSuccess,
          bid.item.id,
          bid.amount
        );
        const res: TypedAction<any>[] = [
          ItemActions.upsertItem({ item }),
          BidActions.processBidSucceeded({ bid }),
        ];
        return res;
      }),
      catchError((error) => this.bidError(bid, error, false))
    );
  }

  private submitPurchase(bid: BidAttempt) {
    return this.locationService.getActivationLocation(bid.item).pipe(
      mergeMap((coords) =>
        this.api.auctionItem.purchaseItem(bid.item.id, {
          purchaseCount: bid.amount,
          payWithCardId: bid.card?.id ?? '',
          payWithCustomerId: bid.profile?.id ?? '',
          location: coords?.toObj(),
          fulfillmentOption: bid.fulfillmentOption,
          shipAddress: bid.shipAddress,
          customMessage: bid.customMessage
        })
      ),
      mergeMap(() => {
        const data: ConfirmDialogData = {
          confirmKey:
            bid.item?.itemType === ItemType.Donation
              ? 'modal.donationPurchaseTitle'
              : 'modal.purchaseTitle',
          bodyKey:
            bid.item?.itemType === ItemType.Donation
              ? 'modal.donationPurchaseHint'
              : 'modal.purchaseHint',
          okKey: 'modal.ok',
          hideCancel: true,
        };
        this.dialog.open(ConfirmDialogComponent, {
          data,
        });
        if (bid.item?.itemType === ItemType.Donation) {
          this.trackAnalytics.donate(
            AnalyticsEvents.Donate,
            bid.item.id,
            bid.amount
          );
        } else {
          this.trackAnalytics.buy(
            AnalyticsEvents.AuctionItemPaid,
            bid.item.id,
            bid.amount,
            {
              ...(bid.fulfillmentOption
                ? {fulfillmentType: bid.fulfillmentOption.type, fulfillmentOptionName: bid.fulfillmentOption.name}
                : {}
              ),
            }
          );
        }
        const res: TypedAction<any>[] = [
          ItemActions.pullItem({ itemId: bid.item.id }),
          BidActions.processBidSucceeded({ bid }),
        ];
        return res;
      }),
      catchError((error) => this.bidError(bid, error))
    );
  }

  private bidError(bid: BidAttempt, err: AnyObject, update = true) {
    const message =
      err?.error?.error?.message ??
      'There was an error processing the transaction';
    const topBidAmount = err?.error?.error?.topBidAmount ?? 0;

    this.snackBar.open(message);
    const res: TypedAction<any>[] = [BidActions.processBidFailed({ bid })];
    if (topBidAmount && update)
      res.push(
        ItemActions.updateItem({
          item: {
            id: bid.item.id,
            changes: { topBidAmount },
          },
        })
      );
    return res;
  }

  constructor(
    private actions$: Actions,
    private readonly api: ApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private trackAnalytics: TrackAnalyticsService,
    private locationService: LocationService
  ) {}
}
