import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CCard, Currency } from '../../types';
import { APP_CONFIG } from '../../config/app';

@Component({
  selector: 'payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent {
  selectCurrency: Currency | undefined;
  enableAddGeneralCard = APP_CONFIG.enableAddGeneralCard;

  constructor(private route: ActivatedRoute) {
    this.selectCurrency = this.route.snapshot.queryParams.selectCurrency;
  }

  selectCard(ccard: CCard) {}
}
