import { createAction, props } from '@ngrx/store';
import { AuctionItems } from '../../types';

export const pullRelatedItems = createAction(
  '[RelatedItems/API] Pull related items',
  props<{ itemId: string }>()
);

export const addRelatedItems = createAction(
  '[RelatedItems/API] Add related items',
  props<{ items: AuctionItems }>()
);
