import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ItemActions from '@fan-store/item/item.actions';

import { mergeMap } from 'rxjs/operators';

import * as AuctionItemsActions from './auction-items.actions';
import { ApiService } from '../../services/api.service';

@Injectable()
export class AuctionItemsEffects {
  loadAuctionItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionItemsActions.pullAuctionItems),
      mergeMap(({ auctionId }) => {
        return this.api.auction.items(auctionId).pipe(
          mergeMap((items) => {
            return [
              ItemActions.upsertItems({ items }),
              AuctionItemsActions.addAuctionItems({
                items: {
                  id: auctionId,
                  itemIds: items.map((i) => i.id),
                },
              }),
            ];
          })
          //         catchError(error => of(AuthApiActions.loginFailure({ error })))
        );
      })
    );
  });

  constructor(private actions$: Actions, private readonly api: ApiService) {}
}
