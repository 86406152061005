<span class="grow">{{ fave?.team }}</span>
<button
  mat-icon-button
  [attr.aria-label]="fave?.isFaved ? 'Remove fave' : 'Add fave'"
  (click)="favedChanged.emit(!fave?.isFaved)"
>
  <mat-icon
    *ngIf="fave?.isFaved"
    color="primary"
    >thumb_up</mat-icon
  >
  <mat-icon class="material-icons-outlined" *ngIf="!fave?.isFaved"
    >thumb_up</mat-icon
  >
</button>
