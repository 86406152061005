import { RouterModule, Routes } from '@angular/router';
import { AnalyticsEvents } from '../analytics/analytics-events.enum';
import { AccountComponent } from './account/account.component';
import { FavesComponent } from './faves/faves.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProfileComponent } from './profile.component';
import { ShippingComponent } from './shipping/shipping.component';

export const ProfileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      keepTheme: true,
    },
    children: [
      { path: '', redirectTo: 'account', pathMatch: 'full' },
      {
        path: 'shipping',
        component: ShippingComponent,
        data: {
          analyticsEvent: AnalyticsEvents.ShippingViewed,
          keepTheme: true,
        },
      },
      {
        path: 'account',
        component: AccountComponent,
        data: {
          analyticsEvent: AnalyticsEvents.AccountViewed,
          keepTheme: true,
        },
      },
      {
        path: 'cards',
        component: PaymentsComponent,
        data: {
          analyticsEvent: AnalyticsEvents.PaymentsViewed,
          keepTheme: true,
        },
      },
      {
        path: 'favorite-teams',
        component: FavesComponent,
        data: {
          analyticsEvent: AnalyticsEvents.FavesViewed,
          keepTheme: true,
        },
      },
    ],
  },
];

export const ProfileRouting = RouterModule.forChild(ProfileRoutes);
