import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../store';
import { login } from '../../../store/user/user.actions';
import { selectUserProcessing } from '../../../store/user/user.selectors';
import { Credentials } from '../../../types';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Output() goToRegister = new EventEmitter();
  @Output() goToForgot = new EventEmitter();
  @Input() redirectHome = true;

  credentials: Credentials = {
    email: '',
    password: '',
  };

  processing$ = this.store.select(selectUserProcessing);
  constructor(private store: Store<GlobalState>, private router: Router) {}

  submit() {
    this.store.dispatch(
      login({
        credentials: { ...this.credentials },
        redirectHome: this.redirectHome,
      })
    );
  }

  registerClick() {
    this.goToRegister.observers.length
      ? this.goToRegister.emit()
      : this.router.navigate(['/register']);
  }

  forgotClick() {
    this.goToForgot.observers.length
      ? this.goToForgot.emit()
      : this.router.navigate(['/forgot']);
  }
}
