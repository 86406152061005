<card-layout
  [title]="item?.title"
  [description]="item?.content"
  [cta]="'general.details' | translate"
  [chip]="'event.ticket' | translate"
  [image]="image"
  [path]="['item', item?.id || '']"
  [overlayImage]="overlayImage"
  (clicked)="onClick()"
  [teamName]="item?.foundation?.name"
>
  <card-buy-detail [item]="item"></card-buy-detail>
  <ng-content></ng-content>
</card-layout>
