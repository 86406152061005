import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Auction } from '@fan-types/auction.model';
import * as AuctionActions from '@fan-store/auction/auction.actions';
import { createEntitySelectors } from '@fan-store/helpers/merge-selectors';
import { GlobalState } from '@fan-store/state';

export const auctionsFeatureKey = 'auctions';

export interface State extends EntityState<Auction> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Auction> = createEntityAdapter<Auction>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(AuctionActions.addAuction, (state, action) =>
    adapter.addOne(action.auction, state)
  ),
  on(AuctionActions.upsertAuction, (state, action) =>
    adapter.upsertOne(action.auction, state)
  ),
  on(AuctionActions.addAuctions, (state, action) =>
    adapter.addMany(action.auctions, state)
  ),
  on(AuctionActions.upsertAuctions, (state, action) =>
    adapter.upsertMany(action.auctions, state)
  ),
  on(AuctionActions.updateAuction, (state, action) =>
    adapter.updateOne(action.auction, state)
  ),
  on(AuctionActions.updateAuctions, (state, action) =>
    adapter.updateMany(action.auctions, state)
  ),
  on(AuctionActions.deleteAuction, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(AuctionActions.deleteAuctions, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(AuctionActions.loadAuctions, (state, action) =>
    adapter.setAll(action.auctions, state)
  ),
  on(AuctionActions.clearAuctions, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  createEntitySelectors(adapter, (state: GlobalState) => state.auctions);
