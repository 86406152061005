<ng-container *ngrxLet="auction$ as auction">
  <ng-container *ngIf="auction?.auctionState === 'open'">
    <ng-container *ngIf="auction?.auctionEnd; else noEndTime">
      {{ 'event.timeLeft' | translate }}
      <countdown [time]="auction?.auctionEnd || ''"></countdown>
    </ng-container>
    <ng-template #noEndTime>{{ 'event.inProgress' | translate }}</ng-template>
  </ng-container>
  <ng-container *ngIf="auction?.auctionState === 'pending'">
    <ng-container *ngIf="auction?.auctionStart; else noStartTime">
      {{ 'event.startsIn' | translate }}
      <countdown [time]="auction?.auctionStart || ''"></countdown>
    </ng-container>
    <ng-template #noStartTime>{{ 'event.pending' | translate }}</ng-template>
  </ng-container>
  <ng-container *ngIf="auction?.auctionState === 'closed'">
    {{ 'event.closed' | translate }}
  </ng-container>
</ng-container>
