import { Component, Input } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from '@fan-services/api.service';
import { Auction } from '@fan-types/auction.model';
import { AuctionItem } from '@fan-types/item.model';
import { SnackbarClass } from '@fan-types/snackbar-class';
import { TeamResult } from '@fan-types/team-result';
import { Theme } from '@fan-types/theme';
import { UserProfile } from '@fan-types/user-profile';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TrackAnalyticsService } from '../../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../../analytics/analytics-events.enum';

export interface ItemsGroup {
  name: 'searchbar.items'
  type: 'auctionItem',
  items: AuctionItem[]
}

export interface EventsGroup {
  name: 'searchbar.events'
  type: 'auction',
  items: Auction[]
}

export interface TeamsGroup {
  name: 'searchbar.teams',
  type: 'team',
  items: TeamResult[]
}

@Component({
  selector: 'app-nav-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() theme?: Theme | null;
  @Input() profile: UserProfile | undefined | null;

  stateGroupOptions!: Observable<(ItemsGroup | EventsGroup | TeamsGroup)[]>;
  search$ = new Subject<string>();
  query = '';
  autocompleteOpen = false;
  numberOfItems = 0;
  lastDismissQuery = '';

  constructor(
    private api: ApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private trackAnalytics: TrackAnalyticsService
  ) {
    this.search$
      .pipe(
        debounceTime(500)
      )
      .subscribe((q) => this.searchContent(q));
  }

  searchContent(query: string) {
    if (!this.query) return;
    this.api.content
      .searchContent(query)
      .subscribe((res) => {
        this.numberOfItems = (res.results.items?.length ?? 0) + (res.results.events?.length ?? 0) + (res.results.teams?.length ?? 0);
        this.stateGroupOptions = of([
          {
            name: 'searchbar.items',
            type: 'auctionItem',
            items: res.results.items?.length ? res.results.items : [{title: 'searchbar.noResults', id: 'null'} as AuctionItem]
          },
          {
            name: 'searchbar.events',
            type: 'auction',
            items: res.results.events?.length ? res.results.events : [{title: 'searchbar.noResults', id: 'null'} as Auction]
          },
          {
            name: 'searchbar.teams',
            type: 'team',
            items: res.results.teams?.length ? res.results.teams : [{team: 'searchbar.noResults', id: 'null'} as TeamResult]
          }
        ])
      });
  }

  openSearchResult(item: {type: 'auction' | 'auctionItem' | 'team', url?: string}) {
    if (!item.url) return;
    let route = '';
    if (item.type === 'auction') {
      route = 'event';
    } else if (item.type === 'auctionItem') {
      route = 'item';
    } else if (item.type === 'team') {
      route = 'team';
    }
    this.numberOfItems = 0;
    this.stateGroupOptions = of([]);
    this.router.navigate([`/${route}`, item.url], {queryParams: {fromSearch: true}});
  }

  openTeamResult(item: TeamResult) {
    if (item.id === 'null') return;
    this.stateGroupOptions = of([]);
    if (item.teamExists) {
      this.openSearchResult({type: 'team', url: item.contentName});
    } else {
      this.api.teamRequest.requestTeam(item.id!, this.profile?.id)
        .subscribe(res => {
          if (res.created) {
            this.translate.get('searchbar.requestSent')
              .subscribe(message => {
                this.snackBar.open(message, undefined, {
                  panelClass: SnackbarClass.Success,
                });
              });
          }
        });
    }
  }

  closeToWhite() {
    const color = this.theme?.backgroundColor ?? '#FFFFFF';
    var whiteLimit = 200,
        r,g,b;
    r = parseInt("0x"+color.substring(1,3));
    g = parseInt("0x"+color.substring(3,5));
    b = parseInt("0x"+color.substring(5,7));
    if(r < whiteLimit || b < whiteLimit || g < whiteLimit) {
        return false;
    }
    return true;
  }

  panelOppened(input: HTMLInputElement, autoCompleteRef: MatAutocomplete, trigger: MatAutocompleteTrigger) {
    this.autocompleteOpen = true;
    let inputWidth = input.getBoundingClientRect().width;
    if (inputWidth === 0) {
      setTimeout(()=>{
        let panel = autoCompleteRef.panel?.nativeElement;
        console.log(panel.style);
        if (!panel ) return;
        panel.style.display = 'none';
        setTimeout(() => {
          inputWidth = input.getBoundingClientRect().width;
          panel.style.minWidth = (inputWidth + 32) + "px";
          panel.style.display = 'block';
          trigger.updatePosition();
        }, 500);
      })
    }
  }

  trackOpenItem(item: AuctionItem | Auction | TeamResult) {
    this.trackAnalytics.search(AnalyticsEvents.SearchResultSelected, this.query, item);
  }

  searchMenuClosed() {
    this.autocompleteOpen = false;
    if (this.lastDismissQuery === this.query) return;
    this.lastDismissQuery = this.query;
    this.trackAnalytics.search(AnalyticsEvents.SearchCancelled, this.query, undefined, this.numberOfItems);
  }
}
