import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../store';
import { selectById } from '../../..//store/content/content.reducer';
import { pullContent } from '@fan-store/content/content.actions';
import { Observable } from 'rxjs';
import { Content } from '../../../types';

const PAGE_SIZE = 40;

@Component({
  selector: 'dash-paged-content',
  templateUrl: './paged-content.component.html',
  styleUrls: ['./paged-content.component.scss'],
})
export class PagedContentComponent {
  content$?: Observable<Content | undefined>;
  _contentId: string | undefined;
  @Input()
  set contentId(contentId: string | undefined) {
    if (contentId) {
      this.content$ = this.store.select(selectById(contentId));
      this.store.dispatch(pullContent({ contentId, limit: PAGE_SIZE }));
    }
    this._contentId = contentId;
  }

  get contentId() {
    return this._contentId;
  }

  constructor(private store: Store<GlobalState>) {}

  onScroll(hasMore = true) {
    console.log(hasMore);
    if (hasMore === false) return;
    if (this.contentId) {
      this.store.dispatch(
        pullContent({ contentId: this.contentId, limit: PAGE_SIZE })
      );
    }
  }
}
