import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, throwError } from 'rxjs';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import { Item, UserProfile } from '../types';
import Coords from '../types/coords.type';
import { StorageService } from './storage.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { GlobalState } from '../store';
import { Store } from '@ngrx/store';
import { selectUserState } from '../store/user/user.selectors';

const LOCATION_REQUESTED_AT_KEY = 'locationRequestedAt';
const UPDATE_LOCATION_INTERVAL_MINUTES = 60 * 24 * 2; // 2 days
const REQUEST_LOCATION_AFTER_ITEMS_VIEWED = 1;

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  itemsLeftToRequestLocation = REQUEST_LOCATION_AFTER_ITEMS_VIEWED;
  private profile: UserProfile | undefined;
  // constructor(
  //   private store: Store<GlobalState>,
  //   private translate: TranslateService
  // ) {
  //   mixpanel.init(environment.mixpanelToken);

  // }
  constructor(
    private dialog: MatDialog,
    private storage: StorageService,
    private api: ApiService,
    private store: Store<GlobalState>
  ) {
    this.store
      .select(selectUserState)
      .subscribe(({ profile }) => (this.profile = profile));
  }

  getActivationLocation(item: Item | undefined) {
    if (!item?.locationRequired) {
      return of(undefined);
    }
    return this.getCurrentLocation().pipe(
      catchError((e) => {
        const data: ConfirmDialogData = {
          confirmKey: 'location.error',
          okKey: 'modal.ok',
          hideCancel: true,
        };
        this.dialog.open(ConfirmDialogComponent, {
          data,
        });
        return throwError(e);
      })
    );
  }
  logLocation() {
    if (!this.profile?.loginId) return undefined;
    const shouldUpdate = this.shouldUpdateLocation();
    if (!shouldUpdate) return undefined;
    this.storage.setItem(LOCATION_REQUESTED_AT_KEY, new Date().toISOString());
    return of(true)
      .pipe(
        // I guess there is no good time to ask for the location, so we'll just ask 5 seconds after the page loads or user logs in
        mergeMap(() => this.getCurrentLocation()),
        mergeMap((coords) => {
          if (!coords) return of(undefined);
          return this.api.user.updateLocation(coords);
        })
      )
      .subscribe(
        () => console.log('LocationService.logLocation()'),
        (error) => console.log('logLocation error', error)
      );
  }
  private shouldUpdateLocation() {
    if (this.itemsLeftToRequestLocation > 0) {
      this.itemsLeftToRequestLocation--;
      return false;
    }
    const locationRequestedAtStr = this.storage.getItem(
      LOCATION_REQUESTED_AT_KEY
    );
    if (!locationRequestedAtStr) return true;
    const locationRequestedAt = new Date(locationRequestedAtStr);
    const now = new Date();
    const minutes = (now.getTime() - locationRequestedAt.getTime()) / 1000 / 60;
    return minutes > UPDATE_LOCATION_INTERVAL_MINUTES;
  }
  private getCurrentLocation() {
    return new Observable<Coords | undefined>((subscribe) => {
      // if (location.protocol.indexOf('https') < 0 || !navigator?.geolocation) {
      //   return subscribe.error(new Error('Location Error'));
      // }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          subscribe.next(
            new Coords(position.coords.latitude, position.coords.longitude)
          );
          subscribe.complete();
        },
        (error) => {
          return subscribe.error(error.message);
        }
      );
    });
  }
}
