import { Component, Input } from '@angular/core';
import { PathSegment } from '../../../../helpers/build-item-path';
import { ThemeService } from '../../../../services/theme.service';

@Component({
  selector: 'team-segment',
  templateUrl: './team-segment.component.html',
  styleUrls: ['./team-segment.component.scss'],
})
export class TeamSegmentComponent {
  label = '';
  @Input() color?: string | null;

  _segment?: PathSegment;
  @Input()
  set segment(segment: PathSegment | undefined) {
    this._segment = segment;
    if (segment) {
      this.label = segment.value;
      this.loadTeam(segment.value);
    }
  }

  get segment() {
    return this._segment;
  }

  loadTeam(team: string) {
    this.themeService.getTheme({ team }).subscribe((t) => {
      if (t.displayName) this.label = t.displayName;
    });
  }

  constructor(private themeService: ThemeService) {}
}
