import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router, Scroll } from '@angular/router';
import { TrackAnalyticsService } from './analytics/track-analytics.service';
import { RealtimeService } from './services/realtime.service';
import { RequestPushNotificationsService } from './services/request-push-notifications.service';
import { ThemeService } from './services/theme.service';

const DEFAULT_SECTION_CLASS = 'max-width';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  sectionClass = DEFAULT_SECTION_CLASS;
  constructor(
    private realtimeService: RealtimeService,
    private router: Router,
    public themeService: ThemeService,
    public requestPushNotificationsService: RequestPushNotificationsService,
    trackAnalyticsService: TrackAnalyticsService,
    viewportScroller: ViewportScroller
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.sectionClass =
          event.snapshot.data?.sectionClass ?? DEFAULT_SECTION_CLASS;
        this.themeService.route = event.snapshot;
        trackAnalyticsService.nav(event.snapshot);
      }
      if (event instanceof Scroll) {
        if (event.position) {
          // backward navigation
          const position = event.position;
          setTimeout(() => viewportScroller.scrollToPosition(position), 10);
        } else if (event.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });
  }

  ngOnInit(): void {
    this.realtimeService.init();
  }
}
