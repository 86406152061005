<card-layout
  [title]="item?.title"
  [description]="item?.content"
  [cta]="'general.details' | translate"
  [chip]="'event.donation' | translate"
  [image]="image"
  [path]="['item', item?.id || '']"
  (clicked)="onClick()"
  [teamName]="item?.foundation?.name"
>
  <div class="mat-subtitle-2">
    <event-countdown [auctionId]="item?.auctionId"></event-countdown>
  </div>
  <ng-content></ng-content>
</card-layout>
