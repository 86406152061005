import { RouterModule, Routes } from '@angular/router';
import { AnalyticsEvents } from '../analytics/analytics-events.enum';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

export const SignRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      analyticsEvent: AnalyticsEvents.LoginViewed,
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      analyticsEvent: AnalyticsEvents.RegisterViewed,
    },
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    data: {
      analyticsEvent: AnalyticsEvents.ForgotViewed,
    },
  },
];

export const SignRouting = RouterModule.forChild(SignRoutes);
