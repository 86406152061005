<div class="shipping-address-list-container">
  <div class="mat-subtitle-1 section-description">
    {{ 'shipping.hint' | translate }}
  </div>
  <ng-container *ngFor="let address of addresses; index as i;">
    <shipping-address-item
      [address]="address"
      (onDelete)="deleteAddressItem(i)"
      (onEdit)="editItem(i)"
      (setDefaultAddress)="setDefaultAddress(i)"
      [allowDelete]="addresses.length > 1"
    ></shipping-address-item>
  </ng-container>
  <button
    mat-stroked-button
    class="add-address-button"
    (click)="onAddressAction.emit({section: 'new'})"
  >
    {{ 'shipping.addAddress' | translate }}
  </button>
</div>
