<ng-container *ngrxLet="card$ as card">
  <h5 mat-dialog-title>
    {{ 'general.edit' | translate }} {{ card?.brand }}
    {{ 'billing.endingIn' | translate }} {{ card?.last4 }} -
    {{ card?.currency }}
  </h5>
  <mat-dialog-content class="mat-typography">
    <form
      id="edit-card-form"
      class="form-body"
      (ngSubmit)="submit(demoForm, card?.currency)"
      [formGroup]="demoForm"
      novalidate
    >
      <div class="mat-subtitle-1">
        {{ 'billing.expires' | translate | uppercase }}
      </div>
      <div class="two-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'billing.expires' | translate }}</mat-label>
          <input
            matInput
            id="cc-exp"
            #expireInput
            formControlName="expDate"
            type="tel"
            class="cc-exp"
            autocomplete="cc-exp"
            placeholder="•• / ••"
            ccExp
            [readonly]="saving$ | async"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'billing.cvv' | translate }}</mat-label>
          <input
            matInput
            id="cc-cvc"
            #cvcInput
            formControlName="cvc"
            type="tel"
            class="input-lg form-control cc-cvc"
            autocomplete="off"
            placeholder="•••"
            required
            ccCVC
            [readonly]="saving$ | async"
          />
        </mat-form-field>
      </div>
      <div class="two-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'shipping.zip' | translate }}</mat-label>
          <input
            matInput
            formControlName="zip"
            autocomplete="postal-code"
            [readonly]="saving$ | async"
            required
          />
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>
      {{ 'general.cancel' | translate | uppercase }}
    </button>
    <button
      mat-raised-button
      color="primary"
      form="edit-card-form"
      [disabled]="!demoForm.valid || (saving$ | async)"
    >
      {{ 'general.save' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</ng-container>
