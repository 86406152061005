import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardEventComponent } from './card-event/card-event.component';
import { CardBinComponent } from './card-bin/card-bin.component';
import { CardRaffleComponent } from './card-raffle/card-raffle.component';
import { CardGiveawayComponent } from './card-giveaway/card-giveaway.component';
import { CardLayoutComponent } from './card-layout/card-layout.component';
import { CardLockerComponent } from './card-locker/card-locker.component';
import { CardGalleryComponent } from './card-gallery/card-gallery.component';
import { CardStackedComponent } from './card-stacked/card-stacked.component';
import { SharedModule } from '../shared.module';
import { CardLinkComponent } from './card-link/card-link.component';
import { CardItemComponent } from './card-item/card-item.component';
import { CardTeamComponent } from './card-team/card-team.component';
import { CardAuctionComponent } from './card-auction/card-auction.component';
import { MatChipsModule } from '@angular/material/chips';
import { CardAuctionDetailComponent } from './card-auction/card-auction-detail/card-auction-detail.component';
import { CardBuyDetailComponent } from './card-buy-detail/card-buy-detail.component';
import { LayoutTypeService } from './layout-type.service';
import { CardWrapComponent } from './card-wrap/card-wrap.component';
import { CardDonationComponent } from './card-donation/card-donation.component';
import { CardCustomBinComponent } from './card-custom-bin/card-custom-bin.component';

@NgModule({
  declarations: [
    CardEventComponent,
    CardBinComponent,
    CardRaffleComponent,
    CardGiveawayComponent,
    CardLayoutComponent,
    CardLockerComponent,
    CardGalleryComponent,
    CardStackedComponent,
    CardLinkComponent,
    CardItemComponent,
    CardTeamComponent,
    CardAuctionComponent,
    CardAuctionDetailComponent,
    CardBuyDetailComponent,
    CardWrapComponent,
    CardDonationComponent,
    CardCustomBinComponent
  ],
  imports: [CommonModule, SharedModule, MatChipsModule],
  exports: [
    CardItemComponent,
    CardGalleryComponent,
    CardStackedComponent,
    CardLockerComponent,
    CardEventComponent,
    CardLayoutComponent,
    CardLinkComponent,
    CardTeamComponent,
    CardWrapComponent
  ],
  providers: [LayoutTypeService]
})
export class CardModule {}
