import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CCard, Currency } from '../../types';
import { createEntitySelectors } from '../helpers/merge-selectors';
import { GlobalState } from '../state';
import * as fromCCard from './ccard.reducer';

export const selectCCardState = createFeatureSelector<fromCCard.State>(
  fromCCard.cCardsFeatureKey
);

export const selectCCardProcessing = createSelector(
  selectCCardState,
  (state) => state.processing
);

export const selectCCardSaving = createSelector(
  selectCCardState,
  (state) => state.savingCard
);

export const selectCCardByCurrency = (currency: Currency) =>
  createSelector(
    selectCCardState,
    (store) =>
      Object.values(store.entities).filter(
        (c) => c?.currency === currency
      ) as CCard[]
  );

export const selectCCardByCurrencyAndFoundation = (
  currency: Currency,
  foundationId: string
) =>
  createSelector(
    selectCCardState,
    (store) =>
      Object.values(store.entities).filter(
        (c) => c?.currency === currency && c?.foundationId === foundationId
      ) as CCard[]
  );

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  createEntitySelectors(
    fromCCard.adapter,
    (state: GlobalState) => state.cCards
  );
