<ng-container *ngrxLet="item$ as item">
  <path></path>
  <div class="item-grid">
    <div>
      <span class="mat-headline-4 c-dark m0">{{ item?.title }}</span>
      <item-gallery *ngIf="item" [item]="item"></item-gallery>
      <description
        class="mobile-hide"
        [item]="item"
        [auction]="auction$ | async"
      ></description>
    </div>
    <cta [item]="item" [auction]="auction$ | async">
      <div class="cta-box cta-mt mobile-hide">
        <p class="mat-h5">{{ 'general.questionsTitle' | translate }}</p>
        <a href="/help" target="_blank" class="gray-7">{{
          'general.questionsHint' | translate
        }}</a>
      </div>
    </cta>
    <description
      class="only-mobile"
      [item]="item"
      [auction]="auction$ | async"
    ></description>
    <div class="cta-box only-mobile">
      <p class="mat-h5">{{ 'general.questionsTitle' | translate }}</p>
      <a href="/help" target="_blank" class="gray-7">{{
        'general.questionsHint' | translate
      }}</a>
    </div>
  </div>
  <related-items *ngIf="item?.id" [itemId]="item?.id"></related-items>
</ng-container>
