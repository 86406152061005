import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GlobalState } from '../../store';
import { logout, sendProfile } from '../../store/user/user.actions';
import {
  selectUserProcessing,
  selectUserState,
} from '../../store/user/user.selectors';
import { UserProfile } from '../../types';
import { ChangePassword } from '../../types/change-password.model';

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnDestroy {
  profile?: UserProfile;
  processing$ = this.store.select(selectUserProcessing);
  subs?: Subscription;
  passwords = {
    old: '',
    newP: '',
    confirm: '',
  };
  hide = true;

  get hasPassword() {
    const { newP, confirm } = this.passwords;
    if (newP || confirm) return true;
    return false;
  }

  get isPasswordCorrect() {
    const { old, newP, confirm } = this.passwords;
    if (!this.hasPassword) return true;
    if (newP.length < 7) return false;
    if (newP === confirm && !!old) return true;
    return false;
  }

  constructor(private store: Store<GlobalState>) {
    this.subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) this.profile = { ...profile };
      // Reset password data
      this.passwords = {
        old: '',
        newP: '',
        confirm: '',
      };
    });
  }

  submit() {
    const passwords: ChangePassword | undefined = this.hasPassword
      ? {
          password: this.passwords.newP,
          oldPassword: this.passwords.old,
        }
      : undefined;
    this.store.dispatch(
      sendProfile({
        profile: {
          contactNumber: this.profile?.contactNumber,
          firstName: this.profile?.firstName,
          lastName: this.profile?.lastName,
          email: this.profile?.email,
        },
        passwords,
      })
    );
  }

  logout() {
    this.store.dispatch(logout());
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }
}
