import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AuctionItems } from '@fan-types/auction.model';
import * as AuctionItemsActions from '@fan-store/auction-items/auction-items.actions';
import { createEntitySelectors } from '@fan-store/helpers/merge-selectors';
import { GlobalState } from '@fan-store/state';

export const auctionItemsFeatureKey = 'auctionItems';

export type State = EntityState<AuctionItems>;

export const adapter: EntityAdapter<AuctionItems> =
  createEntityAdapter<AuctionItems>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(AuctionItemsActions.addAuctionItems, (state, action) =>
    adapter.upsertOne(action.items, state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  createEntitySelectors(adapter, (state: GlobalState) => state.auctionItems);
