import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getItemImage } from '../../../helpers/get-image';
import { AuctionItem } from '../../../types';
import { isSoldOut } from '@fan-helpers/is-sold-out';

@Component({
  selector: 'card-auction',
  templateUrl: './card-auction.component.html',
  styleUrls: ['./card-auction.component.scss'],
})
export class CardAuctionComponent {
  @Output() clicked = new EventEmitter<AuctionItem>();
  image?: string;
  _item?: AuctionItem;
  overlayImage?: string;
  @Input() userBidId?: string;

  @Input()
  set item(item: AuctionItem | undefined) {
    this._item = item;
    this.image = getItemImage(item);
    this.overlayImage = isSoldOut(item)
      ? '/assets/images/sold_out.png'
      : undefined;
  }
  get item() {
    return this._item;
  }

  onClick() {
    if (this.item) this.clicked.emit(this.item);
  }
}
