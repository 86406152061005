<ng-container *ngIf="useCustomUI; else selectCCard">
  <a href (click)="selectPayment(); (false)">
    <ng-content></ng-content>
  </a>
</ng-container>
<ng-template #selectCCard>
  {{ 'event.paymentMethod' | translate }}:
  <a href class="gray-7" (click)="selectPayment(); (false)">
    <span *ngIf="card"
      >{{ card.brand }} {{ 'billing.endingIn' | translate }}
      {{ card.last4 }}</span
    >
    <span *ngIf="!card">{{ 'payment.notSelected' | translate }}</span>
  </a>
</ng-template>
