import { Injectable } from '@angular/core';
import { AnyObject } from '../types';

@Injectable({ providedIn: 'root' })
export class StorageService {
  storageAvailable = !!localStorage && !!localStorage.setItem;
  storage: AnyObject = {};
  constructor() {
    if (this.storageAvailable) {
      try {
        localStorage.setItem('testLocalStorage', 'testLocalStorage');
        localStorage.removeItem('testLocalStorage');
      } catch (e) {
        this.storageAvailable = false;
      }
    }
  }
  setItem(key: string, data: string): void {
    this.storageAvailable
      ? localStorage.setItem(key, data)
      : (this.storage[key] = data);
  }
  getItem(key: string): string {
    return this.storageAvailable
      ? localStorage.getItem(key)
      : this.storage[key];
  }
  getAndParseItem(key: string): string {
    const value = this.getItem(key);
    try {
      const parsed = JSON.parse(value);
      return parsed;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return value;
  }
  removeItem(key: string): void {
    this.storageAvailable
      ? localStorage.removeItem(key)
      : delete this.storage[key];
  }
  clear() {
    this.storageAvailable ? localStorage.clear() : (this.storage = {});
  }
}
