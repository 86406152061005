<div class="form-page">
  <div class="form-header">
    <a class="title active">{{ 'auth.logInHint' | translate }}</a>
  </div>
  <form class="form-body">
    <div class="mb">
      <span>{{ 'auth.goSignUp' | translate }}</span>
    </div>
    <button
      class="mb"
      mat-raised-button
      (click)="goToRegister.emit(); (false)"
      color="primary"
    >
      {{ 'auth.signUp' | translate | uppercase }}
    </button>
    <div class="mb">
      <span>{{ 'auth.goLogIn' | translate }}</span>
    </div>
    <button
      mat-raised-button
      (click)="goToLogin.emit(); (false)"
      class="mb"
      color="primary"
    >
      {{ 'auth.logIn' | translate | uppercase }}
    </button>
    <ng-content></ng-content>
  </form>
</div>
