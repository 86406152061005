<ng-container *ngrxLet="item$ as item">
  <container-element [ngSwitch]="item?.itemType">
    <card-auction
      *ngSwitchCase="'auction'"
      [item]="asAuction(item)"
      (clicked)="onClick(item)"
    ></card-auction>
    <card-bin
      *ngSwitchCase="'buynow'"
      [item]="asBin(item)"
      (clicked)="onClick(item)"
    ></card-bin>
    <card-raffle
      *ngSwitchCase="'ticket'"
      [item]="asTicket(item)"
      (clicked)="onClick(item)"
    ></card-raffle>
    <card-giveaway
      *ngSwitchCase="'giveaway'"
      [item]="asGiveaway(item)"
      (clicked)="onClick(item)"
    ></card-giveaway>
    <card-donation
      *ngSwitchCase="'donation'"
      [item]="asDonation(item)"
      (clicked)="onClick(item)"
    ></card-donation>
    <card-custom-bin
      *ngSwitchCase="'customBuynow'"
      [item]="asCustomBin(item)"
      (clicked)="onClick(item)"
    ></card-custom-bin>
  </container-element>
</ng-container>
