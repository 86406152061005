import { createAction, props } from '@ngrx/store';
import { AuctionItems } from '../../types';

export const pullAuctionItems = createAction(
  '[AuctionItems/API] Pull content',
  props<{ auctionId: string }>()
);

export const addAuctionItems = createAction(
  '[AuctionItems/API] Add Auction Items',
  props<{items: AuctionItems}>()
);

