import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentItemTeam } from '../../../types';

@Component({
  selector: 'card-team',
  templateUrl: './card-team.component.html',
  styleUrls: ['./card-team.component.scss'],
})
export class CardTeamComponent {
  @Output() clicked = new EventEmitter<ContentItemTeam>();
  @Input() itemTeam?: ContentItemTeam | null;
  @Input() wrap?: boolean = false;

  onClick() {
    if (this.itemTeam) this.clicked.emit(this.itemTeam);
  }
}
