<form #profileData="ngForm" (ngSubmit)="submit()" *ngIf="profile">
  <div *ngIf="title" class="mat-h5">{{ title }}</div>
  <div *ngIf="_section === 'onboarding'" class="mat-subtitle-1 mb">
    {{ 'shipping.hint' | translate }}
  </div>
  <div class="four-cols">
    <mat-form-field [ngClass]="{'span-full': _section === 'new-item-section'}" appearance="outline">
      <mat-label>{{ 'shipping.name' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="profileAddress.name"
        name="addressName"
        [placeholder]="'shipping.placeholderName' | translate"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <div *ngIf="_section !== 'new-item-section'"></div>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'shipping.address1' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="profileAddress.address1"
        name="address1"
        autocomplete="address-line1"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'shipping.address2' | translate }}</mat-label>
      <input
        matInput
        type="text"
        autocomplete="address-line2"
        [(ngModel)]="profileAddress.address2"
        name="address2"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'shipping.city' | translate }}</mat-label>
      <input
        matInput
        autocomplete="address-level2"
        type="text"
        [(ngModel)]="profileAddress.city"
        name="city"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" [ngClass]="{'half-column': _section !== 'new-item-section'}">
      <mat-label>{{ 'shipping.state' | translate }}</mat-label>
      <input
        matInput
        type="text"
        autocomplete="address-level1"
        [(ngModel)]="profileAddress.state"
        name="state"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field class="zip-code-input" appearance="outline" [ngClass]="{'half-column': _section !== 'new-item-section'}">
      <mat-label>{{ 'shipping.zip' | translate }}</mat-label>
      <input
        matInput
        type="text"
        autocomplete="postal-code"
        [(ngModel)]="profileAddress.zip"
        name="zip"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
  </div>
  <div class="buttons-container">
    <button
      *ngIf="_section !== 'onboarding'"
      mat-stroked-button
      (click)="onCancel.emit()"
    >
      {{ 'general.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="(processing$ | async) || !profileData.valid"
    >
      {{ saveButtonText | translate }}
    </button>
  </div>
</form>
