import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';

interface Participant {
  name: string;
  id: string;
}

@Component({
  selector: 'ot-participants',
  templateUrl: './ot-participants.component.html',
  styleUrls: ['./ot-participants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtParticipantsComponent implements OnChanges {
  @Input() participants?: Participant[] = [];
  @Input() userProfileId?: string;
  showCount = false;
  otherParticipantsCount = 0;
  filteredParticipants: Participant[] = [];
  loggedParticipant?: Participant;
  avatarColors = ['#8B00E1', '#E1B400', '#E14400'];

  ngOnChanges() {
    const participants = this.participants || [];
    this.loggedParticipant = participants.find(
      (participant) => participant.id === this.userProfileId
    );
    if (participants.length > 4) {
      this.showCount = true;
      this.filteredParticipants = [];
      this.otherParticipantsCount = (this.participants?.length ?? 0) - 1;
    } else {
      this.showCount = false;
      this.filteredParticipants = participants.filter(
        (participant) => participant.id !== this.userProfileId
      );
    }
  }
}
