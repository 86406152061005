import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../../store';
import { selectById as selectRelatedItems } from '../../../store/related-items/related-items.reducer';
import { RelatedItems } from '../../../types';
import * as RelatedItemActions from '@fan-store/related-items/related-items.actions';
import { TrackAnalyticsService } from '../../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../../analytics/analytics-events.enum';

@Component({
  selector: 'related-items',
  templateUrl: './related-items.component.html',
  styleUrls: ['./related-items.component.scss'],
})
export class RelatedItemsComponent {
  items$?: Observable<RelatedItems | undefined>;
  private _itemId: string | undefined;

  @Input()
  set itemId(value: string | undefined) {
    if (value) {
      this.store.dispatch(
        RelatedItemActions.pullRelatedItems({ itemId: value })
      );
      this.items$ = this.store.select(selectRelatedItems(value));
    }
    this._itemId = value;
  }

  constructor(
    private store: Store<GlobalState>,
    private trackAnalytics: TrackAnalyticsService
  ) {}

  clickItem(itemId: string) {
    this.trackAnalytics.itemAction(AnalyticsEvents.ClickRelatedItem, itemId, {
      itemSource: this._itemId,
    });
  }
}
