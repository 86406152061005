<mat-dialog-content>
  <div class="onboarding-content">
    <onboarding-controller
      (cancel)="cancel()"
      [profile]="profile$ | async"
      [ccards]="ccards$ | async"
      [currency]="currency"
      (cardAdded)="cardAdded($event)"
      [foundationId]="foundationId"
    ></onboarding-controller>
  </div>
</mat-dialog-content>
