import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import profileHasAddress from '@fan-helpers/profile-has-address';
import { selectBidCompleted, selectBidProcessing } from '@fan-store/bid/bid.selectors';
import { GlobalState } from '@fan-store/state';
import { selectUserState } from '@fan-store/user/user.selectors';
import { Auction } from '@fan-types/auction.model';
import { CCard } from '@fan-types/ccard.model';
import { Currency } from '@fan-types/currency';
import { Foundation } from '@fan-types/foundation.model';
import { CustomBuynowItem } from '@fan-types/item.model';
import { UserProfile } from '@fan-types/user-profile';
import { Store } from '@ngrx/store';
import { AnalyticsEvents } from 'apps/fan/src/app/analytics/analytics-events.enum';
import { TrackAnalyticsService } from 'apps/fan/src/app/analytics/track-analytics.service';
import { Subscription } from 'rxjs';
import { OnboardingDialogComponent, OnboardingDialogData } from '../onboarding-dialog/onboarding-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '@dash-nx/ui-components';
import { buildItemPath } from '@fan-helpers/build-item-path';
import { processBid } from '@fan-store/bid/bid.actions';

@Component({
  selector: 'custom-buynow',
  templateUrl: './custom-buynow.component.html',
  styleUrls: ['./custom-buynow.component.scss']
})
export class CustomBuynowComponent {
  currency?: Currency;
  foundation?: Foundation;
  subs: Subscription[] = [];
  profile?: UserProfile;
  card?: CCard;
  processing$ = this.store.select(selectBidProcessing);

  showPrePurchaseButton = true;
  customMessage = '';
  waitingPurchase = false;

  @Input() item?: CustomBuynowItem;

  private _auction?: Auction | undefined | null;
  public get auction(): Auction | undefined | null {
    return this._auction;
  }
  @Input()
  public set auction(value: Auction | undefined | null) {
    this._auction = value;
    this.currency = this.auction?.foundation?.currency ?? 'USD';
    this.foundation = this.auction?.foundation;
  }

  constructor(
    private store: Store<GlobalState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private trackAnalytics: TrackAnalyticsService,
    private router: Router
  ) {
    const subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) this.profile = { ...profile };
    });
    this.subs.push(subs);

    // Listen for bid completion
    const bidCompleted = this.store
      .select(selectBidCompleted)
      .subscribe((completed) => {
        if (completed && this.waitingPurchase) {
          this.waitingPurchase = false;
          this.customMessage = '';
          this.showPrePurchaseButton = true;
          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: {
                userBidId: null
              },
              queryParamsHandling: 'merge',
            }
          );
        }
      });
    this.subs.push(bidCompleted);
  }

  cardSelected(card: CCard) {
    setTimeout(() => (this.card = card));
  }

  submit() {
    if (!this.currency || !this.customMessage || !this.item?.id) return;
    this.trackAnalytics.customBuynow(
      this.item.id,
      this.customMessage
    );
    if (!this.card || !profileHasAddress(this.profile)) {
      return this.notOnboarded();
    }
    this.confirmPurchase();
  }

  confirmPurchase() {
    const data: ConfirmDialogData = {
      confirmKey: 'bid.confirmPurchase',
      okKey: 'event.preBuy',
      cancelKey: 'general.no'
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.completePurchase();
      }
    });
  }

  completePurchase() {
    const { routes } = buildItemPath(
      this.route.snapshot.data,
      this.route.snapshot.params
    );
    this.waitingPurchase = true;
    this.store.dispatch(
      processBid({
        bid: {
          step: 'saving',
          amount: 1,
          currency: this.currency!,
          item: this.item!,
          itemPath: [...routes, 'item', this.item!.id],
          type: 'purchase',
          card: this.card,
          customMessage: this.customMessage
        },
      })
    );
  }

  private notOnboarded() {
    if (!this.currency) return;
    const dialogRef = this.dialog.open<
      OnboardingDialogComponent,
      OnboardingDialogData
    >(OnboardingDialogComponent, {
      data: { currency: this.currency, foundationId: this.foundation?.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const data: ConfirmDialogData = {
        confirmKey: result.isNewProfile
          ? 'bid.newAccount'
          : 'bid.existingAccount',
        okKey: 'modal.ok',
        hideCancel: true,
        bodyKey: 'bid.goPurchase',
      };
      this.dialog.open(ConfirmDialogComponent, {
        data,
      });
      if (!this.card) this.card = result.card;
    });
  }

}
