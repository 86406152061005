import { createReducer, on } from '@ngrx/store';
import { MyBid } from '../../types';
import { logout } from '../user/user.actions';
import {
  pullLocker,
  pullLockerFailed,
  pullLockerSucceeded,
} from './locker.actions';

export const lockersFeatureKey = 'locker';

export interface State {
  processing?: boolean;
  myBids: MyBid[];
}

export const initialState: State = {
  processing: false,
  myBids: [],
};

export const reducer = createReducer(
  initialState,

  on(pullLocker, (state) => ({
    ...state,
    processing: true,
  })),

  on(pullLockerSucceeded, (state, { myBids }) => ({
    ...state,
    processing: false,
    myBids,
  })),

  on(pullLockerFailed, (state) => ({
    ...state,
    processing: false,
  })),

  on(logout, (state) => ({
    ...state,
    processing: false,
    myBids: [],
  }))
);
