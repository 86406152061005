import { EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

export const createEntitySelectors = <T, V>(adapter: EntityAdapter<T>, selectState: (state: V) => EntityState<T>) => {
  const selectors = adapter.getSelectors(selectState);

  const selectById = (itemId: string) =>
    createSelector(
      selectors.selectEntities,
      (entities) => entities?.[itemId]
    );

  return {
    ...selectors,
    selectById,
  };
};
