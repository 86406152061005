import { ContentSectionResponse } from '@fan-types/content';
import { ApiBase } from '@fan-services/models/api-base';
import { AuctionItem } from '@fan-types/item.model';
import { Auction } from '@fan-types/auction.model';
import { TeamResult } from '@fan-types/team-result';

export class ContentApi extends ApiBase {
  model = 'content';

  featured(userProfileId?: string) {
    return this.http.get<ContentSectionResponse[]>(this.modelUri('home'), {
      params: this.setParams({ userProfileId }),
    });
  }

  getContent(
    moduleGroup: string,
    params?: { limit?: number; skip?: number; foundationId?: string }
  ) {
    return this.http.get<ContentSectionResponse[]>(this.modelUri(moduleGroup), {
      params: this.setParams(params),
    });
  }

  teamContentByName(name: string) {
    return this.http.get<ContentSectionResponse[]>(this.modelUri('team', name));
  }

  searchContent(search: string) {
    return this.http.get<{results: {
      items: AuctionItem[],
      events: Auction[],
      teams: TeamResult[]
    }}>(this.modelUri('search'), {
      params: this.setParams({query: search}),
    });
  }
}
