import { RouterModule, Routes } from '@angular/router';
import { AnalyticsEvents } from '../analytics/analytics-events.enum';
import { ClosedComponent } from './closed/closed.component';
import { EventComponent } from './event/event.component';
import { EventRouteData } from './events.routing.params';
import { HomeComponent } from './home/home.component';
import { ItemComponent } from './item/item.component';
import { LockerComponent } from './locker/locker.component';
import { TeamHomeComponent } from './team-home/team-home.component';

const eventPaths = (path = '', data: EventRouteData = {}): Routes => {
  return [
    {
      path: `${path}event/:id`,
      component: EventComponent,
      data: {
        ...data,
        path: 'event',
        sectionClass: 'full-width-section',
        keepTheme: true,
      } as EventRouteData,
    },
    {
      path: `${path}item/:id`,
      component: ItemComponent,
      data: {
        ...data,
        path: 'item',
        keepTheme: true,
      } as EventRouteData,
    },
    {
      path: `${path}auction/:id`,
      redirectTo: `${path}event/:id`,
    },
  ];
};

export const EventsRoutes: Routes = [
  {
    path: 'auctions/:team',
    redirectTo: 'team/:team',
  },
  {
    path: 'auctions',
    redirectTo: '',
  },
  {
    path: '',
    component: HomeComponent,
    data: {
      analyticsEvent: AnalyticsEvents.HomeViewed,
    },
  },
  {
    path: 'league/:team',
    redirectTo: 'team/:team',
  },
  {
    path: 'team/:team',
    component: TeamHomeComponent,
    data: {
      segments: ['team'],
      themeFrom: 'team',
      analyticsEvent: AnalyticsEvents.TeamPageViewed,
      analyticsParams: { team: 'team' },
    },
  },
  {
    path: `closed-events`,
    component: ClosedComponent,
    data: {
      segments: ['closed'],
      analyticsEvent: AnalyticsEvents.ClosedEventsViewed,
    },
  },
  {
    path: `locker`,
    component: LockerComponent,
    data: {
      segments: ['locker'],
      keepTheme: true,
      analyticsEvent: AnalyticsEvents.LockerViewed,
    },
  },
  {
    path: 'team/:team/closed-events',
    component: ClosedComponent,
    data: {
      segments: ['team', 'closed'],
      themeFrom: 'team',
      analyticsEvent: AnalyticsEvents.TeamClosedEventsViewed,
    },
  },
  ...eventPaths(),
  ...eventPaths('team/:team/', { segments: ['team'], themeFrom: 'team' }),
  ...eventPaths('closed-events/', { segments: ['closed'] }),
  ...eventPaths('locker/', { segments: ['locker'] }),
  ...eventPaths('team/:team/closed-events/', {
    segments: ['team', 'closed'],
    themeFrom: 'team',
  }),
  ...eventPaths('team/:team/locker/', {
    segments: ['team', 'locker'],
    themeFrom: 'team',
  }),
  // {
  //   path: '**',
  //   redirectTo: ''
  // }
];

export const EventsRouting = RouterModule.forChild(EventsRoutes);
