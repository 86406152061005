import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FulfillmentOption, FulfillmentOptionType } from '@fan-types/fulfillment-option';

@Component({
  selector: 'bid-complete-dialog',
  templateUrl: './bid-complete-dialog.component.html',
  styleUrls: ['./bid-complete-dialog.component.scss']
})
export class BidCompleteDialogComponent {
  shippingAvailable = false;
  pickupAvailable = false;
  constructor(
    public dialogRef: MatDialogRef<BidCompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {fulfillmentOptions?: FulfillmentOption[]}
  ) {
    this.shippingAvailable = !!dialogData.fulfillmentOptions?.find(option => option.type === FulfillmentOptionType.shipping);
    this.pickupAvailable = !!dialogData.fulfillmentOptions?.find(option => option.type === FulfillmentOptionType.pickup);
  }

  close() {
    this.dialogRef.close();
  }
}
