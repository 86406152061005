import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBid from './bid.reducer';

export const selectBidState = createFeatureSelector<fromBid.State>(
  fromBid.bidFeatureKey
);

export const selectBidProcessing = createSelector(
  selectBidState,
  (state) => state.processing
);

export const selectServerDiffMs = createSelector(
  selectBidState,
  (state) => state.serverDiffMs
);

export const selectBidCompleted = createSelector(
  selectBidState,
  (state) => state.completed
);
