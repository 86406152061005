<div class="ot-container">
  <div class="keen-slider" #sliderRef>
    <div class="keen-slider__slide slide">
      <div class="slide-header">
        <div class="slider-header-bg"></div>
        <img class="slide-image" src="/assets/ot/ico_time.png" />
      </div>
      <div class="slide-content">
        <span class="mat-subtitle-3">{{ 'overtime.t1' | translate }}</span>
        <div class="mat-subtitle-4">
          {{ 'overtime.b1' | translate }}
        </div>
      </div>
    </div>
    <div class="keen-slider__slide slide">
      <div class="slide-header">
        <div class="slider-header-bg"></div>
        <img class="slide-image" src="/assets/ot/ico_dollar.png" />
      </div>
      <div class="slide-content">
        <span class="mat-subtitle-3">{{ 'overtime.t2' | translate }}</span>
        <div class="mat-subtitle-4">
          {{ 'overtime.b2' | translate }}
        </div>
      </div>
    </div>
    <div class="keen-slider__slide slide">
      <div class="slide-header">
        <div class="slider-header-bg"></div>
        <img class="slide-image" src="/assets/ot/ico_cart.png" />
      </div>
      <div class="slide-content">
        <span class="mat-subtitle-3">{{ 'overtime.t3' | translate }}</span>
        <div class="mat-subtitle-4">
          {{ 'overtime.b3' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="controls">
    <button mat-flat-button color="primary" (click)="onActionClicked()">
      {{ action | translate | uppercase }}
    </button>
  </div>
</div>
