import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../../store';
import { selectById } from '../../store/auction/auction.reducer';
import { Auction } from '../../types';
import * as AuctionActions from '@fan-store/auction/auction.actions';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent {
  auction$?: Observable<Auction | undefined>;
  _auctionId: string | undefined;

  set auctionId(auctionId: string | undefined) {
    this._auctionId = auctionId;
    if (auctionId) {
      this.auction$ = this.store.select(selectById(auctionId));
      this.store.dispatch(AuctionActions.pullAuction({ auctionId }));
    }
  }
  get auctionId() {
    return this._auctionId;
  }

  constructor(
    private store: Store<GlobalState>,
    private route: ActivatedRoute,
    public themeService: ThemeService,
  ) {
    this.route.params.subscribe((params) => (this.auctionId = params.id));
  }
}
