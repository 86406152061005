import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { defer } from 'rxjs';
import { CreditCardValidators, CreditCard } from 'angular-cc-library';
import { CCard, Currency } from '../../../types';
import { map } from 'rxjs/operators';
import { GlobalState } from '../../../store';
import { Store } from '@ngrx/store';
import { selectCCardSaving } from '../../../store/ccard/ccard.selectors';
import { addCCardStart } from '../../../store/ccard/ccard.actions';

@Component({
  selector: 'payment-form-api',
  templateUrl: './payment-form-api.component.html',
  styleUrls: ['./payment-form-api.component.scss'],
})
export class PaymentFormApiComponent {
  @Input() currency?: Currency;
  @Input() foundationId?: string;
  @Output() cardAdded = new EventEmitter<CCard>();
  saving$ = this.store.select(selectCCardSaving);

  constructor(private fb: FormBuilder, private store: Store<GlobalState>) {}

  public demoForm: FormGroup = this.fb.group({
    creditCard: ['', [CreditCardValidators.validateCCNumber]],
    expDate: ['', [Validators.required, CreditCardValidators.validateExpDate]],
    zip: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(10)],
    ],
    cvc: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
    ],
  });

  public cctype$ = defer(
    () => this.demoForm.get('creditCard')?.valueChanges
  ).pipe(map((num: string) => CreditCard.cardType(num)));

  public goToNextField(controlName: string, nextField: HTMLInputElement) {
    if (this.demoForm.get(controlName)?.valid) {
      nextField.focus();
    }
  }

  public onSubmit(demoForm: FormGroup) {
    const { creditCard, expDate, zip, cvc } = demoForm.value;
    const expMY = expDate.split(' / ');
    const expY = Number(expMY[1]);
    this.store.dispatch(
      addCCardStart({
        ccard: {
          currency: this.currency ?? 'USD',
          cvv: cvc,
          number: creditCard.replace(/\s/g, ''),
          zipCode: zip,
          exp_month: expMY[0],
          exp_year: String(expY < 100 ? 2000 + expY : expY),
          foundationId: this.foundationId,
        },
        onCardAdded: (ccard) => this.cardAdded.emit(ccard),
      })
    );
  }
}
