import { Component, Input } from '@angular/core';
import { AnalyticsEvents } from '../../../../analytics/analytics-events.enum';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';
import {
  Auction,
  ContentItem,
  ContentItemTeam,
  ContentItemWeb,
  ContentSection,
  Item,
} from '../../../../types';

@Component({
  selector: 'dash-stacked',
  templateUrl: './stacked.component.html',
  styleUrls: ['./stacked.component.scss'],
})
export class StackedComponent {
  private _section?: ContentSection;
  auctionIds: string[] = [];
  items: ContentItem[] = [];

  @Input()
  set section(section: ContentSection | undefined) {
    this._section = section;
    this.auctionIds =
      section && 'auctionIds' in section ? section.auctionIds : [];
    this.items = section && 'items' in section ? section.items : [];
  }
  get section() {
    return this._section;
  }

  constructor(private trackAnalytics: TrackAnalyticsService) {}

  clickEvent(event: Auction) {
    this.trackAnalytics.eventAction(
      AnalyticsEvents.ViewFeaturedItem,
      event.id,
      {
        section: this.section?.section,
        type: 'event',
        sectionStyle: this.section?.sectionStyle,
      }
    );
  }

  clickItem(item: Item) {
    this.trackAnalytics.itemAction(AnalyticsEvents.ViewFeaturedItem, item.id, {
      section: this.section?.section,
      type: 'item',
      sectionStyle: this.section?.sectionStyle,
    });
  }

  clickWeb(item: ContentItemWeb) {
    this.trackAnalytics.viewFeatured({
      type: 'link',
      title: item.title,
      itemRef: item.itemRef,
      section: this.section?.section,
      sectionStyle: this.section?.sectionStyle,
    });
  }

  clickTeam(item: ContentItemTeam) {
    this.trackAnalytics.viewFeatured({
      type: 'team',
      title: item.title,
      itemRef: item.itemRef,
      team: item.team?.name,
      params: item.parms,
      section: this.section?.section,
      sectionStyle: this.section?.sectionStyle,
    });
  }

  trackEventBy(index: number, auctionId: string) {
    return auctionId;
  }

}
