import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  defaultImage = '/assets/images/placeholder.jpeg';
  fallbackImage?: string;
  safeImage?: SafeUrl;

  constructor(
    private sanitizer: DomSanitizer,
  ){}

  @Input() overlayImage?: string;

  @Input()
  public set src(value: string | undefined) {
    this.safeImage = value
      ? this.sanitizer.bypassSecurityTrustUrl(value)
      : undefined;
  }

  onError() {
    if (this.fallbackImage) return;
    this.fallbackImage = this.defaultImage;
  }
}
