<div class="mat-subtitle-2">
  <ng-template [ngIf]="title">{{ title | translate | uppercase }}</ng-template
  >&nbsp;<ng-template [ngIf]="itemsLeft">{{
    itemsLeft | number: '1.0'
  }}</ng-template>
</div>
<div class="mat-subheading-2 dark m0">
  {{ item?.minimumBid | currency: 'USD':'symbol':'1.2-2' }}
  <event-currency [auctionId]="item?.auctionId"></event-currency>
</div>
<div class="mat-subtitle-2">
  <event-countdown [auctionId]="item?.auctionId"></event-countdown>
</div>
