import { Injectable } from '@angular/core';
import * as ItemActions from '@fan-store/item/item.actions';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import {
  RealtimeMessage,
  RealtimeMessagePayload,
  UserMessage,
  AuctionItemMessage,
  AuctionMessage,
} from '../types/realtime';
import {
  NotificationsComponent,
  NotificationsComponentData,
} from '../shared/layout/notifications/notifications.component';
import { messageToItem } from '../helpers/message-to-item';
import { GlobalState } from '../store';
import { Store } from '@ngrx/store';
import { pullItem, updateItem } from '../store/item/item.actions';
import { endAuction, startAuction } from '../store/auction/auction.actions';
import { SnackbarClass } from '../types';
import { updateServerDiffMs } from '../store/bid/bid.actions';
import { pullOTItems } from '../store/user/user.actions';
import PubNub from 'pubnub';

const getUUID = () => {
  try {
    const uuid = localStorage.getItem('pubnub-uuid');
    if (uuid) return uuid;
  } catch (e) {}
  const uuid = PubNub.generateUUID();
  try {
    localStorage.setItem('pubnub-uuid', uuid);
  } catch (e) {}
  return uuid;
};
@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  private pubNub = new PubNub({
    publishKey: environment.pubKey || '',
    subscribeKey: environment.subKey || '',
    uuid: getUUID(),
  });
  private _networkReconnect = new Subject<void>();
  get networkReconnect() {
    return this._networkReconnect.asObservable();
  }

  private _userChannel: string | undefined;
  public get userChannel(): string | undefined {
    return this._userChannel;
  }
  public set userChannel(value: string | undefined) {
    if (value && !this._userChannel) this.listen(value);
    if (!value && this._userChannel) this.unsubscribe(this._userChannel);
    if (value && this._userChannel && value !== this._userChannel) {
      this.unsubscribe(this._userChannel);
      this.listen(value);
    }
    this._userChannel = value;
  }

  constructor(
    private snackBar: MatSnackBar,
    private store: Store<GlobalState>
  ) {}

  init() {
    this.listedBids();
    this.store.dispatch(updateServerDiffMs());
  }

  private listedBids() {
    this.pubNub.subscribe({
      channels: ['bids'],
    });
    this.pubNub.addListener({
      message: (msg: RealtimeMessage) => this.onMessage(msg.message),
      status: (status) => {
        if (status?.category === 'PNNetworkUpCategory')
          this._networkReconnect.next();
      },
    });
  }

  private onMessage(payload: RealtimeMessagePayload) {
    if (payload.group === 'notifications') this.processUserMessage(payload);
    else if (payload.group === 'item') this.processItemMessage(payload);
    else if (payload.group === 'auction') this.processEventMessage(payload);
  }

  private processItemMessage(message: AuctionItemMessage) {
    const changes = messageToItem(message);
    this.store.dispatch(
      updateItem({ item: { id: message.auctionItemId, changes } })
    );
    if (
      message.type === 'otStart' &&
      this.userChannel &&
      message.overtimeUserProfileIds.includes(this.userChannel)
    ) {
      this.store.dispatch(pullItem({ itemId: message.auctionItemId }));
    }
  }

  private processUserMessage(message: UserMessage) {
    const data: NotificationsComponentData = {
      message: message.message,
      auctionItemId: message.auctionItemId,
      cta: 'action.bid',
    };
    this.snackBar.openFromComponent(NotificationsComponent, {
      duration: 60000,
      data,
      panelClass: SnackbarClass.Information,
    });
    if (message.auctionItemId) {
      this.store.dispatch(
        ItemActions.pullItem({ itemId: message.auctionItemId })
      );
      this.store.dispatch(pullOTItems());
    }
  }

  private processEventMessage(message: AuctionMessage) {
    const { auctionId, type } = message;
    if (type === 'start') this.store.dispatch(startAuction({ auctionId }));
    else if (type === 'end') this.store.dispatch(endAuction({ auctionId }));
  }

  private listen(channel: string) {
    this.pubNub.subscribe({
      channels: [channel],
    });
  }

  private unsubscribe(channel: string) {
    this.pubNub.unsubscribe({
      channels: [channel],
    });
  }
}
