import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import mixpanel, { Dict } from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { GlobalState } from '../store';
import { selectUserState } from '../store/user/user.selectors';
import { UserProfile } from '../types';
import { AnalyticsEvents } from './analytics-events.enum';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _profile: UserProfile | undefined;
  private get profile(): UserProfile | undefined {
    return this._profile;
  }
  private set profile(value: UserProfile | undefined) {
    this._profile = value ? { ...value } : undefined;
    this.updateProfile();
  }
  constructor(
    private store: Store<GlobalState>,
    private translate: TranslateService
  ) {
    mixpanel.init(environment.mixpanelToken);
    this.store
      .select(selectUserState)
      .subscribe(({ profile }) => (this.profile = profile));
  }

  private updateProfile() {
    const profile = this.profile;
    if (!profile) return;
    mixpanel.identify(profile.id);
    mixpanel.register({
      $email: profile.email,
      $first_name: profile.firstName,
      $last_name: profile.lastName,
      $phone: profile.contactNumber,
    });
    mixpanel.people.set({
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      phone: profile.contactNumber,
      name: `${profile.firstName ?? ''} ${profile.lastName ?? ''}`,
      $email: profile.email,
      $first_name: profile.firstName,
      $last_name: profile.lastName,
      $phone: profile.contactNumber,
      profileId: profile.id,
      lang: this.translate.currentLang,
    });
  }

  track(event: AnalyticsEvents, props: Dict) {
    mixpanel.track(event, {
      lang: this.translate.currentLang,
      userId: this.profile?.id,
      ...props,
    });
  }
}
