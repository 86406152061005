import { Item, ItemState } from '../types';
import { AuctionItemMessage } from '../types/realtime';

export const messageToItem = (message: AuctionItemMessage): Partial<Item> => {
  if (message.type === 'closed') {
    const {
      topBidAmount,
      topBidUserProfileId,
      winnerMessage,
      winnerProfileIds,
    } = message;
    return {
      topBidAmount,
      topBidUserProfileId,
      winnerMessage,
      winnerProfileIds,
      itemState: ItemState.Closed,
    };
  } else if (message.type === 'high') {
    const { bidAmount, bidCount, userProfileId } = message;
    return {
      topBidAmount: bidAmount,
      bidCount,
      topBidUserProfileId: userProfileId,
    };
  } else if (message.type === 'otBid') {
    const { otBidCount, topBidAmount, topBidUserProfileId } = message;
    return {
      otBidCount,
      topBidUserProfileId,
      topBidAmount,
    };
  } else if (message.type === 'otStart') {
    const { overtimeEnd, overtimeStart, overtimeUserProfileIds } = message;
    return {
      overtimeEnd,
      overtimeStart,
      overtimeUserProfileIds,
      itemState: ItemState.Overtime,
    };
  } else if (message.type === 'paid') {
    return {
      itemState: ItemState.Paid,
    };
  } else if (message.type === 'purchase') {
    const { purchasedCount } = message;
    return {
      purchasedCount,
    };
  } else if (message.type === 'winners') {
    const { winnerProfileIds } = message;
    return {
      winnerProfileIds,
    };
  }
  return {};
};
