<card-event
  *ngIf="item?.itemRef && item?.itemType === 'auction'"
  [auctionId]="item?.itemRef ?? ''"
  (clicked)="eventClicked.next($event)"
></card-event>
<card-link
  (clicked)="webClicked.emit($event)"
  *ngIf="item?.itemType === 'web'"
  [item]="web"
></card-link>
<card-item
  (clicked)="itemClicked.emit($event)"
  *ngIf="item?.itemType === 'item'"
  [id]="item?.itemRef"
></card-item>
<card-team
  (clicked)="teamClicked.emit($event)"
  *ngIf="item?.itemType === 'team'"
  [itemTeam]="team"
  [wrap]="true"
></card-team>
