import { ViewportScroller } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Content } from '../../../../types';

@Component({
  selector: 'scroll-restore',
  template: '',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollRestoreComponent implements OnChanges {
  @Input() content?: Content;

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnChanges() {
    // Ugly solution to restore scroll position, the NG out of the box solution does not work in
    // combination with the way we're initializing the content in the home view
    const position = this.viewportScroller.getScrollPosition();
    if (position[1]) {
      setTimeout(() => {
        this.viewportScroller.scrollToPosition(position);
      }, 10);
    }
  }
}
