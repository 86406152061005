import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../store';
import { selectById } from '../../../store/content/content.reducer';
import { pullContent } from '@fan-store/content/content.actions';
import { Observable } from 'rxjs';
import { Content, ContentSection } from '../../../types';

@Component({
  selector: 'dash-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  content$?: Observable<Content | undefined>;
  @Input()
  set contentId(contentId: string) {
    this.content$ = this.store.select(selectById(contentId));
    this.store.dispatch(pullContent({ contentId }));
  }

  @Input()
  set teamId(contentId: string) {
    this.content$ = this.store.select(selectById(contentId));
    this.store.dispatch(pullContent({ contentId, isTeam: true }));
  }

  constructor(private store: Store<GlobalState>) {}

  trackSectionBy(index: number, el: ContentSection) {
    return `${el.section}-${index}`;
  }
}
