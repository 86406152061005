<mat-accordion
  class="example-headers-align"
  displayMode="flat"
  *ngIf="item?.content"
>
  <mat-expansion-panel
    displayMode="flat"
    class="mat-elevation-z0"
    [expanded]="expanded"
  >
    <mat-expansion-panel-header>
      <span class="mat-h5 m0">{{ 'event.description' | translate }}</span>
    </mat-expansion-panel-header>
    <ui-linkified [text]="item?.content"></ui-linkified>
    <ui-linkified class="contact" *ngIf="auction?.foundation?.contactMessage" [text]="auction?.foundation?.contactMessage"></ui-linkified>
  </mat-expansion-panel>
</mat-accordion>
