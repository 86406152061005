<form
  [formGroup]="demoForm"
  (ngSubmit)="onSubmit(demoForm)"
  id="edit-card-form"
  class="form-body"
  novalidate
>
  <mat-form-field appearance="outline">
    <mat-label
      >{{ 'billing.card' | translate }}
      <span *ngIf="cctype$ | async as cctype"
        >[<span class="cc-brand">{{ cctype }}</span
        >]</span
      ></mat-label
    >
    <input
      cdkFocusInitial
      matInput
      id="cc-number"
      (input)="goToNextField('creditCard', expireInput)"
      formControlName="creditCard"
      type="tel"
      class="cc-number"
      autocomplete="cc-number"
      placeholder="•••• •••• •••• ••••"
      ccNumber
      [readonly]="saving$ | async"
    />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'billing.expires' | translate }}</mat-label>
    <input
      matInput
      id="cc-exp"
      (input)="goToNextField('expDate', cvcInput)"
      #expireInput
      formControlName="expDate"
      type="tel"
      class="cc-exp"
      autocomplete="cc-exp"
      placeholder="•• / ••"
      ccExp
      [readonly]="saving$ | async"
    />
  </mat-form-field>
  <div class="two-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'billing.cvv' | translate }}</mat-label>
      <input
        matInput
        id="cc-cvc"
        #cvcInput
        formControlName="cvc"
        type="tel"
        class="input-lg form-control cc-cvc"
        autocomplete="off"
        placeholder="•••"
        required
        ccCVC
        [readonly]="saving$ | async"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'shipping.zip' | translate }}</mat-label>
      <input
        matInput
        formControlName="zip"
        autocomplete="postal-code"
        [readonly]="saving$ | async"
        required
      />
    </mat-form-field>
  </div>
</form>
<div class="two-cols">
  <div></div>
  <div class="two-cols">
    <button mat-stroked-button mat-dialog-close>
      {{ 'general.cancel' | translate | uppercase }}
    </button>
    <button
      mat-raised-button
      color="primary"
      form="edit-card-form"
      [disabled]="!demoForm.valid || (saving$ | async)"
    >
      {{ 'general.save' | translate | uppercase }}
    </button>
  </div>
</div>
