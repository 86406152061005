<div class="max-width parent">
  <div class="block1">
    <a routerLink="/"><img class="logo" [src]="theme.image" /></a>
    <div class="links">
      <a
        class="primary-link no-decoration"
        routerLinkActive="underline"
        routerLink="/"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'nav.events' | translate }}</a
      >
      <a
        *ngIf="logged$ | async"
        class="primary-link no-decoration"
        routerLinkActive="underline"
        routerLink="/locker"
        >{{ 'nav.myLocker' | translate }}</a
      >
      <a
        class="primary-link no-decoration"
        routerLinkActive="underline"
        routerLink="/closed-events"
        >{{ 'nav.closedEvents' | translate }}</a
      >
      <a
        class="primary-link no-decoration"
        routerLinkActive="underline"
        routerLink="/help"
        >{{ 'nav.help' | translate }}</a
      >
      <a
        class="primary-link no-decoration"
        href="https://forms.gle/L5ptePk28aqNY98H8"
        target="_blank"
        >{{ 'nav.deleteMyAccount' | translate }}</a
      >
    </div>
    <div class="social">
      <a [href]="fbLink" target="_blank">
        <img src="/assets/images/facebook.svg" />
      </a>
      <!-- <a href="https://twitter.com/DASHauction" target="_blank">
        <img src="/assets/images/twitter.svg" class="twitter" />
      </a> -->
    </div>
  </div>
  <div class="block2">
    <span class="secondary-link">{{
      'general.copyright' | translate : { year: year }
    }}</span>
    <a
      class="secondary-link no-decoration"
      routerLinkActive="underline"
      routerLink="/privacy"
      >{{ 'nav.pp' | translate }}</a
    >
    <a
      class="secondary-link no-decoration"
      routerLinkActive="underline"
      routerLink="/terms"
      >{{ 'nav.tycLong' | translate }}</a
    >
    <a class="secondary-link no-decoration"> v{{ version }} </a>
  </div>
</div>
