import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { buildItemPath } from '../../../helpers/build-item-path';

export type NotificationsComponentData = {
  auctionItemId?: string;
  auctionId?: string;
  message: string;
  cta?: string;
};

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  message: string;
  cta = 'general.details';
  constructor(
    private snackBarRef: MatSnackBarRef<NotificationsComponent>,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationsComponentData
  ) {
    this.message = data.message;
    if (data.cta) this.cta = data.cta;
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }

  detail() {
    const { auctionItemId, auctionId } = this.data || {};
    const { routes } = buildItemPath(
      this.route.snapshot.data,
      this.route.snapshot.params
    );
    if (auctionItemId) {
      this.router.navigate([...routes, 'item', auctionItemId]);
    } else if (auctionId) {
      this.router.navigate([...routes, 'auction', auctionId]);
    }
    this.snackBarRef.dismiss();
  }
}
