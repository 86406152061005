<div
  class="content-section parent hover-color pointer"
  role="button"
  (click)="onClick()"
  [class.less]="ls.less"
  [class.wrap]="wrap"
>
  <div *ngIf="wrap" class="wrap-item">
    <image [src]="image" [overlayImage]="overlayImage"></image>
  </div>
  <div *ngIf="!wrap" class="asset">
    <image [src]="image" [overlayImage]="overlayImage"></image>
    <div class="chip" *ngIf="chip && !ls.less">
      <mat-chip-set>
        <mat-chip
          [ngClass]="{ warningChip: warningChip }"
          color="primary"
          selected
          >{{ chip | uppercase }}</mat-chip
        >
      </mat-chip-set>
    </div>
  </div>
  <div *ngIf="!wrap" class="body">
    <div class="content">
      <div class="mat-h5 title">
        <span class="main-title">
          {{ title }}
        </span>
        <span *ngIf="titleNote" class="title-note">
          {{ titleNote }}
        </span>
      </div>
      <div class="c-g7 mat-subtitle-1 description" *ngIf="!ls.less && teamName">
        {{ teamName }}
      </div>
      <div class="c-g7 mat-subtitle-1 description" *ngIf="!ls.less">
        {{ description }}
      </div>
    </div>
    <div class="footer" *ngIf="!ls.less">
      <div class="details">
        <ng-content></ng-content>
      </div>
      <div class="cta" *ngIf="cta">
        <button mat-flat-button color="primary">
          {{ cta }}
          <mat-icon iconPositionEnd class="right-icon pull-up">arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
