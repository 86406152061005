import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './content/content.component';
import { CardModule } from '../card/card.module';
import { LetDirective, PushPipe } from '@ngrx/component';
import { SectionComponent } from './section/section.component';
import { GalleryComponent } from './section/gallery/gallery.component';
import { StackedComponent } from './section/stacked/stacked.component';
import { PagedContentComponent } from './paged-content/paged-content.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RoundComponent } from './section/round/round.component';
import { ScrollRestoreComponent } from './content/scroll-restore/scroll-restore.component';
import { WrapComponent } from './section/wrap/wrap.component';

@NgModule({
  declarations: [
    ContentComponent,
    SectionComponent,
    GalleryComponent,
    StackedComponent,
    PagedContentComponent,
    RoundComponent,
    ScrollRestoreComponent,
    WrapComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    LetDirective, PushPipe,
    InfiniteScrollModule,
  ],
  exports: [ContentComponent, PagedContentComponent],
})
export class ContentModule {}
