import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Theme } from '../types/theme';
import { ApiService } from './api.service';
import { GetThemeParams } from './models/team.api';
import { DEFAULT_THEME } from '../config/themes';

export { DEFAULT_THEME } from '../config/themes';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private api: ApiService) {}
  private themes = {
    byTeam: new Map<string, Theme>(),
    byFoundation: new Map<string, Theme>(),
    byAuction: new Map<string, Theme>(),
  };
  public set route(value: ActivatedRouteSnapshot) {
    const { data, params, queryParams } = value;
    const themeFor = data?.themeFrom;
    if (themeFor === 'team' && params.team)
      this.updateParams({ team: params.team });
    else if (themeFor === 'auctionId' && params.id)
      this.updateParams({ auctionId: params.id });
    else if (queryParams?.fromSearch || !data?.keepTheme) this.updateParams();
  }
  private _theme = new BehaviorSubject(DEFAULT_THEME);
  public get theme() {
    return this._theme.asObservable();
  }

  private setTheme(value?: Theme) {
    this._theme.next(value ?? DEFAULT_THEME);
  }

  private updateParams(params?: GetThemeParams) {
    this.getTheme(params).subscribe((t) => this.setTheme(t));
  }

  getTheme(params?: GetThemeParams): Observable<Theme> {
    if (!params) return of(DEFAULT_THEME);
    const { cache, key } = this.getCache(params);
    const cachedTheme = cache.get(key);
    if (cachedTheme) return of(cachedTheme);
    return this.api.team.getTeam(params).pipe(
      map((team): Theme => {
        const theme: Theme = {
          backgroundColor: team.brandColor ?? DEFAULT_THEME.backgroundColor,
          // textColor: team.fontColor ?? DEFAULT_THEME.textColor,
          textColor: '#ffffff',
          image: team.imageUrl,
          headerBorderBottom: '0px',
          team: team.name,
          displayName: team.displayName,
        };
        cache.set(key, theme);
        return theme;
      }),
      catchError((e) => {
        console.log('Error getting theme', e);
        return of(DEFAULT_THEME);
      })
    );
  }
  private getCache(params: GetThemeParams) {
    if ('team' in params)
      return { cache: this.themes.byTeam, key: params.team };
    if ('foundationId' in params)
      return { cache: this.themes.byFoundation, key: params.foundationId };
    if ('auctionId' in params)
      return { cache: this.themes.byAuction, key: params.auctionId };
    throw new Error('Invalid parameters');
  }
}
