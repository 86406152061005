<div class="snack-container">
  <div class="title">
    {{ message }}
  </div>
  <div class="actions">
    <button mat-button (click)="dismiss()">
      {{ 'general.close' | translate }}
    </button>
    <button
      *ngIf="data?.auctionItemId || data?.auctionId"
      mat-button
      class="enable"
      (click)="detail()"
    >
      {{ cta | translate }}
    </button>
  </div>
</div>
