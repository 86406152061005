import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../store';
import { isLogged, selectProfile } from '../../store/user/user.selectors';
import { Theme } from '../../types/theme';
import { DEFAULT_THEME } from '../../config/themes';
@Component({
  selector: 'dash-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() theme?: Theme | null;
  logged$ = this.store.select(isLogged);
  defaultTheme = DEFAULT_THEME;
  profile$ = this.store.select(selectProfile);

  constructor(private store: Store<GlobalState>) {}

}
