import { Component, Inject } from '@angular/core';
import { Currency } from '../../../types';
import { GlobalState } from '../../../store';
import { Store } from '@ngrx/store';
import { selectCCardSaving } from '../../../store/ccard/ccard.selectors';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PaymentAddData {
  currency?: Currency;
  foundationId?: string;
}

@Component({
  selector: 'payment-dialog-add',
  templateUrl: './payment-dialog-add.component.html',
  styleUrls: ['./payment-dialog-add.component.scss'],
})
export class PaymentDialogAddComponent {
  saving$ = this.store.select(selectCCardSaving);
  currency?: Currency;
  foundationId?: string;

  constructor(
    private store: Store<GlobalState>,
    public dialogRef: MatDialogRef<PaymentDialogAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentAddData
  ) {
    this.currency = data.currency;
    this.foundationId = data.foundationId;
  }
}
