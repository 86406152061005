import { createAction, props } from '@ngrx/store';
import { Credentials, UserProfile, SignUpCredentials } from '../../types';
import { ChangePassword } from '../../types/change-password.model';

export const recoverAccessToken = createAction('[User] Recover Token');

export const saveAccessToken = createAction(
  '[User] Save Access Token',
  props<{ accessToken?: string }>()
);

export const login = createAction(
  '[User] Login',
  props<{ credentials: Credentials; redirectHome: boolean }>()
);

export const register = createAction(
  '[User] Register',
  props<{ credentials: SignUpCredentials; redirectHome: boolean }>()
);

export const loginFail = createAction('[User] Login Fail');

export const logout = createAction('[User] Logout');

export const loginSucceeded = createAction(
  '[User] Login Succeeded',
  props<{
    profile: UserProfile;
    isNewProfile: boolean;
    redirectHome: boolean;
  }>()
);

export const loadProfile = createAction(
  '[User] Load Profile',
  props<{ tokenId?: string }>()
);

export const setProfile = createAction(
  '[User] Set Profile',
  props<{ profile?: UserProfile }>()
);

export const sendProfile = createAction(
  '[User] Send Profile',
  props<{
    profile: Partial<UserProfile>;
    passwords?: ChangePassword;
  }>()
);

export const sendProfileFail = createAction('[User] Send Profile Fail');

export const pullOTItems = createAction('[User] Pull OT Items');


export const pullOTItemsSuccess = createAction(
  '[User] Pull OT Items Success',
  props<{
    otItems: string[];
  }>()
);
