import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { buildItemPath } from '../../../../helpers/build-item-path';
import { GlobalState } from '../../../../store';
import { processBid } from '../../../../store/bid/bid.actions';
import { selectBidProcessing } from '../../../../store/bid/bid.selectors';
import { selectUserState } from '../../../../store/user/user.selectors';
import {
  Auction,
  AuctionItem,
  CCard,
  Currency,
  UserProfile,
} from '../../../../types';
import { Foundation } from '../../../../types/foundation.model';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import {
  OnboardingDialogComponent,
  OnboardingDialogData,
} from '../onboarding-dialog/onboarding-dialog.component';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../../../analytics/analytics-events.enum';

@Component({
  selector: 'ot-bid',
  templateUrl: './ot-bid.component.html',
  styleUrls: ['./ot-bid.component.scss'],
})
export class OtBidComponent implements OnDestroy {
  currency?: Currency;
  foundation?: Foundation;
  nextBid?: number;
  subs: Subscription[] = [];
  profile?: UserProfile;
  processing$ = this.store.select(selectBidProcessing);
  inOvertime = false;
  placedBid = false;
  bid?: number;
  card?: CCard;
  editing = false;
  saving = false;

  private _item?: AuctionItem | undefined;
  public get item(): AuctionItem | undefined {
    return this._item;
  }
  @Input()
  public set item(value: AuctionItem | undefined) {
    this._item = value;
    this.calcNextBid();
    this.updateFlags();
  }

  private _auction?: Auction | undefined | null;
  public get auction(): Auction | undefined | null {
    return this._auction;
  }
  @Input()
  public set auction(value: Auction | undefined | null) {
    this._auction = value;
    this.currency = this.auction?.foundation?.currency ?? 'USD';
    this.foundation = this.auction?.foundation;
  }

  constructor(
    private store: Store<GlobalState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private trackAnalytics: TrackAnalyticsService
  ) {
    const subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) {
        this.profile = { ...profile };
        this.updateFlags();
      }
    });
    this.subs.push(subs);
  }

  private calcNextBid() {
    if (!this.item) {
      this.nextBid = 0;
      return;
    }
    this.nextBid = this.item.myCurrentBid ?? this.item.topBidAmount;
  }

  submit() {
    if (!this.currency || !this.bid || !this.item) return;
    this.trackAnalytics.bid(
      AnalyticsEvents.OvertimeBidStart,
      this.item.id,
      this.bid
    );
    if (!this.card) return this.notOnboarded();
    const { routes } = buildItemPath(
      this.route.snapshot.data,
      this.route.snapshot.params
    );
    this.store.dispatch(
      processBid({
        bid: {
          step: 'saving',
          amount: this.bid,
          currency: this.currency,
          item: this.item,
          itemPath: [...routes, 'item', this.item.id],
          type: 'ot-bid',
          card: this.card,
        },
      })
    );
    this.saving = true;
  }

  notOnboarded() {
    if (!this.currency) return;
    const dialogRef = this.dialog.open<
      OnboardingDialogComponent,
      OnboardingDialogData
    >(OnboardingDialogComponent, { data: { currency: this.currency } });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const data: ConfirmDialogData = {
        confirmKey: result.isNewProfile
          ? 'bid.newAccount'
          : 'bid.existingAccount',
        okKey: 'modal.ok',
        hideCancel: true,
        bodyKey: 'bid.goBid',
      };
      this.dialog.open(ConfirmDialogComponent, {
        data,
      });
      if (!this.card) this.card = result.card;
    });
  }

  private updateFlags() {
    this.inOvertime = (() => {
      if (!this.profile?.id) return false;
      if (!this.item?.overtimeUserProfileIds) return false;
      return this.item.overtimeUserProfileIds.includes(this.profile.id);
    })();
    this.placedBid = (() => {
      if (!this.item?.myCurrentBid) return false;
      if (!this.item?.topBidAmount) return false;
      return this.item.myCurrentBid > this.item.topBidAmount;
    })();
    if (this.editing && this.item?.myCurrentBid === this.bid && this.saving) {
      this.saving = false;
      this.editing = false;
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  openBid() {
    if (!this.item) return;
    this.bid = this.placedBid
      ? this.item.myCurrentBid
      : this.item.topBidAmount + this.item.increment;
    this.editing = true;
    this.saving = false;
  }
  cardSelected(card: CCard) {
    setTimeout(() => (this.card = card));
  }
}
