import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@fan-services/storage.service';
import { Observable } from 'rxjs';
import { accessTokenKey } from '../keys';
import { AnyObject } from '../types';

@Injectable()
export class AccessTokenInterceptorService implements HttpInterceptor {
  constructor(private storage: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let avoid = false;
    avoid = avoid || req.url.startsWith('https://maps.googleapis.com');
    if (!avoid) {
      const token = this.getToken();
      const headers: AnyObject = {};
      if (token && !req.headers.get('authorization')) {
        headers['Authorization'] = token;
      }
      req = req.clone({
        setHeaders: headers,
      });
    }
    return next.handle(req);
  }
  getToken() {
    return this.storage?.getAndParseItem(accessTokenKey);
  }
}
