import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GlobalState } from '../../../store';
import { selectServerDiffMs } from '../../../store/bid/bid.selectors';

@Component({
  selector: 'countdown',
  templateUrl: './countdown.component.html',
})
export class CountdownComponent implements OnDestroy {
  private diff = 0;
  private subs: Subscription[] = [];
  private _time?: number;
  private interval?: number;
  params?: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    showDays: boolean;
    showHours: boolean;
  };

  @Input()
  public set time(value: string) {
    const countdown = new Date(value);
    countdown.setMilliseconds(countdown.getMilliseconds() - this.diff);
    this._time = countdown.getTime();
    this._startTimer();
  }

  constructor(private store: Store<GlobalState>) {
    const subs = this.store.select(selectServerDiffMs).subscribe((diff) => {
      this.diff = diff;
    });
    this.subs.push(subs);
  }

  private delta() {
    const date = new Date();
    const time = this._time ?? date.getTime();
    return Math.max(0, Math.floor((time - date.getTime()) / 1000));
  }

  public calcTime() {
    const delta = this.delta();
    let left = delta;

    const days = Math.floor(left / 86400);
    left -= days * 86400;
    const hours = Math.floor(left / 3600) % 24;
    left -= hours * 3600;
    const minutes = Math.floor(left / 60) % 60;
    left -= minutes * 60;
    const seconds = left % 60;

    this.params = {
      days,
      hours,
      minutes,
      seconds,
      showDays: !!days,
      showHours: !!hours || !!days,
    };
    if (delta <= 0) this._stopTimer();
  }

  ngOnDestroy() {
    this._stopTimer();
    this.subs.forEach((s) => s?.unsubscribe());
  }

  private _startTimer() {
    if (this.delta() <= 0) return;
    this._stopTimer();
    this.calcTime();
    const interval = setInterval(() => this.calcTime(), 1000);
    this.interval = Number(interval);
  }

  private _stopTimer() {
    clearInterval(this.interval);
    this.interval = undefined;
  }
}
