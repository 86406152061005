import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { StorageService } from './storage.service';
import { of } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class FirebaseMessagingService {
  constructor(
    private afMessaging: AngularFireMessaging,
    private storage: StorageService,
    private api: ApiService
  ) {
    if (this.isSupported) {
      this.afMessaging.messages.subscribe((_messaging) => {
        console.log({ _messaging });
      });
    }
  }

  get isSupported() {
    if (!('serviceWorker' in navigator)) return false;
    if (!('PushManager' in window)) return false;
    if (!('Notification' in window)) return false;
    return true;
  }

  requestPermission() {
    if (!this.isSupported) return;
    const hasRequested = this.storage?.getItem(
      'hasRequestedNotificationPermission'
    );
    if (hasRequested) return;
    try {
      this.afMessaging.requestToken
        .subscribe(
          (token) => {
            if (!token) return;
            this.api.device
              .addDevice({
                app: 'dash',
                service: 'fcm',
                serviceId: token,
                platform: 'web',
              })
              .subscribe((res) => console.log(res));
          },
          (error) => {
            console.error(error);
            this.storage?.setItem('hasRequestedNotificationPermission', '1');
          }
        );
    } catch {
      // web push not supported
    }
  }

  deleteToken() {
    try {
      this.afMessaging.getToken
        .pipe(
          mergeMap((token) => {
            return typeof token === 'string'
              ? this.afMessaging.deleteToken(token)
              : of('');
          })
        )
        .subscribe(() => {
          console.log('Token deleted!');
        });
    } catch (e) {
      console.log(e);
    }
  }
}
