import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { CONTENT_LINKS } from '../../config/content-links';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  helpUrl?: SafeResourceUrl;

  constructor(
    // private userService: UserService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const lang: keyof typeof CONTENT_LINKS.help = this.translate
      .currentLang as any;
    const url = CONTENT_LINKS.help[lang] ?? CONTENT_LINKS.help.en;
    this.helpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
