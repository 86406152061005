import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import linkifyHtml from 'linkify-html';


@Component({
  selector: 'ui-linkified',
  templateUrl: './linkified.component.html',
  styleUrls: ['./linkified.component.scss'],
})
export class LinkifiedComponent {
  content?: SafeHtml;

  @Input()
  set text(text: string | undefined) {
    const html = linkifyHtml(text ?? '', {
      className: 'gray-7',
      target: '_blank'
    });
    this.content = this.sanitazer.bypassSecurityTrustHtml(html);
  }

  constructor(private sanitazer: DomSanitizer) {}
}
