import { UserProfile } from '../types';

const profileHasAddress = (profile: UserProfile | undefined) => {
  if (!profile) return false;
  const hasShipping =
    !!profile.shipAddress1 ||
    !!profile.shipCity ||
    !!profile.shipState ||
    !!profile.shipZip;
  return hasShipping;
};

export default profileHasAddress;
