import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { buildItemPath } from '../../../../helpers/build-item-path';
import { GlobalState } from '../../../../store';
import { processBid } from '../../../../store/bid/bid.actions';
import {
  selectBidCompleted,
  selectBidProcessing,
} from '../../../../store/bid/bid.selectors';
import { selectUserState } from '../../../../store/user/user.selectors';
import {
  Auction,
  CCard,
  Currency,
  DonationItem,
  UserProfile,
} from '../../../../types';
import { Foundation } from '../../../../types/foundation.model';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import {
  OnboardingDialogComponent,
  OnboardingDialogData,
} from '../onboarding-dialog/onboarding-dialog.component';
import profileHasAddress from '../../../../helpers/profile-has-address';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../../../analytics/analytics-events.enum';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss'],
})
export class DonationComponent implements OnDestroy {
  currency?: Currency;
  foundation?: Foundation;
  donationAmount?: number;
  subs: Subscription[] = [];
  profile?: UserProfile;
  card?: CCard;
  processing$ = this.store.select(selectBidProcessing);
  paying = false;
  needPayment = false;

  @Input() item?: DonationItem;

  private _auction?: Auction | undefined | null;
  public get auction(): Auction | undefined | null {
    return this._auction;
  }
  @Input()
  public set auction(value: Auction | undefined | null) {
    this._auction = value;
    this.currency = this.auction?.foundation?.currency ?? 'USD';
    this.foundation = this.auction?.foundation;
  }

  constructor(
    private store: Store<GlobalState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private trackAnalytics: TrackAnalyticsService,
    private api: ApiService
  ) {
    const subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) {
        this.profile = { ...profile };
      }
    });
    this.subs.push(subs);
    const donationCompleted = this.store
      .select(selectBidCompleted)
      .subscribe((completed) => {
        if (completed) {
          this.donationAmount = undefined;
        }
      });
    this.subs.push(donationCompleted);
  }

  notOnboarded() {
    if (!this.currency) return;
    const dialogRef = this.dialog.open<
      OnboardingDialogComponent,
      OnboardingDialogData
    >(OnboardingDialogComponent, {
      data: { currency: this.currency, foundationId: this.foundation?.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const data: ConfirmDialogData = {
        confirmKey: result.isNewProfile
          ? 'bid.newAccount'
          : 'bid.existingAccount',
        okKey: 'modal.ok',
        hideCancel: true,
        bodyKey: 'bid.goBid',
      };
      this.dialog.open(ConfirmDialogComponent, {
        data,
      });
      if (!this.card) this.card = result.card;
    });
  }

  submit() {
    if (!this.currency || !this.donationAmount || !this.item?.id) return;
    this.trackAnalytics.donate(
      AnalyticsEvents.StartDonate,
      this.item.id,
      Number(this.donationAmount)
    );
    if (!this.card || !profileHasAddress(this.profile))
      return this.notOnboarded();
    const { routes } = buildItemPath(
      this.route.snapshot.data,
      this.route.snapshot.params
    );

    this.store.dispatch(
      processBid({
        bid: {
          step: 'saving',
          amount: Number(this.donationAmount),
          currency: this.currency,
          item: this.item,
          itemPath: [...routes, 'item', this.item.id],
          type: 'purchase',
          card: this.card,
        },
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
  cardSelected(card: CCard) {
    setTimeout(() => (this.card = card));
  }
}
