import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FulfillmentOptionType } from '@fan-types/fulfillment-option';
import { AuctionItem } from '@fan-types/item.model';

export interface BidConfirmDialogData {
  bid: number;
  item: AuctionItem;
}

@Component({
  selector: 'bid-confirm-dialog',
  templateUrl: './bid-confirm-dialog.component.html',
  styleUrls: ['./bid-confirm-dialog.component.scss']
})
export class BidConfirmDialogComponent {

  bid: number;
  item: AuctionItem;
  pickupAvailable = false;
  shippingAvailable = false;


  constructor(
    public dialogRef: MatDialogRef<BidConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: BidConfirmDialogData,
  ) {
    this.bid = dialogData.bid;
    this.item = dialogData.item;
    this.pickupAvailable = !!this.item.fulfillmentOptions?.find(option => option.type === FulfillmentOptionType.pickup);
    this.shippingAvailable = !!this.item.fulfillmentOptions?.find(option => option.type === FulfillmentOptionType.shipping);
  }

  cancel() {
    this.dialogRef.close();
  }

  bidConfirmed() {
    this.dialogRef.close(this.bid);
  }
}
