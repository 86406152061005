import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey
);

export const selectUserProcessing = createSelector(
  selectUserState,
  (state) => state.processing
);

export const isLogged = createSelector(
  selectUserState,
  (state) => !!state.profile?.id
);

export const selectProfile = createSelector(
  selectUserState,
  (state) => state.profile
);

export const selectOtItems = createSelector(
  selectUserState,
  (state) => state.otItems
);
