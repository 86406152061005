import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuctionItemApi } from '@fan-services/models/auction-item.api';
import { AuctionApi } from '@fan-services/models/auction.api';
import { BidsApi } from '@fan-services/models/bid.api';
import { ContentApi } from '@fan-services/models/content.api';
import { CardApi } from './models/card.api';
import { DeviceApi } from './models/device.api';
import { FavesApi } from './models/fave.api';
import { TeamApi } from './models/team.api';
import { UsersApi } from './models/user.api';
import { TeamRequestApi } from './models/team-request.api';

@Injectable({ providedIn: 'root' })
export class ApiService {
  auction = new AuctionApi(this.http);
  auctionItem = new AuctionItemApi(this.http);
  bid = new BidsApi(this.http);
  content = new ContentApi(this.http);
  user = new UsersApi(this.http);
  card = new CardApi(this.http);
  fave = new FavesApi(this.http);
  team = new TeamApi(this.http);
  device = new DeviceApi(this.http);
  teamRequest = new TeamRequestApi(this.http);

  constructor(private http: HttpClient) {}
}
