import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { version } from '../../../version';
import { GlobalState } from '../../store';
import { isLogged } from '../../store/user/user.selectors';
import { DEFAULT_THEME } from '../../services/theme.service';
import { SOCIALS } from '../../config/socials';

@Component({
  selector: 'dash-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  logged$ = this.store.select(isLogged);
  version = version;
  theme = DEFAULT_THEME;
  fbLink = SOCIALS.facebook;
  year = new Date().getFullYear();
  constructor(private store: Store<GlobalState>) {}
}
