import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Fave } from '../../../types/fave';

@Component({
  selector: 'fave',
  templateUrl: './fave.component.html',
  styleUrls: ['./fave.component.scss'],
})
export class FaveComponent {
  @Input() fave?: Fave;
  @Output() favedChanged = new EventEmitter<boolean>();
}
