import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';

import {
  catchError,
  map,
  mergeMap,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';

import { ApiService } from '../../services/api.service';
import { AnyObject } from '../../types';
import { GlobalState } from '../state';
import { selectUserState } from '../user/user.selectors';
import {
  addCCard,
  addCCardFailed,
  addCCardStart,
  addTokenizedCCardStart,
  deleteCCard,
  editCCardStart,
  loadCCards,
  pullCCards,
  requestDeleteCCard,
  upsertCCard,
} from './ccard.actions';

@Injectable()
export class CCardEffects {
  pullCCards$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pullCCards),
      withLatestFrom(this.store.select(selectUserState)),
      mergeMap(([{ currency, foundationId }, { profile }]) => {
        if (!profile?.id) return throwError('Unauthorized');
        return this.api.card
          .getCards(profile?.id, currency, foundationId)
          .pipe(map((cCards) => loadCCards({ cCards })));
      })
    );
  });

  deleteCCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestDeleteCCard),
      withLatestFrom(this.store.select(selectUserState)),
      mergeMap(([{ currency, cardId }, { profile }]) => {
        if (!profile?.id) return throwError('Unauthorized');
        return this.api.card
          .deleteCard(profile.id, cardId, currency)
          .pipe(map(() => deleteCCard({ id: cardId })));
      })
    );
  });

  addCCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addCCardStart),
      withLatestFrom(this.store.select(selectUserState)),
      mergeMap(([{ ccard, onCardAdded }, { profile }]) => {
        if (!profile?.id) return throwError('Unauthorized');
        return this.api.card.addCreditCard(profile.id, ccard).pipe(
          map((cCard) => addCCard({ cCard })),
          tap((cCard) => onCardAdded?.(cCard.cCard)),
          catchError((error) => this.cardError(error))
        );
      })
    );
  });

  addTokenizedCCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addTokenizedCCardStart),
      withLatestFrom(this.store.select(selectUserState)),
      // omit duplicated events (thanks Deluxe for the double event)
      throttleTime(200),
      mergeMap(([{ ccard, onCardAdded }, { profile }]) => {
        if (!profile?.id) return throwError('Unauthorized');
        return this.api.card.addTokenizedCreditCard(profile.id, ccard).pipe(
          map((cCard) => addCCard({ cCard })),
          tap((cCard) => onCardAdded?.(cCard.cCard)),
          catchError((error) => this.cardError(error))
        );
      })
    );
  });

  editCCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editCCardStart),
      withLatestFrom(this.store.select(selectUserState)),
      mergeMap(([{ ccard, onCardEdited, cardId }, { profile }]) => {
        if (!profile?.id) return throwError('Unauthorized');
        return this.api.card.editCard(profile.id, cardId, ccard).pipe(
          map((cCard) => upsertCCard({ cCard })),
          tap(() => onCardEdited?.()),
          catchError((error) => this.cardError(error))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<GlobalState>,
    private readonly api: ApiService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  private cardError(error: AnyObject) {
    return this.translateService.get('modal.cardError').pipe(
      map((copy) => {
        const message =
          error?.status === 401 ? copy : error?.error?.error?.message ?? copy;
        this.snackBar.open(message);
        return addCCardFailed();
      })
    );
  }
}
