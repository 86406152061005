<card-layout
  [title]="item?.title"
  [description]="item?.content"
  [cta]="'general.details' | translate"
  [chip]="'event.giveaway' | translate"
  [image]="image"
  [path]="['item', item?.id || '']"
  (clicked)="onClick()"
  [teamName]="item?.foundation?.name"
>
  <event-countdown [auctionId]="item?.auctionId"></event-countdown>
</card-layout>
