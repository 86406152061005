import { createAction, props } from '@ngrx/store';
import { BidAttempt } from '../../types';

export const processBid = createAction(
  '[Bid/API] Process Bid',
  props<{ bid: BidAttempt }>()
);

export const processBidSucceeded = createAction(
  '[Bid/API] Process Bid Succeeded',
  props<{ bid: BidAttempt }>()
);

export const processBidFailed = createAction(
  '[Bid/API] Process Bid Failed',
  props<{ bid: BidAttempt }>()
);

export const updateServerDiffMs = createAction(
  '[Bid/API] Update Server Diff Ms'
);

export const updateServerDiffMsSuccess = createAction(
  '[Bid/API] Update Server Diff Ms Update',
  props<{ diff: number }>()
);
