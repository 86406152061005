import { Component, Input } from '@angular/core';
import { Auction, Item } from '../../../types';

@Component({
  selector: 'description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent {
  expanded = true;
  @Input() item?: Item;
  @Input() auction?: Auction | null;
}
