<ng-container *ngrxLet="content$ as content">
  <div
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll(content?.hasMore)"
  >
    <dash-section
      *ngFor="let section of content?.sections"
      [section]="section"
    ></dash-section>
  </div>
</ng-container>
