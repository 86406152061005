<div class="form-page">
  <div class="form-header">
    <a class="title active">{{ 'auth.signUp' | translate }}</a>
    <div class="subtitle">{{ 'auth.signUpHint' | translate }}</div>
  </div>
  <form #loginData="ngForm" class="form-body" (ngSubmit)="submit()">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.email' | translate }}</mat-label>
      <input
        matInput
        type="email"
        autocomplete="email"
        [(ngModel)]="credentials.email"
        name="user"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'auth.pwd' | translate }}</mat-label>
      <input
        matInput
        type="password"
        [(ngModel)]="credentials.password"
        name="password"
        autocomplete="new-password"
        [readonly]="processing$ | async"
        required
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.firstName' | translate }}</mat-label>
      <input
        matInput
        autocomplete="given-name"
        [(ngModel)]="credentials.firstName"
        name="firstName"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.lastName' | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="credentials.lastName"
        autocomplete="family-name"
        name="lastName"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.phoneNumber' | translate }}</mat-label>
      <input
        matInput
        type="phone"
        autocomplete="tel"
        [(ngModel)]="credentials.contactNumber"
        name="phoneNumber"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <div class="mb">
      <mat-checkbox name="tos" [(ngModel)]="checked"
        ><div
          class="disclaimer"
          [innerHTML]="'auth.disclaimer' | translate"
        ></div
      ></mat-checkbox>
    </div>
    <button
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="(processing$ | async) || !loginData.valid || !checked"
    >
      {{ 'auth.signUp' | translate }}
    </button>

    <div class="mb">
      <span>{{ 'auth.goLogIn' | translate }}</span>
    </div>
    <button mat-raised-button (click)="loginClick(); (false)" class="mb">
      {{ 'auth.logIn' | translate | uppercase }}
    </button>
    <ng-content></ng-content>
  </form>
</div>
