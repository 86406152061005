<h5 mat-dialog-title>
  {{ 'billing.addCard' | translate }}
</h5>
<mat-dialog-content class="mat-typography">
  <payment-form-selector
    [currency]="currency"
    [foundationId]="foundationId"
    (cardAdded)="dialogRef.close()"
  ></payment-form-selector>
</mat-dialog-content>
