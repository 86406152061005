import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OvertimeComponent } from './overtime.component';
import { SharedModule } from '../shared.module';
import { OtModalComponent } from './ot-modal/ot-modal.component';

@NgModule({
  declarations: [OvertimeComponent, OtModalComponent],
  imports: [CommonModule, SharedModule],
  exports: [
    OvertimeComponent,
  ],
})
export class OvertimeModule {}
