import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from 'keen-slider';

function ThumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active');
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active');
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          main.moveToIdx(idx);
        });
      });
    }

    slider.on('created', () => {
      addActive(slider.track.details.rel);
      addClickEvents();
      main.on('animationStarted', (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(next);
      });
    });
  };
}

@Component({
  selector: 'keen-instance',
  templateUrl: './keen-instance.component.html',
  styleUrls: ['./keen-instance.component.scss'],
})
export class KeenInstanceComponent implements AfterViewInit, OnDestroy {

  @Input() images: string[] = [];
  @Input() overlayImage?: string;

  @ViewChild('sliderRef') sliderRef?: ElementRef<HTMLElement>;
  @ViewChild('thumbnailRef') thumbnailRef?: ElementRef<HTMLElement>;
  slider?: KeenSliderInstance;
  thumbnailSlider?: KeenSliderInstance;
  currentSlide = 0;

  ngAfterViewInit() {
    if (!this.sliderRef) return;
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: false,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
      },
    });
    setTimeout(() => {
      if (!this.thumbnailRef || !this.slider) return;
      this.slider.update();
      this.thumbnailSlider = new KeenSlider(
        this.thumbnailRef.nativeElement,
        {
          initial: 0,
          slides: {
            perView: 3,
            spacing: 16,
          },
        },
        [ThumbnailPlugin(this.slider ?? {})]
      );
    }, 150);


  }

  // ngAfterViewChecked(): void {
  //   // console.log('Enter on view checked');
  //   // if (this.slider) {
  //   //   this.slider.update();
  //   // }
  // }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
    if (this.thumbnailSlider) this.thumbnailSlider.destroy();
  }

  trackImageBy(index: number, el: string) {
    return el;
  }
}
