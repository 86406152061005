import { createAction, props } from '@ngrx/store';
import { MyBid } from '../../types';

export const pullLocker = createAction('[Locker/API] Pull Locker');

export const pullLockerSucceeded = createAction(
  '[Locker/API] Pull Locker Succeeded',
  props<{ myBids: MyBid[] }>()
);

export const pullLockerFailed = createAction('[Locker/API] Pull Locker Failed');
