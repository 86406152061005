<div
  class="ot-container"
  [style.backgroundColor]="(themeService.theme | async)?.backgroundColor"
  *ngIf="!otOnboarded || otItems.length"
>
  <div class="max-width">
    <img class="ot-image" src="assets/ot/ico_overtime.png" />
    <div class="announcement">
      <label class="title">
        {{
          (otItems.length ? 'event.inOt' : 'overtime.title') | translate
        }}</label
      ><br />
      <label>
        {{ 'overtime.desc' | translate }}
      </label>
    </div>
    <div class="button-container">
      <button mat-flat-button color="primary" (click)="cta()">
        {{ 'general.view' | translate | uppercase }}
        <mat-icon iconPositionEnd class="right-icon pull-up">arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</div>
