import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Auction,
  ContentItem,
  ContentItemTeam,
  ContentItemWeb,
  Item,
} from '../../../types';
import { LayoutTypeService } from '../layout-type.service';

@Component({
  selector: 'card-stacked',
  templateUrl: './card-stacked.component.html',
  styleUrls: ['./card-stacked.component.scss'],
  viewProviders: [LayoutTypeService],
})
export class CardStackedComponent {
  @Output() eventClicked = new EventEmitter<Auction>();
  @Output() itemClicked = new EventEmitter<Item>();
  @Output() teamClicked = new EventEmitter<ContentItemTeam>();
  @Output() webClicked = new EventEmitter<ContentItemWeb>();
  constructor(private layoutTypeService: LayoutTypeService) {}

  @Input()
  set less(value: boolean) {
    if (value) this.layoutTypeService.less = true;
  }

  @Input() item?: ContentItem;
  get web() {
    return this.item as ContentItemWeb;
  }
  get team() {
    return this.item as ContentItemTeam;
  }
}
