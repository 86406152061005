import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ContentItemWeb } from '../../../types';

@Component({
  selector: 'card-link',
  templateUrl: './card-link.component.html',
  styleUrls: ['./card-link.component.scss'],
})
export class CardLinkComponent {
  @Output() clicked = new EventEmitter<ContentItemWeb>();
  src?: string;
  _item?: ContentItemWeb;

  @Input()
  set item(item: ContentItemWeb | undefined) {
    if (!item) return;
    this._item = item;
    this.src = `${environment.assetsUrl}/${
      item.assetUrl ?? item.desktopAssetUrl
    }`;
  }

  get item() {
    return this._item;
  }

  onClick() {
    if (this.item) this.clicked.emit(this.item);
    const urlRef = (/^(https?)/i).test(this.item?.itemRef ?? '') ? this.item?.itemRef : `http://${this.item?.itemRef}`;
    window.open(urlRef, '_blank');
  }
}
