import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../store';
import { pullLocker } from '../../store/locker/locker.actions';
import { selectMyLocker } from '../../store/locker/locker.selectors';

@Component({
  selector: 'locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss'],
})
export class LockerComponent {
  myBids$ = this.store.select(selectMyLocker);

  constructor(private store: Store<GlobalState>) {
    this.store.dispatch(pullLocker());
  }
}
