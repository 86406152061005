<div class="max-width">
  <mat-toolbar [style.backgroundColor]="theme?.backgroundColor">
    <a
      class="contents"
      [routerLink]="theme?.team ? ['/team', theme?.team] : ['/']"
      ><img
        [src]="theme?.image || defaultTheme.image"
        class="header-logo"
    /></a>
    <div class="separator"></div>
    <app-nav-search-input [theme]="theme" [profile]="profile$ | async"></app-nav-search-input>
    <button
      mat-flat-button
      class="nav-button"
      routerLink="/"
      routerLinkActive="active"
      [style.backgroundColor]="theme?.backgroundColor"
      [routerLinkActiveOptions]="{ exact: true }"
      [style.color]="theme?.textColor"
    >
      <mat-icon class="left-icon">calendar_today_outlined</mat-icon>
      <span class="mobile-hide">
        {{ 'nav.events' | translate }}
      </span>
    </button>
    <button
      *ngIf="logged$ | async"
      mat-flat-button
      class="nav-button"
      routerLink="/locker"
      routerLinkActive="active"
      [style.backgroundColor]="theme?.backgroundColor"
      [style.color]="theme?.textColor"
    >
      <mat-icon class="left-icon">inbox_outlined</mat-icon>
      <span class="mobile-hide">
        {{ 'nav.myLocker' | translate }}
      </span>
    </button>
    <button
      *ngIf="logged$ | async"
      mat-flat-button
      class="nav-button"
      routerLink="/profile"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: false }"
      [style.backgroundColor]="theme?.backgroundColor"
      [style.color]="theme?.textColor"
    >
      <mat-icon class="left-icon">person_outlined</mat-icon>
      <span class="mobile-hide">{{ 'nav.profile' | translate }}</span>
    </button>
    <button
      *ngIf="(logged$ | async) === false"
      mat-flat-button
      class="nav-button"
      routerLink="/login"
      routerLinkActive="active"
      [style.backgroundColor]="theme?.backgroundColor"
      [style.color]="theme?.textColor"
    >
      <mat-icon class="left-icon">login_outlined</mat-icon>
      {{ 'auth.logIn' | translate }}
    </button>
  </mat-toolbar>
</div>
