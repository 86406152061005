import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { CCard, Currency, UserProfile } from '../../../types';
import { GlobalState } from '../../../store';
import { Store } from '@ngrx/store';
import { addTokenizedCCardStart } from '../../../store/ccard/ccard.actions';
import { ApiService } from '../../../services/api.service';
import { selectUserState } from '../../../store/user/user.selectors';
import { BaseComponent } from '@dash-nx/ui-components';
import {
  TxnSuccessData,
  type EmbeddedPayments as EmbeddedPaymentsType,
} from './deluxe-embedded-payments';
import { environment } from '../../../../environments/environment';

declare const EmbeddedPayments: EmbeddedPaymentsType;

@Component({
  selector: 'payment-form-deluxe',
  templateUrl: './payment-form-deluxe.component.html',
  styleUrls: ['./payment-form-deluxe.component.scss'],
})
export class PaymentFormDeluxeComponent
  extends BaseComponent
  implements OnInit
{
  @Input() currency?: Currency;
  @Input() foundationId?: string;
  @Input() title?: string;
  @Output() cardAdded = new EventEmitter<CCard>();
  profile?: UserProfile;
  status = signal<'loading' | 'ready' | 'error' | 'authorizing' | 'saving'>(
    'loading'
  );
  jwt = '';

  constructor(private store: Store<GlobalState>, private api: ApiService) {
    super();
    const subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) {
        this.profile = { ...profile };
      }
    });
    this.subscriptions.push(subs);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    if (!this.profile) return;
    this.status.set('loading');
    forkJoin([
      this.initDeluxeScript(),
      this.api.card.generateAuthorizationJwt(
        this.profile.id,
        this.foundationId,
        this.currency
      ),
    ]).subscribe({
      next: ([resultOne, { jwt }]) => {
        this.jwt = jwt;
        this.initEmbeddedPayments(jwt);
      },
      error: (error) => {
        console.error('Error:', error);
        this.status.set('error');
      },
    });
  }

  private initDeluxeScript() {
    if (typeof EmbeddedPayments !== 'undefined') return of({});

    // Temporary script URL for testing
    const deluxeScript = environment.production
      ? 'https://payments.deluxe.com/embedded/javascripts/deluxe.js'
      : 'https://payments.deluxe.com/embedded/javascripts/deluxe.js';
    // const deluxeScript = environment.production
    //   ? 'https://payments.deluxe.com/embedded/javascripts/deluxe.js'
    //   : 'https://payments2.deluxe.com/embedded/javascripts/deluxe.js';

    return new Observable((observer) => {
      const script = document.createElement('script');
      script.src = deluxeScript;
      script.onload = () => {
        // Script loaded successfully
        if (EmbeddedPayments) {
          observer.next(undefined);
          observer.complete();
        } else {
          observer.error('Failed to load EmbeddedPayments after script load');
        }
      };
      script.onerror = () => {
        observer.error('Script load error');
      };
      document.head.appendChild(script);
    });
  }

  private async initEmbeddedPayments(jwt: string) {
    try {
      const instance = await EmbeddedPayments.init(jwt, {
        countryCode: this.currency === 'CAD' ? 'CA' : 'US',
        currencyCode: this.currency ?? 'USD',
        allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        supportedNetworks: ['visa', 'masterCard', 'amex'],
        skipCompleteAuthorization: true,
      });
      console.log('EmbeddedPayments ready!!');
      this.status.set('ready');

      instance
        .setEventHandlers({
          onTxnSuccess: (gateway, data) => {
            console.log(
              `${gateway} Transaction Succeeded: ${JSON.stringify(data)}`
            );
            this.status.set('saving');
            this.submitTokenizedCc(data);
          },
          onTxnFailed: (gateway, data) => {
            this.status.set('error');
            console.log(
              `${gateway} Transaction Failed: ${JSON.stringify(data)}`
            );
          },
          onValidationError: (gateway, errors) => {
            this.status.set('ready');
            console.log(`Validation Error: ${JSON.stringify(errors)}`);
          },
          onCancel: (gateway) => {
            console.log(`${gateway} transaction cancelled`);
          },
          onTxnAuthorized: (gateway, data) => {
            console.log(
              `${gateway} Transaction Authorized: ${JSON.stringify(data)}`
            );
            this.status.set('saving');
            this.submitTokenizedCc(data);
          },
        })
        .render({
          containerId: 'embeddedpayments',
        });
    } catch (error) {
      console.error('Error:', error);
      this.status.set('error');
    }
  }

  public onSubmit() {
    if (this.status() !== 'ready') {
      return;
    }
    EmbeddedPayments.pay(this.jwt);
    this.status.set('authorizing');
  }

  public submitTokenizedCc(data: TxnSuccessData) {
    this.store.dispatch(
      addTokenizedCCardStart({
        ccard: {
          currency: this.currency ?? 'USD',
          foundationId: this.foundationId,
          processor: 'deluxe',
          jwt: data.jwt,
        },
        onCardAdded: (ccard) => this.cardAdded.emit(ccard),
      })
    );
  }
}
