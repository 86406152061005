import { environment } from '../../environments/environment';

const appId = environment.appId;
// TODO: Migrate to remote config
export const CONTENT_LINKS =
  !appId || appId === 'dash'
    ? {
        privacy: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vSFZj7IJdOtO9YLO0D__QpPoTGc0zSKTYeKcLqFVoKM3HS9HtncVCxwo86VZmrcb4nz4AHiCeUDXauo/pub?embedded=true',
          fr: 'https://docs.google.com/document/d/e/2PACX-1vSE8MFy1ykag8PDEXQ--pTBC1UPfvoHrQiKtf8glwS50FeKxhg0BcKgPW47kTCiCrX1GcX7sQbtti74/pub?embedded=true',
        },
        help: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vTYWiE5KNhkjCk9BupLV6YsodKr5LRCxVKzQXa0YpFZzn7mVUIYDfT6sYwE-Eg8SNIy4TfF8O8lfg6s/pub?embedded=true',
          fr: '"https://docs.google.com/document/d/e/2PACX-1vRFVimUJmi5mrEWlIpbArufCdlUIrGxSegnnK8U3go2cH8USLjTOCu_BCO_BpBN2bsgskMVecezPGGg/pub?embedded=true',
        },
        terms: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vQRw3uZiVk4v0YhX4B0L9JMPc0geLOsqAoLgAeEqC581zgE-XW450z4cpbBXmrxlZRTL2iUm6j-dLPB/pub?embedded=true',
          fr: 'https://docs.google.com/document/d/e/2PACX-1vTNlvnMD6MiEElSuccbBgt1D4Z9uwfmjmx_4YzUalTxabXxR2uFPuZs_jfvsxK-AyPe4BN_uE9p4ly4/pub?embedded=true',
        },
        about: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vRfpEZ11xwUUiMl067tvy7gOgtaQ6L9gK9Fad9bNkS9MThRAhFXiTMqWjs8yr4wih_zQtmF8V7mevAK/pub?embedded=true',
          fr: 'https://docs.google.com/document/d/e/2PACX-1vR_4IvP7iu5vbEFddI-xq2R8gEXaMWnpoI9bi9gkY1jo2aAHde04GOrq8v3TgCjj2UczFNpkDfNLPs3/pub?embedded=true',
        },
      }
    : {
        privacy: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vR-yhnGXXEg2HA3-_zRaamPVgRCSxOLm2XMe2YL3LA94_4hCR2Bvp2Xe0Brxd524KvLWtHvW1krx1cg/pub?embedded=true',
          fr: 'https://docs.google.com/document/d/e/2PACX-1vTrolIFfRxhVxFN6letNWTjzkImX9ckFZG5ODORGwUAXisj0Q3S48ZfHdz10CM_W3q_4WGT7lJlwuFm/pub?embedded=true',
        },
        help: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vQnABH4tzDP21wMPB709yFIXnptTUKn788IpKRQDmpbOOdZIvOn91QJ9LpJDA0dSlPbIksBnI-r9a4p/pub?embedded=true',
          fr: 'https://docs.google.com/document/d/e/2PACX-1vSxvQShWPq05V8cOuULZmVxfLxnS6gBNOUgN7VyO-Au9vbtfERjDFD_e13XWIN8nmVLhFzvBJJdfUlO/pub?embedded=true',
        },
        terms: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vSZMD5xGa4PmWTou9RzIX9XW1EEh0nF9HWDP6ik-nskPHywN1v-KmNGfirao-OwenzaQgEaJFFBzgIM/pub?embedded=true',
          fr: 'https://docs.google.com/document/d/e/2PACX-1vTmFiZvPix20K7SczAY9ZMxS0SCoWa6xxQXJiK_C1jACG_slesbg3cGGJbVzUiNat02uSeNCpPFv826/pub?embedded=true',
        },
        about: {
          en: 'https://docs.google.com/document/d/e/2PACX-1vQ-usmYm_lWfXpsQp8h9R7y5iqlZ2f_KIaaTPZ3YNuPeTLeM-3A34jf3-k3eAudTTNU2SS2sEt4yaFr/pub?embedded=true',
          fr: 'https://docs.google.com/document/d/e/2PACX-1vRlfAte7hWjzPpG51a8HZALk9z4zPcrd-lftg7lrC3xhw1iu_cVWxnWyXV2hQ50vs1CV_baB7KelBi8/pub?embedded=true',
        },
      };
