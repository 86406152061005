import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Currency } from '@fan-types/currency';
import { FulfillmentOption } from '@fan-types/fulfillment-option';
import { AuctionItem } from '@fan-types/item.model';
import { ProfileAddress } from '@fan-types/user-profile';

export interface BidBuynowFulfillmentMethodDialogData {
  item: AuctionItem;
  currency?: Currency
}

@Component({
  selector: 'bid-buynow-fulfillment-method-dialog',
  templateUrl: './bid-buynow-fulfillment-method-dialog.component.html',
  styleUrls: ['./bid-buynow-fulfillment-method-dialog.component.scss']
})
export class BidBuynowFulfillmentMethodDialogComponent {
    item: AuctionItem;
    currency?: Currency;

  constructor(
    public dialogRef: MatDialogRef<BidBuynowFulfillmentMethodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: BidBuynowFulfillmentMethodDialogData,
  ) {
    this.item = dialogData.item;
    this.currency = dialogData.currency;
  }

  confirmFulfillmentMethod(event: {option: Partial<FulfillmentOption>, address?: ProfileAddress}) {
    this.dialogRef.close({method: {fulfillmentOption: event.option, shipAddress: event.address}});
  }

  fulfillmentOptionState(event: string) {
    if (event === 'cancel') {
      this.dialogRef.close('closed');
    }
  }


}
