<form #profileData="ngForm" (ngSubmit)="submit()" *ngIf="profile">
  <div class="mat-subtitle-1 mb">
    {{ 'account.contactInfo' | translate | uppercase }}
  </div>
  <div class="two-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.firstName' | translate }}</mat-label>
      <input
        matInput
        type="text"
        autocomplete="given-name"
        [(ngModel)]="profile.firstName"
        name="firstName"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.lastName' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="profile.lastName"
        autocomplete="family-name"
        name="lastName"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.email' | translate }}</mat-label>
      <input
        matInput
        type="email"
        [(ngModel)]="profile.email"
        autocomplete="email"
        name="email"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.phoneNumber' | translate }}</mat-label>
      <input
        matInput
        type="tel"
        [(ngModel)]="profile.contactNumber"
        name="contactNumber"
        autocomplete="tel"
        required
        [readonly]="processing$ | async"
      />
    </mat-form-field>
  </div>
  <div class="mat-subtitle-1 mb">
    {{ 'modal.changePwd' | translate | uppercase }}
  </div>
  <div class="two-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.oldPwd' | translate }}</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        [(ngModel)]="passwords.old"
        name="password"
        autocomplete="current-password"
        [readonly]="processing$ | async"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide; (false)"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="two-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.newPwd' | translate }}</mat-label>
      <input
        matInput
        type="password"
        [(ngModel)]="passwords.newP"
        name="newPassword"
        autocomplete="new-password"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'account.confirmPwd' | translate }}</mat-label>
      <input
        matInput
        type="password"
        [(ngModel)]="passwords.confirm"
        name="confirmPwd"
        autocomplete="new-password"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
  </div>
  <div class="two-cols">
    <button
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="
        (processing$ | async) || !profileData.valid || !isPasswordCorrect
      "
    >
      {{ 'account.update' | translate }}
    </button>
  </div>
  <div class="two-cols">
    <button mat-stroked-button class="mb" (click)="logout(); (false)">
      {{ 'account.logOut' | translate | uppercase }}
    </button>
  </div>
</form>
