<lib-loading [visible]="loading"></lib-loading>
<div class="mat-subtitle-1 mb">
  {{ 'fave.hint' | translate }}
</div>
<mat-form-field appearance="outline">
  <mat-icon matPrefix class="icon">search</mat-icon>
  <mat-label>{{ 'fave.search' | translate }}</mat-label>
  <input
    matInput
    type="text"
    [(ngModel)]="q"
    name="query"
    (ngModelChange)="search$.next($event)"
  />
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-select (ngModelChange)="selectSport($event)" [(ngModel)]="sport">
    <mat-option value="my">Favorites</mat-option>
    <mat-option *ngFor="let sport of sports" [value]="sport">{{
      sport
    }}</mat-option>
  </mat-select>
</mat-form-field>
<div *ngFor="let l of faves">
  <div class="mat-h5 league" *ngIf="l.league">{{ l.league }}</div>
  <div *ngFor="let f of l.faves; let i = index">
    <mat-divider *ngIf="i"></mat-divider>
    <fave [fave]="f" (favedChanged)="favedChanged(f, $event)"></fave>
  </div>
</div>
