import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getItemImage } from '../../../helpers/get-image';
import { DonationItem } from '../../../types';

@Component({
  selector: 'card-donation',
  templateUrl: './card-donation.component.html',
  styleUrls: ['./card-donation.component.scss'],
})
export class CardDonationComponent {
  @Output() clicked = new EventEmitter<DonationItem>();
  image?: string;
  _item?: DonationItem;
  overlayImage?: string;

  @Input()
  set item(item: DonationItem | undefined) {
    this._item = item;
    this.image = getItemImage(item);
  }
  get item() {
    return this._item;
  }

  onClick() {
    if (this.item) this.clicked.emit(this.item);
  }
}
