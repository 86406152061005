import { Auction } from '@fan-types/auction.model';
import { AuctionItem } from '@fan-types/item.model';
import { ApiBase } from '@fan-services/models/api-base';

export class AuctionApi extends ApiBase {
  model = 'auctions';

  items(id: string) {
    const url = this.modelUri(id, 'items');
    return this.http.get<AuctionItem[]>(url);
  }

  findById(id: string) {
    return this.http.get<Auction>(this.modelUri(id));
  }

  serverTime() {
    return this.http.get<{ now: string }>(this.modelUri('time'));
  }
}
