import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { StorageService } from '../../../../services/storage.service';
import {
  SelectPaymentComponent,
  SelectPaymentDialogData,
} from '../../../../shared/payments/select-payment/select-payment.component';
import { GlobalState } from '../../../../store';
import { pullCCards } from '../../../../store/ccard/ccard.actions';
import {
  selectCCardByCurrency,
  selectCCardByCurrencyAndFoundation,
} from '../../../../store/ccard/ccard.selectors';
import { CCard, Currency } from '../../../../types';

type CardSegment = {
  currency: Currency;
  foundationId?: string;
};

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnDestroy {
  @Input() card: CCard | undefined;
  @Input() useCustomUI = false;
  @Input() defaultCardId: string | undefined;
  @Output() cardSelected = new EventEmitter<CCard>();
  @Output() cardSelectedFinished = new EventEmitter<CCard>();

  private _segment?: CardSegment | undefined;
  public get segment(): CardSegment | undefined {
    return this._segment;
  }
  @Input()
  public set segment(value: CardSegment | undefined) {
    this._segment = value;
    if (value) {
      this.updateSegment(value);
    }
  }

  subs?: Subscription;

  constructor(
    private store: Store<GlobalState>,
    public dialog: MatDialog,
    private storageService: StorageService
  ) {}

  private updateSegment(segment: CardSegment) {
    this.store.dispatch(pullCCards({ ...segment }));
    this.subs = this.store
      .select(
        segment.foundationId
          ? selectCCardByCurrencyAndFoundation(
              segment.currency!,
              segment.foundationId
            )
          : selectCCardByCurrency(segment.currency!)
      )
      .subscribe((payments) => {
        if (!payments.length) return;
        if (!this.card && payments.length) {
          const prevPaymentId = this.storageService.getItem(
            `payment_${this.segment?.currency}`
          );
          const preselectedPayment = payments.find(
            (p) => this.defaultCardId && p.cardId === this.defaultCardId
          );
          const prevPayment = payments.find((p) => p.id === prevPaymentId);
          this.cardSelected.emit(
            preselectedPayment ?? prevPayment ?? payments[0]
          );
        }
      });
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }

  selectPayment() {
    if (!this.segment) return;
    const dialogRef = this.dialog.open<
      SelectPaymentComponent,
      SelectPaymentDialogData
    >(SelectPaymentComponent, {
      data: this.segment,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.id) {
        this.cardSelected.emit(result);
        this.cardSelectedFinished.emit(result);
        this.storageService.setItem(
          `payment_${this.segment?.currency}`,
          result.id
        );
      }
    });
  }
}
