import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignNavComponent } from './sign-nav/sign-nav.component';
import { SharedModule } from '../shared.module';
import { RegisterFormComponent } from './register-form/register-form.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ForgotFormComponent } from './forgot-form/forgot-form.component';
import { ShippingFormComponent } from './shipping-form/shipping-form.component';
import { StartFormComponent } from './start-form/start-form.component';

@NgModule({
  declarations: [
    SignNavComponent,
    RegisterFormComponent,
    LoginFormComponent,
    ForgotFormComponent,
    ShippingFormComponent,
    StartFormComponent
  ],
  exports: [
    SignNavComponent,
    RegisterFormComponent,
    LoginFormComponent,
    ForgotFormComponent,
    ShippingFormComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class OnboardingModule {}
