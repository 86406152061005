import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'start-form',
  templateUrl: './start-form.component.html',
  styleUrls: ['./start-form.component.scss'],
})
export class StartFormComponent {
  @Output() goToRegister = new EventEmitter();
  @Output() goToLogin = new EventEmitter();
}
