import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ItemActions from '@fan-store/item/item.actions';

import { mergeMap } from 'rxjs/operators';

import * as RelatedItemsActions from './related-items.actions';
import { ApiService } from '../../services/api.service';

@Injectable()
export class RelatedItemsEffects {
  pullRelatedItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RelatedItemsActions.pullRelatedItems),
      mergeMap(({ itemId }) => {
        return this.api.auctionItem.related(itemId).pipe(
          mergeMap((items) => {
            return [
              ItemActions.upsertItems({ items }),
              RelatedItemsActions.addRelatedItems({
                items: {
                  id: itemId,
                  itemIds: items.map((i) => i.id),
                },
              }),
            ];
          })
          //         catchError(error => of(AuthApiActions.loginFailure({ error })))
        );
      })
    );
  });

  constructor(private actions$: Actions, private readonly api: ApiService) {}
}
