<div class="cta-colored-box cta-centered-box">
  <event-status *ngIf="auction" [auction]="auction"></event-status>
</div>
<!-- Available and price -->
<div class="cta-box cta-box-column-content">
  <p class="mat-h5 dark">
    {{
      'event.price'
        | translate
          : { price: (item?.minimumBid | currency : currency : '' : '1.2-2') }
    }}&nbsp;<span class="currency">{{ currency }}</span>
  </p>
  <p *ngIf="item?.itemState === 'open'">
    {{'item.itemsAvailable' | translate}}: {{ (item?.maxItems || 0) - (item?.purchasedCount || 0) }}
  </p>
</div>
<!-- Custom Buy now -->
<div class="cta-box" *ngIf="item?.itemState === 'open'">
  <button
    *ngIf="showPrePurchaseButton"
    mat-raised-button
    color="primary"
    class="full-with-button"
    (click)="showPrePurchaseButton = false"
  >
    {{ 'customBuynow.enterMessage' | translate | uppercase }}
  </button>
  <ng-container *ngIf="!showPrePurchaseButton">
    <form #customBuynowForm="ngForm" (ngSubmit)="submit()">
       <mat-form-field appearance="outline">
         <mat-label>{{'customBuynow.enterMessage' | translate}}</mat-label>
         <textarea matInput
           required
           [(ngModel)]="customMessage"
           name="customMessage"
           cdkTextareaAutosize
           #autosize="cdkTextareaAutosize"
           cdkAutosizeMinRows="4"
           cdkAutosizeMaxRows="6"></textarea>
       </mat-form-field>
      <button
        mat-raised-button
        class="full-with-button"
        color="primary"
        id="buy"
        [disabled]="(processing$ | async) || !customBuynowForm.valid"
        type="submit"
      >
        {{ 'customBuynow.makePurchase' | translate | uppercase }}
      </button>
    </form>
  </ng-container>
  <payment
    *ngIf="currency && profile"
    [segment]="{ currency: currency, foundationId: foundation?.id }"
    (cardSelected)="cardSelected($event)"
    [card]="card"
  ></payment>
  <div class="mat-subtitle-4" *ngIf="!card">
    {{ 'event.nodHint' | translate }}
  </div>
  <!-- Open Item Comments -->
  <div class="mat-subtitle-4">
    {{ 'event.binNoteFee1' | translate }}
  </div>
  <div class="mat-subtitle-4">
    {{
      'event.noteFee2'
        | translate : { procPerc: foundation?.procPerc, name: foundation?.name }
    }}
  </div>
  <div class="mat-subtitle-4">
    {{
      'event.noteLocation'
        | translate : { location: currency === 'CAD' ? 'Canada' : 'U.S.A.' }
    }}
  </div>
  <div class="mat-subtitle-4">
    {{ 'event.noteTos' | translate }}
  </div>
</div>
<mat-progress-bar mode="query" *ngIf="processing$ | async"></mat-progress-bar>
