import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getItemImage } from '@fan-helpers/get-image';
import { isSoldOut } from '@fan-helpers/is-sold-out';
import { CustomBuynowItem } from '@fan-types/item.model';

@Component({
  selector: 'card-custom-bin',
  templateUrl: './card-custom-bin.component.html',
  styleUrls: ['./card-custom-bin.component.scss']
})
export class CardCustomBinComponent {
  @Output() clicked = new EventEmitter<CustomBuynowItem>();
  image?: string;
  _item?: CustomBuynowItem;
  overlayImage?: string;
  @Input() userBidId?: string;

  @Input()
  set item(item: CustomBuynowItem | undefined) {
    this._item = item;
    this.image = getItemImage(item);
    this.overlayImage = isSoldOut(item) ? '/assets/images/sold_out.png' : undefined;
  }
  get item() {
    return this._item;
  }

  onClick() {
    if (this.item) this.clicked.emit(this.item);
  }
}
